<ng-container *ngIf="!soloValores">
    <form #formMedicamento="ngForm">
        <plex-wrapper>
            <plex-select [(ngModel)]="medicamento.generico" name="generico" (getData)="loadMedicamentoGenerico($event)"
                         placeholder="Medicamento genérico" label="Medicamento" idField="conceptId" labelField="term"
                         (change)="loadPresentaciones()" required="true">
            </plex-select>
            <plex-select name="unidades" *ngIf="unidades.length > 0" [(ngModel)]="medicamento.unidades"
                         [data]="unidades" placeholder="Seleccione una opción" label="Unidades por presentación"
                         labelField="valor" required="true">
            </plex-select>
            <plex-int name="unidades" label="Unidades por presentación" [(ngModel)]="medicamento.unidades"
                      *ngIf="unidades.length == 0" required="true" min="1" max="90">
            </plex-int>
            <plex-int name="envases" label="Envases" [(ngModel)]="medicamento.cantidad" required="true" min="1"
                      max="90">
            </plex-int>
            <plex-text [(ngModel)]="medicamento.diagnostico" label="Diagnóstico" name="motivo" multiline="true"
                       grow="full">
            </plex-text>
            <plex-radio [(ngModel)]="medicamento.tipoReceta" label="Tipo de receta" type="horizontal"
                        [data]="opcionesTipoReceta" name="tipoReceta">
            </plex-radio>
            <plex-bool [(ngModel)]="medicamento.tratamientoProlongado" label="Tratamiento prolongado"
                       name="tratamientoProlongado">
            </plex-bool>
        </plex-wrapper>
        <plex-title titulo="Dosis diaria" size="sm"></plex-title>
        <plex-wrapper>
            <plex-int name="cantidadDiaria" label="Dosis" [(ngModel)]="medicamento.dosisDiaria.cantidad"
                      required="false" min="1" max="90">
            </plex-int>
            <plex-int name="duracion" label="Cantidad de días" [(ngModel)]="medicamento.dosisDiaria.dias"
                      required="false" min="1" max="90">
            </plex-int>
        </plex-wrapper>
        <plex-button type="success" [disabled]="!formMedicamento.valid" (click)="agregarMedicamento($event)"
                     [validateForm]="formMedicamento">Agregar
        </plex-button>
    </form>
    <plex-list size="sm" *ngIf="registro.valor.medicamentos" class="mt-3">
        <plex-item *ngFor="let item of registro.valor.medicamentos">
            <plex-label [tituloBold]="true" titulo="{{ item.generico.term  }}" subtitulo="{{ item.unidades }} {{item.presentacion.term }}(s) por {{ item.cantidad }} envase(s) o {{ item.dosisDiaria.cantidad }} {{item.presentacion.term }}(s) por {{ item.dosisDiaria.dias }} días
            "></plex-label>
            <plex-badge *ngIf="item.diagnostico" type="warning" hint="{{item.diagnostico}}" hintIcon="informacion"
                        hintType="warning">Diagnóstico
            </plex-badge>
            <plex-badge *ngIf="item.tratamientoProlongado" type="info">Tratamiento prolongado
            </plex-badge>
            <plex-badge type="info">{{item.tipoReceta}}
            </plex-badge>
            <plex-button type="danger" size="sm" (click)="borrarMedicamento(item)" icon="delete"></plex-button>
        </plex-item>
    </plex-list>
</ng-container>
<div *ngIf="soloValores">
    <plex-list size="sm" *ngIf="registro.valor.medicamentos">
        <plex-item *ngFor="let item of registro.valor.medicamentos">
            <plex-label [tituloBold]="true" titulo="{{ item.generico.term  }}" subtitulo="{{ item.unidades }} {{item.presentacion.term }}(s) por {{ item.cantidad }} envase(s) o {{ item.dosisDiaria.cantidad }} {{item.presentacion.term }}(s) por {{ item.dosisDiaria.dias }} días
                "></plex-label>
            <plex-badge *ngIf="item.diagnostico" type="warning" hint="{{item.diagnostico}}" hintIcon="informacion"
                        hintType="warning"> Diagnóstico</plex-badge>
            <plex-badge *ngIf="item.tratamientoProlongado" type="info">Tratamiento prolongado
            </plex-badge>
            <plex-badge type="info">{{item.tipoReceta}}
            </plex-badge>
        </plex-item>
    </plex-list>
</div>
