<ng-container *ngIf="soloValores && registro && registro.valor.informeIngreso">
    <div class="row">
        <div class="col-4">
            <label class="block">Fecha Ingreso</label>
            <span>{{ registro.valor.informeIngreso.fechaIngreso | date: 'dd/MM/yyyy HH:mm' }}</span>
        </div>
        <div class="col-4">
            <label class="block">Origen hospitalización</label>
            <span>{{ registro.valor.informeIngreso.origen }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label class="block">Motivo de ingreso</label>
            <span>{{ registro.valor.informeIngreso.motivo }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-4" *ngIf="registro.valor.informeIngreso.ocupacionHabitual">
            <label class="block">Ocupación habitual</label>
            <span>{{ registro.valor.informeIngreso.ocupacionHabitual.nombre }}</span>
        </div>
        <div class="col-4" *ngIf="registro.valor.informeIngreso.situacionLaboral">
            <label class="block">Situación laboral</label>
            <span>{{ registro.valor.informeIngreso.situacionLaboral }}</span>
        </div>
        <div class="col-4" *ngIf="registro.valor.informeIngreso.nivelInstruccion">
            <label class="block">Nivel instrucción</label>
            <span>{{ registro.valor.informeIngreso.nivelInstruccion }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <label class="block">Asociado a</label>
            <span *ngIf="registro.valor.informeIngreso.asociado">{{ registro.valor.informeIngreso.asociado }}</span>
        </div>
        <div class="col-4">
            <label class="block">Obra social</label>
            <span
                  *ngIf="registro.valor.informeIngreso.obraSocial">{{registro.valor.informeIngreso.obraSocial.financiador}}</span>
        </div>
    </div>
</ng-container>