<plex-layout main="{{profesionalSelected?'8':'12'}}" resizable="true" [min]="4" [max]="5" [steps]="2">
    <plex-layout-main>
        <plex-title main titulo="PROFESIONALES">
            <plex-button label="volver" type="danger" position="left" routerLink='/inicio'></plex-button>

            <plex-button label="Nuevo Profesional" type="success" position="right" routerLink='/tm/profesional/create'>
            </plex-button>
        </plex-title>
        <plex-wrapper>
            <plex-int [(ngModel)]="filtros.documento" name="documento" label="Documento" placeholder="Ingrese documento"
                      (change)="filtrar()">
            </plex-int>
            <plex-text [(ngModel)]="filtros.apellido" name="apellido" label="Apellido" placeholder="Ingrese apellido"
                       (change)="filtrar()">
            </plex-text>
            <plex-text [(ngModel)]="filtros.nombre" name="nombre" label="Nombre" placeholder="Ingrese nombre"
                       (change)="filtrar()">
            </plex-text>
            <plex-bool [(ngModel)]="filtros.estado" name="estado" label="Deshabilitados" (change)="filtrar()"
                       type="slide">
            </plex-bool>
            <plex-bool class="bool-posicion" label="No matriculado" [(ngModel)]="filtros.noMatriculado" name="estado"
                       type="slide" (change)="filtrar()">
            </plex-bool>
        </plex-wrapper>
        <div *ngIf="!(listado$ | async)?.length" class="mt-5">
            <plex-label class="flex-column" icon="magnify" type="info" justify="center" size="xl" direction="column"
                        titulo="No hay resultados para esta búsqueda"
                        subtitulo="Edite algún filtro para realizar una nueva">
            </plex-label>
        </div>
        <ng-container *ngIf="(listado$ | async)?.length">
            <plex-table [columns]="columns" #table="plTable" (scroll)="onScroll()" [offset]="102">
                <plex-title titulo="Listado" size="sm">
                    <plex-table-columns>
                    </plex-table-columns>
                </plex-title>

                <tr *ngFor="let profesional of (listado$ | plSort:table | async)"
                    (click)="seleccionarProfesional(profesional)"
                    [class.selected]="profesional.id===profesionalSelected?.id" [class.selectable]="selectable">
                    <td *plTableCol="'documento'">{{profesional.documento}}</td>
                    <td *plTableCol="'apellido'">{{profesional.apellido }}</td>
                    <td *plTableCol="'nombre'">{{profesional.nombre}}</td>
                    <td *plTableCol="'condicion'">
                        <plex-badge *ngIf="profesional.profesionalMatriculado" type="info">
                            Matriculado
                        </plex-badge>
                        <plex-badge *ngIf="!profesional.profesionalMatriculado" type="warning">
                            No Matriculado
                        </plex-badge>
                    </td>
                    <td *plTableCol="'estado'">
                        <plex-badge *ngIf="profesional.habilitado" type="info">
                            Habilitado
                        </plex-badge>
                        <plex-badge *ngIf="!profesional.habilitado" type="warning">
                            Deshabilitado
                        </plex-badge>
                    </td>
                </tr>
            </plex-table>
        </ng-container>
    </plex-layout-main>

    <!-- sidebar listado -->
    <plex-layout-sidebar type="invert" *ngIf='profesionalSelected'>
        <plex-title size="md" titulo="Detalle Profesional">
            <plex-button *ngIf="profesionalSelected && !profesionalSelected.profesionalMatriculado" tooltip='Editar'
                         [icon]="'pencil'" type="warning" size="sm"
                         (click)="routeTo('create', profesionalSelected.id)  ">
            </plex-button>
            <plex-button size="sm" type="danger" [icon]="'close'" (click)="cerrar()"></plex-button>

        </plex-title>
        <plex-detail size="lg">
            <img *ngIf="fotoProfesional" [src]="fotoProfesional">
            <plex-badge *ngIf='profesionalSelected.profesionalMatriculado' type="info">Matriculado</plex-badge>
            <plex-badge *ngIf='!profesionalSelected.profesionalMatriculado' type="warning">No Matriculado</plex-badge>
            <div title> {{ profesionalSelected.apellido | uppercase }}, {{ profesionalSelected.nombre | uppercase}}
            </div>
            <div subtitle> {{ profesionalSelected.documento }} </div>
            <plex-label *ngIf='profesionalSelected.fechaNacimiento' titulo="Fecha de Nacimiento"
                        subtitulo="{{ profesionalSelected.fechaNacimiento | fecha }}" size="md">
            </plex-label>
            <plex-label *ngIf='profesionalSelected.fechaNacimiento' titulo="Edad"
                        subtitulo="{{ profesionalSelected | edad }}" size="md"></plex-label>
            <plex-label *ngIf='profesionalSelected.sexo' titulo="Sexo" subtitulo="{{ profesionalSelected.sexo }}"
                        size="md">
            </plex-label>
            <plex-label *ngIf='profesionalSelected.cuit' titulo="CUIT" subtitulo="{{ profesionalSelected.cuit }}"
                        size="md">
            </plex-label>
        </plex-detail>

        <ng-container *ngIf="profesionalSelected.formacionGrado?.length">
            <plex-title size="sm" titulo="Formaciones de grado"></plex-title>
            <plex-list [selectable]="false" *ngFor="let formacionGrado of profesionalSelected.formacionGrado">
                <plex-item *ngIf="formacionGrado?.matriculacion && formacionGrado.matriculacion[formacionGrado.matriculacion.length - 1].matriculaNumero"
                           [selectable]="false">
                    <plex-label titulo="Profesión" subtitulo="{{formacionGrado.profesion.nombre}}" size="md">
                    </plex-label>
                    <plex-label titulo="Matrícula"
                                subtitulo="{{formacionGrado.matriculacion[formacionGrado.matriculacion.length - 1].matriculaNumero}}"
                                size="md"></plex-label>
                </plex-item>
            </plex-list>
        </ng-container>
        <ng-container *ngIf="profesionalSelected.formacionPosgrado?.length">
            <plex-title size="sm" titulo="Posgrados"></plex-title>
            <plex-list [selectable]="false" *ngFor="let formacionPosgrado of profesionalSelected.formacionPosgrado">
                <plex-item *ngIf="formacionPosgrado?.matriculacion && formacionPosgrado.matriculacion[formacionPosgrado.matriculacion.length - 1].matriculaNumero"
                           [selectable]="false">
                    <plex-label titulo="Especialidad" subtitulo="{{formacionPosgrado.especialidad.nombre}}" size="md">
                    </plex-label>
                    <plex-label titulo="Matrícula"
                                subtitulo="{{ formacionPosgrado.matriculacion[formacionPosgrado.matriculacion.length-1].matriculaNumero }}"
                                size="md"></plex-label>
                </plex-item>
            </plex-list>
        </ng-container>
        <ng-container
                      *ngIf="!profesionalSelected.profesionalMatriculado && (profesionalSelected.profesionExterna || profesionalSelected.matriculaExterna)">
            <plex-title size="sm" titulo="Profesión Externa"></plex-title>
            <plex-list [selectable]="false">
                <plex-item [selectable]="false">
                    <plex-label *ngIf="profesionalSelected.profesionExterna" titulo="Profesión"
                                subtitulo="{{profesionalSelected.profesionExterna.nombre}}" size="md">
                    </plex-label>
                    <plex-label *ngIf="profesionalSelected.matriculaExterna" titulo="Matrícula"
                                subtitulo="{{profesionalSelected.matriculaExterna}}" size="md"></plex-label>
                </plex-item>
            </plex-list>
        </ng-container>
    </plex-layout-sidebar>
</plex-layout>