<form #form="ngForm">
    <div class="row flex-column">
        <div class="col-12">
            <ng-container *ngIf="!soloValores; else showSoloValores">
                <plex-int [label]="params.title ? params.title : registro.concepto.term" name="intValue"
                          *ngIf="params.numericType === 'integer'" [(ngModel)]="registro.valor"
                          [suffix]="params.unit ? params.unit : '' " [min]="params.min" [max]="params.max"
                          (change)="onChange()" [required]="esRequerido"></plex-int>
                <plex-float [label]="params.title ? params.title : registro.concepto.term" name="floatValue"
                            *ngIf="params.numericType === 'float'" [(ngModel)]="registro.valor"
                            [suffix]="params.unit ? params.unit : ''" [min]="params.min" [max]="params.max"
                            (change)="onChange()" [required]="esRequerido"></plex-float>

                <p *ngIf="mensaje?.texto">
                    <plex-badge type="{{mensaje.type}}">
                        {{mensaje.texto}}
                    </plex-badge>
                </p>
            </ng-container>
            <ng-template #showSoloValores>
                <div *ngIf="params?.title; else showTerm">
                    <label class="text-capitalize">{{ params.title }}</label>
                </div>
                <ng-template #showTerm>
                    <label class="text-capitalize">{{ registro.concepto.term }}</label>
                </ng-template>
                {{registro.valor}} {{params.unit}}
            </ng-template>
        </div>
    </div>
</form>