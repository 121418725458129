<plex-title titulo="Reglas Solicitudes" size="md">
    <plex-text class="mb-2" [(ngModel)]="search" [debounce]="600" name="search" (change)="onSearchStart($event)"
               placeholder=" Buscar ..." [autoFocus]="true">
    </plex-text>
</plex-title>

<plex-wrapper *ngIf="!esParametrizado">
    <plex-select [(ngModel)]="organizacionOrigen" name="orgOrigen" tmOrganizaciones label="Organización origen"
                 placeholder="Seleccione la organización" (change)="refrescarFiltro()">
    </plex-select>
    <plex-select [(ngModel)]="prestacionOrigen" tmPrestaciones preload="true" name="prestOrigen"
                 label="Prestación origen" placeholder="Seleccione la prestación" (change)="refrescarFiltro()">
    </plex-select>
    <plex-select [(ngModel)]="organizacionDestino" name="orgDestino" tmOrganizaciones label="Organización destino"
                 placeholder="Seleccione la organización" (change)="refrescarFiltro()">
    </plex-select>
    <plex-select [(ngModel)]="prestacionDestino" name="prestDestino" tmPrestaciones preload="true"
                 label="Prestación destino" placeholder="Seleccione la prestación" (change)="refrescarFiltro()">
    </plex-select>
    <plex-button type="success" label="Descargar" [disabled]="!hayFiltro || !filas?.length" (click)="descargarReglas()">
    </plex-button>
</plex-wrapper>

<ng-container *ngIf="filas$ | async as filas">

    <plex-loader class="loader" *ngIf="loader && filas" type="ball-pulse"></plex-loader>

    <plex-list [striped]="true" [offset]="260" [ngClass]="{ 'loading': loader }" (scrolled)="onScroll()">
        <plex-item *ngIf="filas?.length">
            <b *ngIf="!esParametrizado">Organización origen</b>
            <b label> Prestación origen </b>
            <b label> Organización destino </b>
            <b label> Prestación destino </b>
            <b></b>
        </plex-item>
        <plex-item *ngFor="let fila of filas">
            <div *ngIf="!esParametrizado">
                <plex-icon name="hospital-building" type="info"></plex-icon>
                <plex-label [tituloBold]="true" titulo="{{ fila?.organizacionOrigen?.nombre }}">
                </plex-label>
            </div>
            <plex-label [tituloBold]="!esParametrizado" titulo="{{ fila?.prestacionOrigen?.term }}">
            </plex-label>
            <div>
                <plex-icon *ngIf="!esParametrizado" name="hospital-building" type="success"></plex-icon>
                <plex-label [tituloBold]="!esParametrizado" titulo="{{ fila?.organizacionDestino?.nombre }}">
                </plex-label>
            </div>
            <plex-label [tituloBold]="!esParametrizado" titulo="{{ fila?.prestacionDestino?.term }}"></plex-label>

            <plex-button *ngIf="prestacion"
                         [disabled]="prestacion?.conceptId === fila?.prestacionOrigen?.prestacion?.conceptId"
                         type="primary" size="sm" icon="plus" tooltip="Agregar a la consulta"
                         (click)="seleccionarConcepto(fila.prestacionDestino)" tooltipPosition="left">
            </plex-button>

            <plex-badge *ngIf="fila?.prestacionOrigen?.auditable && !esParametrizado" type="success">
                auditable
            </plex-badge>
            <plex-badge *ngIf="!fila?.prestacionOrigen?.auditable && !esParametrizado" type="warning">
                no auditable
            </plex-badge>
        </plex-item>

        <div *ngIf="!loader && filas && !filas?.length" justify="center" class="mt-5">
            <plex-label class="mt-5" titulo="No hay resultados para esta búsqueda" direction="column"
                        subtitulo="Edite el filtro para realizar una nueva" type="warning" size="lg" icon="close">
            </plex-label>
        </div>
    </plex-list>
</ng-container>