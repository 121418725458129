<div class="mt-2">
    <plex-title titulo="últimas vistas"></plex-title>
</div>
<plex-list>
    <plex-item *ngFor="let novedad of cacheNovedades.reverse()" (click)="verDetalle(novedad)">
        <plex-badge class="novedadBadge" size="md" type="info">{{novedad.fecha | date: 'dd/MM/yyyy'}}</plex-badge>
        <plex-badge class="novedadBadge" size="md" type="success"> {{novedad.modulo ? novedad.modulo.nombre : 'general'}}
        </plex-badge>
        <plex-icon size="18" name="{{novedad.modulo?.icono}}"></plex-icon>
        <span>{{novedad.titulo}}</span>
    </plex-item>
    <hr class="bg-white">
</plex-list>