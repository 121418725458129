<section class="mt-2">
    <plex-title titulo="notas" size="sm">
        <plex-button name="nuevaNota" label="Agregar nota" type="info" size="sm" (click)="showAgregarNota = true">
        </plex-button>
    </plex-title>

    <plex-wrapper justify *ngIf="showAgregarNota">
        <plex-text columns="4" label="Título de nota" placeholder="Ingrese un título..." [(ngModel)]="nuevaNota.titulo"
                   name="titulo">
        </plex-text>
        <plex-text columns="6" label="Cuerpo de la nota" placeholder="Ingrese una nota" [(ngModel)]="nuevaNota.nota"
                   name="nota" multiline=true required="true">
        </plex-text>
        <plex-button class="pt-4" name="confirmarNota" type="success" icon="check" size="md" justify="end"
                     multiline="true" [disabled]="!nuevaNota?.nota?.length" (click)="addNota()"></plex-button>
    </plex-wrapper>
    <plex-list *ngIf="notas?.length">
        <plex-item *ngFor="let nota of notas; let i = index" class="">
            <plex-icon name="documento" type="info" size="xl"></plex-icon>
            <plex-label titulo="{{ nota.titulo || 'Sin título'}}" subtitulo="{{ nota.nota }}"></plex-label>

            <div *ngIf="nota.destacada" class="w-100" justify="end">
                <plex-icon name="star" type="info" size="18" tooltip="Nota destacada"></plex-icon>
            </div>
            <plex-badge type="info">{{ nota.fecha | fecha: 'utc'}}</plex-badge>

            <plex-button *ngIf="nota.destacada" type="info" icon="star-off" size="sm" tooltip="Quitar destacado"
                         (click)="destacarNota(i)">
            </plex-button>

            <plex-button *ngIf="!nota.destacada" type="info" icon="star" size="sm" tooltip="Destacar"
                         (click)="destacarNota(i)">
            </plex-button>

            <plex-button type="danger" size="sm" icon="delete" (click)="removeNota(i)"></plex-button>
        </plex-item>
    </plex-list>
    <plex-label *ngIf="!notas?.length && !showAgregarNota"
                titulo="No se encontraron notas registradas en el paciente"></plex-label>
</section>