<plex-title titulo="Bloques y turnos" size="md">
    <plex-help class="mr-1" type="help" icon="information-variant" titulo="Detalle de agenda"
               tooltip="Detalle de agenda">
        <div class="container mb-4">
            <div class="row">
                <div class="col-12">
                    <label>Creada por </label> {{ agenda?.createdBy | nombre }}
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Fecha</label> {{ agenda?.horaInicio | date: 'EEE' | uppercase }} {{
                    agenda.horaInicio
                    | date:
                    'dd/MM/yyyy'}}, {{ agenda?.horaInicio | date: 'HH:mm'}} a {{ agenda.horaFin |
                    date:
                    'HH:mm'}} hs
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Tipos de prestación</label>
                    <div *ngFor="let tipoPrestacion of agenda.tipoPrestaciones">
                        {{tipoPrestacion.nombre}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Equipo de Salud</label>
                    <span *ngIf="agenda.profesionales?.length == 0" class="text-danger">Equipo de
                        Salud
                        no
                        asignado</span>
                    <ng-container *ngIf="agenda.profesionales">
                        <div *ngFor="let profesional of agenda.profesionales">
                            {{profesional | nombre}}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Espacio físico</label>
                    <span *ngIf="agenda.espacioFisico?.nombre || agenda.otroEspacioFisico?.nombre">{{agenda
                        | espacioFisico}}</span>
                    <span *ngIf="!agenda.espacioFisico?.nombre && !agenda.otroEspacioFisico?.nombre"
                          class="text-danger">Espacio físico no
                        asignado</span>
                </div>
            </div>
            <div *ngIf="agenda.dinamica" class="row">
                <div class="col-12">
                    <label>Cupos disponibles</label>
                    <span *ngIf="agenda.cupo > -1">{{agenda.cupo}}</span>
                    <span *ngIf="agenda.cupo == -1">Sin límite</span>
                </div>
            </div>
            <div *ngIf="agenda.link" class="row">
                <div class="col-12">
                    <label>Link agenda virtual</label>
                    <a href="{{agenda.link}}" target="_blank">{{agenda.link}}</a>
                </div>
            </div>
        </div>
    </plex-help>
    <plex-help *ngIf="agenda.nota" class="mr-1" type="help" icon="message">
        {{ agenda.nota }}
    </plex-help>
    <plex-button type="danger" icon="close" (click)="volverAtras()" tooltip="Cerrar" class="mr-1" size="sm">
    </plex-button>

</plex-title>