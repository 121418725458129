<plex-layout main="7">
    <plex-layout-main>
        <plex-title titulo="Punto de Inicio - Internacion"></plex-title>
        <paciente-buscar (searchStart)="onSearchStart()" (searchEnd)="onSearchEnd($event)"
                         (searchClear)="onSearchClear()">
        </paciente-buscar>

        <paciente-listado *ngIf="listado && listado.length" [pacientes]="listado"
                          (selected)="onPacienteSelected($event)" [offset]="110">
        </paciente-listado>

        <div *ngIf="listado && !listado.length && !searchClear" class="alert alert-danger">
            <plex-icon name="account-alert"></plex-icon> No se encontró ningún paciente
        </div>
    </plex-layout-main>
    <!-- Sidebar -->
    <plex-layout-sidebar type="invert">
        <ng-container *ngIf="pacienteSeleccionado">
            <paciente-detalle [paciente]="pacienteSeleccionado" orientacion="horizontal"></paciente-detalle>
            <div class="info-sidebar">
                <hr class="blanco lg">
                <ng-container *ngIf="!showLoader">
                    <div class="row" *ngIf="!(pacienteSeleccionado | pacienteRestringido)">
                        <div class="col-4">
                            <h5 class="text-info">Registros</h5>
                        </div>
                        <div class="col-8 text-right d-flex justify-content-end">
                            <plex-dropdown size="sm" type="success" class="mr-1" [right]="true" [items]="registros"
                                           label="NUEVO REGISTRO">
                            </plex-dropdown>
                            <plex-button [disabled]="pacienteSeleccionado | pacienteRestringido" size="sm" type="info" (click)="ruteo(pacienteSeleccionado.id, 'huds')">
                                VER HUDS
                            </plex-button>
                        </div>
                    </div>
                </ng-container>
                <plex-loader *ngIf="showLoader" type="ball-pulse-sync"></plex-loader>
                <!-- Tabla de las epicrisis -->
                <section class="contenedor-tabla">
                    <table class="table table-striped">
                        <thead>
                            <tr class="text-uppercase">
                                <td>
                                    <strong>Fecha</strong>
                                </td>
                                <td>
                                    <strong>Prestación</strong>
                                </td>
                                <td class="text-right">
                                    <strong>Acciones</strong>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="!epicrisisPaciente.length">
                                <td colspan="3">
                                    <div *ngIf="!epicrisisPaciente.length && !showLoader" class="row">
                                        <div class="col-12 text-center">
                                            <plex-badge type="warning internacion">No hay registros del paciente
                                            </plex-badge>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngIf="epicrisisPaciente.length">
                                <tr class="hover" *ngFor="let epicrisis of epicrisisPaciente">
                                    <td class="text-info">
                                        {{ epicrisis.solicitud.fecha | fecha }}
                                    </td>
                                    <td>
                                        {{ epicrisis.solicitud.tipoPrestacion.term }}
                                    </td>
                                    <td class="text-right">
                                        <plex-button icon="eye" title="ver registro" type="info"
                                                     (click)="ruteo(epicrisis.id, 'epicrisis')"></plex-button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </section>
            </div>
        </ng-container>
    </plex-layout-sidebar>
</plex-layout>