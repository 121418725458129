<!-- 
- Fecha y hora de inicio
- Hora de fin
- Tipo de actividad: reference set relacionado con el tipo de prestación (ver #308)
- Temática: listado estático. En el caso de seleccionar 'Otra' mostrar el siguiente campo ...
- Otra temática (ver campo anterior)
- Profesionales intervinientes
- Un campo de observación que se llama 'Resumen de la actividad' (obligatorio) 
-->
<form #form="ngForm">
    <ng-container>
        <div class="row">
            <div class="col-6" *ngIf="registro.valor?.prestacionesRealizadas">
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.banioAntitermico" name="banioAntitermico"
                           label="Baño Antitérmico" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.sondaNasogastrica" name="sondaNasogastrica"
                           label="Colocación de sonda nasogástrica" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.viaPeriferica" name="viaPeriferica" label="Colocación de vía periferica"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.aspiracionSecreciones" name="aspiracionSecreciones"
                           label="Aspiración de Secreciones" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.basiloscopia" name="basiloscopia" label="Toma de muestra para basiloscopía"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.confeccionCarnet" name="confeccionCarnet"
                           label="Confección de carnet de vacunas" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.saludEscolar" name="saludEscolar" label="Cuidado de enfermería en salud escolar"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.CLCF" name="CLCF" label="Control de latidos Cardio fetales"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.tomaMuesra" name="tomaMuesra" label="Toma de muestra"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.dinamicaUterina" name="dinamicaUterina"
                           label="Control de la Dinámica Uterina" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.electrocardiograma" name="electrocardiograma"
                           label="Electrocardiograma" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.entregaLeche" name="entregaLeche" label="Entrega de leche"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.enemaEvacuante" name="enemaEvacuante"
                           label="Enema Evacuante" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.extraccionSuturas" name="extraccionSuturas"
                           label="Extracción de suturas" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.gestionInsumos" name="gestionInsumos"
                           label="Gestión de Insumos" [readonly]="soloValores">
                </plex-bool>
            </div>
            <div class="col-6">
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.impregnacion" name="impregnacion" label="Impregnación"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.instilacionOcular" name="instilacionOcular"
                           label="Instilación Ocular" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.lavajeGastrico" name="lavajeGastrico"
                           label="Lavaje Gástrico" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.monitoreoCardiaco" name="monitoreoCardiaco"
                           label="Monitoreo Cardíaco" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.nebulizaciones" name="nebulizaciones"
                           label="Nebulizaciones" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.rcp" name="rcp" label="Reanimación Cardio Pulmonar"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.recap" name="recap" label="RECAP"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.rehidratacion" name="rehidratacion" label="Rehidratación Oral"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.vendaje" name="vendaje" label="Vendaje"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.virologo" name="virologo" label="Toma de muestra para virológico"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.sondaVesical" name="sondaVesical" label="Colocación de Sonda Vesical"
                           [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.curacionSimple" name="curacionSimple"
                           label="Curación Simple" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.curacionCompleja" name="curacionCompleja"
                           label="Curación Compleja" [readonly]="soloValores">
                </plex-bool>
                <br>
                <plex-bool [(ngModel)]="registro.valor.prestacionesRealizadas.curacionQuemadura" name="curacionQuemadura"
                           label="Curación Quemadura" [readonly]="soloValores">
                </plex-bool>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col">
                        Asistencia a prácticas médicas
                    </div>
                    <div class="col">
                        <plex-int [(ngModel)]="registro.valor.tiemposEmpleados.asistenciaPracticas" name="asistenciaPracticas"
                                  suffix=" minutos " min="0" [readonly]="soloValores">
                        </plex-int>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Atención Domiciliaria
                    </div>
                    <div class="col">
                        <plex-int [(ngModel)]="registro.valor.tiemposEmpleados.atencionDomiciliaria" name="atencionDomiciliaria"
                                  suffix=" minutos " min="0" [readonly]="soloValores">
                        </plex-int>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Charla Educativa
                    </div>
                    <div class="col">
                        <plex-int [(ngModel)]="registro.valor.tiemposEmpleados.charlaEducativa" name="charlaEducativa"
                                  suffix=" minutos " min="0" [readonly]="soloValores">
                        </plex-int>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col">
                        Derivaciones
                    </div>
                    <div class="col">
                        <plex-int [(ngModel)]="registro.valor.tiemposEmpleados.derivaciones" name="derivaciones" suffix=" minutos "
                                  min="0" [readonly]="soloValores">
                        </plex-int>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Atención pre Hospitalaria
                    </div>
                    <div class="col">
                        <plex-int [(ngModel)]="registro.valor.tiemposEmpleados.atencionPrehospitalaria" name="atencionPrehospitalaria"
                                  suffix=" minutos " min="0" [readonly]="soloValores">
                        </plex-int>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Contención Emocional
                    </div>
                    <div class="col">
                        <plex-int [(ngModel)]="registro.valor.tiemposEmpleados.contencionEmocional" name="contencionEmocional"
                                  suffix=" minutos " min="0" [readonly]="soloValores">
                        </plex-int>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</form>
<!-- Lista de pacientes y buscador  -->