<div *ngIf="!soloValores">
    <!-- requeridos para la formula -->
    <ng-container *ngIf="elementoRUP.requeridos && elementoRUP.requeridos.length">
        <div class="row">

            <ng-container *ngIf="registro?.registros?.length">
                <ng-container *ngFor="let item of elementoRUP.requeridos; let i = index">
                    <div
                        [ngClass]="{'d-none': !cumpleReglasParams(item.elementoRUP), 'col': cumpleReglasParams(item.elementoRUP)}">
                        <rup [elementoRUP]="item.elementoRUP" [params]="item.params" [prestacion]="prestacion"
                            [registro]="registro.registros[i]" [soloValores]="soloValores" (change)="calculoIMC()">
                        </rup>
                    </div>
                </ng-container>
            </ng-container>

            <div class="col">
                <plex-float label="{{ termCapitalizado }}" [(ngModel)]="valorImc" required="true" name="imc"
                    [disabled]="true">
                </plex-float>
            </div>
        </div>
    </ng-container>
    <p *ngIf="alerta">
        <span>
            {{ alerta }}
        </span>
    </p>
</div>

<ng-container *ngIf="soloValores">
    <ng-container *ngIf="elementoRUP.requeridos && elementoRUP.requeridos.length">
        <div class="row">
            <ng-container *ngFor="let item of elementoRUP.requeridos; let i = index">
                <div [ngClass]="{'d-none': !cumpleReglasParams(item.elementoRUP), 'col': cumpleReglasParams(item.elementoRUP)}"
                    class="col-{{ item.style.columns }} mr-{{ item.style?.offset ? item.style : '0' }}">
                    <rup [elementoRUP]="item.elementoRUP" [params]="item.params" [prestacion]="prestacion"
                        [registro]="registro.registros[i]" [soloValores]="true" [paciente]="paciente">
                    </rup>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <p class="readonly">
        <span>{{ registro.nombre }}</span>
        {{ registro.valor }}
    </p>
</ng-container>