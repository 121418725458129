<section class="w-100" *ngIf="showList">
    <plex-title size="sm" titulo="número de carpeta y organización" *ngIf="carpetaEfectores?.length > 0">
    </plex-title>
    <plex-wrapper class="w-100 align-items-center" *ngFor="let carpeta of carpetaEfectores">
        <ng-container *ngIf="carpeta.nroCarpeta">
            <plex-label grow="4" size="lg" icon="folder-account" type="info" titulo="{{carpeta.nroCarpeta}}"
                        subtitulo="{{carpeta.organizacion.nombre}}">
            </plex-label>
            <plex-button grow="auto" size="sm"
                         *ngIf="idOrganizacion === carpeta.organizacion._id && !showEdit && autorizado" type="warning"
                         tooltipPosition="left" tooltip="Editar" icon="pencil" (click)="editar()"
                         class="d-flex align-self-center ml-2">
            </plex-button>

            <plex-wrapper class="w-100" *ngIf="idOrganizacion === carpeta.organizacion._id && showEdit && autorizado">

                <plex-text label="Editar número de carpeta" name="nroCarpeta" [(ngModel)]="nuevoNroCarpeta">
                </plex-text>
                <plex-button *ngIf="idOrganizacion === carpeta.organizacion._id && showEdit" type="danger"
                             tooltipPosition="left" tooltip="Cancelar edición" icon="close" (click)="cerrarEdicion()">
                </plex-button>
                <plex-button class="ml-2" *ngIf="idOrganizacion === carpeta.organizacion._id && showEdit"
                             [disabled]="nroCarpetaOriginal === nuevoNroCarpeta" type="success" tooltipPosition="left"
                             tooltip="Confirmar edición" icon="check" (click)="guardarCarpetaPaciente()">
                </plex-button>
            </plex-wrapper>
        </ng-container>
    </plex-wrapper>
</section>
<ng-container *ngIf="showNuevaCarpeta">
    <plex-title titulo="carpeta del paciente" size="sm">
        <plex-button *ngIf="editarCarpeta" type="success" size="sm" label="registrar nueva carpeta"
                     (click)="crearCarpetaPaciente()" tooltipPosition="left" tooltip="registrar número de carpeta">
        </plex-button>
    </plex-title>
    <plex-badge *ngIf="showList" type="warning" size="block" class="my-4">
        número de carpeta inexistente en la organización actual
    </plex-badge>
</ng-container>
<plex-wrapper class="w-100" *ngIf="!showList">
    <plex-text label="Nuevo número de Carpeta" name="nroCarpeta" [(ngModel)]="nuevoNroCarpeta">
    </plex-text>
    <plex-button size="md" type="danger" tooltipPosition="left" tooltip="Cancelar edición" (click)="cancelar()"
                 icon="close">
    </plex-button>
    <plex-button size="md" type="success" tooltipPosition="left" tooltip="Confirmar edición"
                 (click)="guardarCarpetaPaciente(true)" icon="check" class="ml-2">
    </plex-button>
</plex-wrapper>