<form #form="ngForm">
    <div class="row">
        <div class="col">
            <ng-container *ngIf="!soloValores; else showSoloValores">
                <plex-radio [(ngModel)]="valor" [data]="options" name="binario" [type]="params?.plexRadioType"
                            [required]="params?.required" [label]="label" (change)="onChange()">
                </plex-radio>
                <plex-text *ngIf="valor && (valor === 'Otro' || valor === 'No aplica')" label="Descripción"
                           name="descripcion" [(ngModel)]="registro.valor.descripcion" [required]="true">
                </plex-text>
                <ng-container *ngIf="showOkayOptions">
                    <plex-radio [(ngModel)]="valor" [data]="okayOptions" name="okayBinario" [label]=""
                                [type]="params?.plexRadioType" [required]="showOkayOptions"
                                (change)="onChangeOkayOpt()">
                    </plex-radio>
                </ng-container>
            </ng-container>
            <ng-template #showSoloValores>
                <label>
                    {{label}}
                </label>
                {{ registro | RUPSoloValor }}
            </ng-template>
        </div>
    </div>
</form>