<form #form="ngForm">
    <plex-float *ngIf="!soloValores" placeholder="Ingrese un valor entre 0 y 400" [(ngModel)]="registro.valor" name="{{ registro.concepto.term }}"
        label="Peso" suffix="Kg" min="0" max="400" (change)="emitChange()" [required]="esRequerido">
    </plex-float>
    <p *ngIf="soloValores && registro.valor" class="readonly">
        <span>Peso</span> {{ registro.valor }} Kg
    </p>
    <p *ngIf="soloValores && !registro.valor" class="readonly">
        <span>Peso</span> sin valor
    </p>
</form>