<form #form="ngForm">
    <div class="container-fluid col-12">
        <div class="row mb-3" *ngIf="permiteCarga">
            <div class="col-12">
                <strong *ngIf="!soloValores" class="pr-2">Cargar documentos</strong>
                <span *ngIf="!waiting && !soloValores">
                    <upload-file label="Desde mi PC" (onUpload)="onUpload($event)" modulo="rup"
                                 [extensiones]="extensions"></upload-file>
                    <plex-button *ngIf="!waiting" class="btn-sm" type="primary" label="Desde mi App móvil"
                                 (click)="fromMobile()"></plex-button>
                    <span *ngIf="errorExt">
                        <plex-badge type="danger">Archivo inválido</plex-badge>
                    </span>
                </span>
                <span *ngIf="waiting">
                    <plex-loader class="app-waiting" type="ball-pulse-sync"></plex-loader>
                    <plex-button type="danger" class="btn-sm" label="Cancelar" (click)="cancelar()"></plex-button>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="registro?.valor?.documentos?.length && fileToken && !parametroRegistro">

                <div class="image-container hr-inline-group">
                    <table class=" table table-striped">
                        <thead>
                            <tr>
                                <th>Documento</th>
                                <th>Tipo de Documento</th>
                                <th>Fecha</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let doc of documentos(); let i = index">
                                <td>
                                    <shared-galeria-archivos [modulo]="'rup'" [file]="doc">
                                    </shared-galeria-archivos>
                                </td>
                                <td>
                                    <plex-select *ngIf="!soloValores && descendientesInformeClinico.length"
                                                 [(ngModel)]="doc.descripcion" name="select{{i}}" idField="term"
                                                 labelField="term" [data]="descendientesInformeClinico">
                                    </plex-select>
                                    <span *ngIf="soloValores && doc.descripcion">{{doc.descripcion.term}}</span>
                                </td>
                                <td>
                                    <plex-datetime *ngIf="!soloValores" type="date" [(ngModel)]="doc.fecha"
                                                   name="fecha{{i}}" [max]="hoy">
                                    </plex-datetime>
                                    <span *ngIf="soloValores && doc.fecha">{{doc.fecha | fecha}}</span>
                                </td>
                                <td>
                                    <plex-button *ngIf="!soloValores" icon="delete" type="danger"
                                                 (click)="imageRemoved(doc)" title="Borrar documento"></plex-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</form>

<plex-badge *ngIf="!uploadValid" type="danger float-right">
    No se adjunto ningun archivo
</plex-badge>