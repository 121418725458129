<form #form="ngForm">

    <plex-title titulo="Prestar Carpeta N° {{prestamo.numero}}" size="md"></plex-title>

    <plex-text label="Destino" [(ngModel)]="prestarHC.destino" name="destino" placeholder="Ingrese el destino..."
               [disabled]="true">
    </plex-text>
    <plex-text label="Responsable" [(ngModel)]="prestarHC.responsable" name="responsable"
               placeholder="Ingrese un responsable..." [disabled]="true">
    </plex-text>
    <plex-text label="Observaciones" [(ngModel)]="prestarHC.observaciones" name="observaciones"
               placeholder="Ingrese observaciones...">
    </plex-text>

    <div class="pt-2">
        <plex-button class="float-left" label="Cancelar" type="danger" (click)="cancel()">
        </plex-button>

        <plex-button class="float-right" label="Guardar" type="success" (click)="save()" validateForm="true">
        </plex-button>
    </div>
</form>