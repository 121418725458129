<plex-layout *ngIf="autorizado && showAgenda" main="{{modelo.bloques?.length ? 6 : 12}}"
             foco="{{modelo.bloques?.length > 0? 'sidebar' : (isMobile()?'main':'')}}">

    <plex-layout-main>
        <form #form="ngForm">
            <plex-title titulo="{{(!editaAgenda)?'Crear nueva agenda':'Editar agenda'}}" size="md">
                <plex-bool class="mr-1" [(ngModel)]="modelo.enviarSms" label="Enviar SMS" name="enviarSms" type="slide">
                </plex-bool>
                <plex-button type="danger" label="Cancelar" (click)="cancelar()" size="sm">
                </plex-button>
                <plex-button class="mx-1" size="sm"
                             [disabled]="!form.valid  || !formSidebar.valid || hideGuardar || alertas.length || alertaProfesional"
                             label="Guardar" validateForm="true" autodisabled="true" type="success"
                             (click)="onSave($event, false)" title="Guardar">
                </plex-button>
                <plex-button [disabled]="!form.valid || !formSidebar.valid || hideGuardar || alertas.length || alertaProfesional"
                             label="Guardar y clonar" autodisabled="true" size="sm" validateForm="true" type="primary"
                             (click)="onSave($event, true)">
                </plex-button>
            </plex-title>
            <!-- Alertas de profesional y espacio físico -->
            <plex-grid *ngIf="alertaProfesional.length || alertaEspacioFisico.length" role="alert" type="full">
                <div *ngIf="alertaProfesional" class="alert alert-warning" role="alert">
                    <plex-icon name="alert" size="md"></plex-icon> {{alertaProfesional}}
                </div>
                <div *ngIf="alertaEspacioFisico" class="alert alert-warning" role="alert">
                    <plex-icon name="alert" size="md"></plex-icon> {{alertaEspacioFisico}}
                </div>
            </plex-grid>
            <!-- Campos importante para la creación de una agenda -->
            <plex-grid class="mb-1" type="full" responsive cols="5" colsSm="1" colsMd="3" colsLg="5">
                <plex-datetime type="date" [(ngModel)]="modelo.fecha" [required]="true" [autoFocus]="autoFocus"
                               (change)="validarTodo(); verificarProfesional(); verificarEspacioFisico()"
                               name="modelo.fecha" label="Fecha">
                </plex-datetime>
                <plex-datetime [disabled]="!modelo.fecha" type="time" [(ngModel)]="modelo.horaInicio" [required]="true"
                               (change)="validarTodo(); verificarProfesional(); verificarEspacioFisico()"
                               name="modelo.horaInicio" label="Inicio">
                </plex-datetime>
                <plex-datetime [disabled]="!modelo.horaInicio && !modelo.fecha" type="time" [(ngModel)]="modelo.horaFin"
                               [min]="horaInicioPlus()" [required]="true"
                               (change)="validarTodo(); verificarProfesional(); verificarEspacioFisico()"
                               name="modelo.horaFin" label="Fin">
                </plex-datetime>
            </plex-grid>
            <plex-wrapper>
                <plex-select [ngClass]="{'w-100' : smallScreen(), 'w-50': !smallScreen()}" [disabled]="!modelo.horaFin"
                             [(ngModel)]="modelo.tipoPrestaciones" name="modelo.tipoPrestaciones"
                             label="Tipos de prestación" tmPrestaciones="turnos:planificarAgenda:prestacion:?"
                             preload="true" ambito="ambulatorio" [multiple]="true" [required]="true"
                             (change)="cambioPrestaciones()" [closeAfterSelect]="true">
                </plex-select>
                <div class="pt-1" [ngClass]="{'w-100 mt-3' : smallScreen(), 'w-50': !smallScreen()}">
                    <plex-label class="float-left mr-3" titulo="Equipo de Salud"
                                hint="Si no encuentra el profesional en el listado, puede que no exista o no tenga permisos para las prestaciones seleccionadas"
                                hintType="info" hintIcon="eye" detach="top">
                    </plex-label>
                    <plex-select [disabled]="!modelo.horaFin || !modelo.tipoPrestaciones?.length"
                                 [(ngModel)]="modelo.profesionales" name="modelo.profesionales"
                                 (getData)="loadProfesionales($event)" [multiple]="true"
                                 labelField="apellido + ' ' + nombre" (change)="verificarProfesional()"
                                 [closeAfterSelect]="true">
                    </plex-select>
                </div>
            </plex-wrapper>
            <!-- Tipo de agenda -->
            <plex-title titulo="Tipo de agenda" size="md"></plex-title>
            <plex-grid type="full" responsive cols="6" colsSm="1" colsMd="2" colsLg="6">
                <div *feature="'agendaVirtual'">
                    <plex-bool [readonly]="!modelo.horaFin" [(ngModel)]="virtual" label="Agenda por video llamada"
                               name="agendaVirtual" type="slide">
                    </plex-bool>
                    <plex-text *ngIf="modelo.horaFin && virtual" name="link" [(ngModel)]="modelo.link" label=""
                               placeholder="Ingresar Link">
                    </plex-text>
                </div>
                <div>
                    <plex-bool [readonly]="disabledDinamica" [(ngModel)]="dinamica" name="dinamica" label="Dinámica"
                               type="slide" (change)="seleccionarDinamica($event)">
                    </plex-bool>
                </div>
                <div>
                    <plex-bool [readonly]="!modelo.horaFin" [(ngModel)]="multiprofesional" name="multiprofesional"
                               label="Multiprofesional" type="slide">
                    </plex-bool>
                </div>
            </plex-grid>
            <!-- Espacio Físico -->
            <plex-grid *ngIf="modelo.horaInicio && modelo.horaFin && showBloque && modelo.bloques?.length" type="full"
                       cols="1">
                <plex-title titulo="Espacio físico" size="md">
                    <plex-bool [(ngModel)]="espacioFisicoPropios" name="espacioFisicoPropios" type="slide"
                               label="{{textoEspacio}}" (change)="filtrarEspacioFisico()">
                    </plex-bool>
                </plex-title>
                <plex-select *ngIf="espacioFisicoPropios" [(ngModel)]="modelo.espacioFisico" name="espacioFisico"
                             (getData)="loadEspaciosFisicos($event)" label="Seleccione un espacio físico del efector"
                             placeholder="Seleccione un espacio físico"
                             labelField="nombre + ' - ' + servicio.nombre + ' (' + edificio.descripcion + ')'"
                             (change)="validarTodo(); verificarEspacioFisico()">
                </plex-select>
                <plex-select *ngIf="!espacioFisicoPropios" [(ngModel)]="modelo.otroEspacioFisico" name="otrosEspacios"
                             (getData)="loadEspaciosFisicos($event)" label="Seleccione un espacio físico"
                             placeholder="Seleccione un espacio físico" labelField="nombre">
                </plex-select>
            </plex-grid>
            <!-- Bloques -->
            <plex-grid *ngIf="showBloque && modelo?.bloques?.length" type="full" cols="1">
                <plex-title titulo="Bloques" size="md">
                    <plex-button *ngIf="!dinamica" icon="plus" type="success btn-sm" title="Agregar Bloque"
                                 (click)="addBloque()">
                    </plex-button>
                </plex-title>
                <plex-list [selectable]="true" [striped]="false">
                    <plex-item *ngFor="let unBloque of modelo.bloques; let i=index" (click)="activarBloque(i)"
                               [selected]="i==bloqueActivo">
                        <plex-label *ngIf="unBloque.horaInicio && unBloque.horaFin"
                                    titulo="{{unBloque.horaInicio | date: 'HH:mm'}} a {{unBloque.horaFin | date: 'HH:mm'}}"
                                    size="md"></plex-label>
                        <plex-label *ngIf="unBloque.descripcion" titulo="{{unBloque.descripcion}}" size="md">
                        </plex-label>
                        <plex-button *ngIf="!dinamica && modelo.bloques.length > 1" icon="delete" type="danger"
                                     size="sm" (click)="deleteBloque(i)">
                        </plex-button>
                    </plex-item>
                </plex-list>
            </plex-grid>
        </form>
    </plex-layout-main>
    <plex-layout-sidebar type="invert">
        <form #formSidebar="ngForm">
            <ng-container *ngIf="!dinamica">
                <plex-title titulo="Crear nuevo bloque" size="md"></plex-title>
                <plex-grid *ngIf="alertas.length" role="alert" type="full">
                    <div *ngFor="let alert of alertas; let i=index" class="alert alert-warning" role="alert">
                        <plex-icon name="alert" size="md"></plex-icon> {{alert}}
                    </div>
                </plex-grid>
                <!-- Información de configuracion de los turnos del bloque -->
                <plex-grid *ngIf="elementoActivo && bloqueActivo >= 0" type="full" cols="4">
                    <plex-datetime *ngIf="!modelo.intercalar" type="time" [(ngModel)]="elementoActivo.horaInicio"
                                   [name]="'horaInicio' + bloqueActivo" [required]="true"
                                   (blur)="cambioHoraBloques( 'inicio' )" label="Hora Inicio">
                    </plex-datetime>
                    <plex-datetime *ngIf="!modelo.intercalar" type="time" [(ngModel)]="elementoActivo.horaFin"
                                   name="horaFin" [required]="true" (blur)="cambioHoraBloques( 'fin' )"
                                   label="Hora Fin">
                    </plex-datetime>
                    <plex-int *ngIf="modelo.nominalizada" [(ngModel)]="elementoActivo.cantidadTurnos"
                              name="cantidadTurnos" (keyup)="cambiaTurnos( 'cantidad' )" label="Cantidad de Turnos"
                              [required]="true" min=1 placeholder="Ingrese un valor">
                    </plex-int>
                    <plex-int *ngIf="modelo.nominalizada" [(ngModel)]="elementoActivo.duracionTurno"
                              name="duracionTurno" (keyup)="cambiaTurnos( 'duracion' )" label="Duración del Turno"
                              [required]="true" min=1 placeholder="Ingrese un valor">
                        <span right>minutos</span>
                    </plex-int>
                </plex-grid>
                <plex-text [(ngModel)]="elementoActivo.descripcion" name="descripcion" label="Descripción"></plex-text>
                <!-- Prestaciones asociadas al bloque -->
                <plex-title titulo="Tipo de prestación asociada" size="md"></plex-title>
                <plex-grid responsive type="full" cols="2" colsSm="1" colsMd="2">
                    <div *ngFor="let unaPrestacion of elementoActivo.tipoPrestaciones; let i=index">
                        <plex-bool [(ngModel)]="elementoActivo.tipoPrestaciones[i].activo" [name]=" 'activo' +
                               i" label="{{unaPrestacion.nombre}}" type="slide">
                        </plex-bool>
                    </div>
                </plex-grid>
                <ng-container *ngIf="modelo.nominalizada">
                    <!-- Turnos de acceso directo -->
                    <plex-title titulo="Acceso Directo" size="md" class="mt-4"></plex-title>

                    <plex-grid responsive type="full" cols="2" colsSm="1" colsMd="2" colsLg="2">
                        <div direction="column">
                            <label>DEL DÍA</label>
                            <plex-grid responsive type="full" cols="2">
                                <plex-int [(ngModel)]="elementoActivo.accesoDirectoDelDia" [min]="0" [required]="true"
                                          (keyup)="cambiaCantTipo( 'accesoDirectoDelDia' )" name="accesoDirectoDelDia">
                                </plex-int>
                                <plex-int [(ngModel)]="elementoActivo.accesoDirectoDelDiaPorc" [min]="0"
                                          (keyup)="cambiaPorcentajeTipo( 'accesoDirectoDelDia' )"
                                          name="accesoDirectoDelDiaPorc">
                                    <span right>%</span>
                                </plex-int>
                            </plex-grid>
                        </div>
                        <div direction="column">
                            <label>PROGRAMADOS</label>
                            <plex-grid responsive type="full" cols="2">
                                <plex-int [(ngModel)]="elementoActivo.accesoDirectoProgramado" [min]="0"
                                          [required]="true" (keyup)="cambiaCantTipo( 'accesoDirectoProgramado' )"
                                          name="accesoDirectoProgramado">
                                </plex-int>
                                <plex-int [(ngModel)]="elementoActivo.accesoDirectoProgramadoPorc" [min]="0"
                                          (keyup)="cambiaPorcentajeTipo( 'accesoDirectoProgramado' )"
                                          name="accesoDirectoProgramadoPorc">
                                    <span right>%</span>
                                </plex-int>
                            </plex-grid>
                        </div>
                    </plex-grid>
                    <!-- Turnos reservados -->
                    <plex-title titulo="Reservados" size="md" class="mt-4"></plex-title>
                    <plex-grid responsive type="full" cols="2" colsSm="1" colsMd="2">
                        <div direction="column">
                            <label>CON LLAVE</label>
                            <plex-grid responsive type="full" cols="2">
                                <plex-int [(ngModel)]="elementoActivo.reservadoGestion" [min]="0" [required]="true"
                                          (keyup)="cambiaCantTipo( 'reservadoGestion')" name="reservadoGestion">
                                </plex-int>
                                <plex-int [(ngModel)]="elementoActivo.reservadoGestionPorc" [min]="0"
                                          (keyup)="cambiaPorcentajeTipo( 'reservadoGestion')"
                                          name="reservadoGestionporc">
                                    <span right>%</span>
                                </plex-int>
                            </plex-grid>
                        </div>
                        <div direction="column">
                            <label>PARA PROFESIONAL</label>
                            <plex-grid responsive type="full" cols="2">
                                <plex-int [(ngModel)]="elementoActivo.reservadoProfesional" [min]="0" [required]="true"
                                          (keyup)="cambiaCantTipo( 'reservadoProfesional')" name="reservadoProfesional">
                                </plex-int>
                                <plex-int [(ngModel)]="elementoActivo.reservadoProfesionalPorc" [min]="0"
                                          (keyup)="cambiaPorcentajeTipo( 'reservadoProfesional')"
                                          name="progReservadoPorc">
                                    <span right class="prueba">%</span>
                                </plex-int>
                            </plex-grid>
                        </div>
                    </plex-grid>

                    <plex-grid responsive type="full" cols="3" colsSm="1" colsMd="3" colsLg="3">
                        <plex-bool *ngIf="elementoActivo.accesoDirectoProgramado > 0 && mobileEnabled"
                                   [(ngModel)]="elementoActivo.turnosMobile" label="Ventanilla virtual"
                                   name="turnosMobile" (change)="onVentanillaVirtualChange($event)"></plex-bool>
                        <plex-int *ngIf="elementoActivo.turnosMobile && elementoActivo.accesoDirectoProgramado > 0 && mobileEnabled"
                                  [(ngModel)]="elementoActivo.cupoMobile" min="1"
                                  [max]="elementoActivo.accesoDirectoProgramado" name="cupoMobile"
                                  tooltip="Este no es un cupo extra, es parte del cupo de programados"
                                  tooltipPosition="top" [required]="elementoActivo.turnosMobile">
                            <span right>Turnos</span>
                        </plex-int>
                    </plex-grid>

                    <plex-grid responsive type="full" cols="3" colsSm="1" colsMd="3" colsLg="3">
                        <plex-bool *ngIf="!elementoActivo.citarPorBloque"
                                   [(ngModel)]="elementoActivo.pacienteSimultaneos" label="Pacientes simultáneos"
                                   name="pacienteSimultaneos"></plex-bool>
                        <plex-int *ngIf="elementoActivo.pacienteSimultaneos"
                                  [(ngModel)]="elementoActivo.cantidadSimultaneos" name="cantidadSimultaneos" [min]=1
                                  required>
                            <span right>Pacientes</span>
                        </plex-int>
                    </plex-grid>

                    <plex-grid responsive type="full" cols="3" colsSm="1" colsMd="3" colsLg="3">
                        <plex-bool *ngIf="!elementoActivo.pacienteSimultaneos"
                                   [(ngModel)]="elementoActivo.citarPorBloque" label="Citar por segmento"
                                   name="citarPorBloque"></plex-bool>
                        <plex-int *ngIf="elementoActivo.citarPorBloque" [(ngModel)]="elementoActivo.cantidadBloque"
                                  [min]=1 [max]="elementoActivo.cantidadTurnos" name="cantidadBloque" required>
                            <span right>Pacientes</span>
                        </plex-int>
                    </plex-grid>
                </ng-container>
            </ng-container>
            <!-- Agendas dinámicas -->
            <ng-container *ngIf="dinamica">
                <plex-title titulo="Agenda Dinámica" size="md"></plex-title>
                <plex-grid type="full">
                    <plex-text [(ngModel)]="elementoActivo.descripcion" name="descripcion" label="Descripción">
                    </plex-text>
                    <plex-bool [(ngModel)]="setCupo" name="cupo" label="Cupo máximo"> </plex-bool>
                    <plex-int *ngIf="setCupo" [(ngModel)]="cupoMaximo" name="cupoMaximo" min="1" max="1000"
                              [required]="true">
                    </plex-int>
                </plex-grid>
            </ng-container>
        </form>
    </plex-layout-sidebar>
</plex-layout>

<clonar-agenda *ngIf="showClonar && autorizado" [agenda]="modelo" (volverAlGestor)="cancelar()"></clonar-agenda>
<modal-agenda *ngIf="showModal" #modalAgenda [dato]="datos" (close)="cerrarModal()"></modal-agenda>