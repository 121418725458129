<form #form="ngForm" (keydown.enter)="$event.preventDefault()" *ngIf="!soloValores">
    <plex-title size="sm" titulo="Principio Activo">
        <plex-button size="sm" icon="plus" type="info" (click)="addSustancia()" tooltip="Agregar nuevo item"
                     tooltipPosition="left"> </plex-button>
        <plex-button size="sm" icon="delete" type="danger" (click)="deleteSustancia()" tooltip="Eliminar último item"
                     tooltipPosition="left" [disabled]="registro.valor.sustancias.length === 1" class="ml-1">
        </plex-button>

    </plex-title>

    <!-- PRINCIPIO ACTIVO -->
    <ng-container *ngFor="let sustancia of registro.valor.sustancias">
        <plex-grid cols="2" class="mr-3">
            <plex-select [(ngModel)]="sustancia.ingrediente" name="generico" (getData)="loadMedicamentoGenerico($event)"
                         label="Principio activo" idField="conceptId" labelField="term" (change)="valuesChange()"
                         required="true" span="3">
            </plex-select>
            <plex-text label="Valor Dosis" [(ngModel)]="sustancia.dosisValor" name="vDosis" required
                       (change)="valuesChange()">
            </plex-text>
            <plex-select label="Unidad Dosis" [(ngModel)]="sustancia.dosisUnidad" [snomedExpression]="unidadesSnomed"
                         name="uDosis" required (change)="valuesChange()"></plex-select>
        </plex-grid>
    </ng-container>

    <plex-wrapper>
        <plex-select [(ngModel)]="registro.valor.via" name="via" [snomedExpression]="viasSnomed"
                     (change)="valuesChange()" label="Vía de administración" [preload]="true">
        </plex-select>

        <plex-select [(ngModel)]="registro.valor.presentacion" name="presentacion"
                     [snomedExpression]="formasFarmaceuticasSnomed" (change)="valuesChange()" label="Forma Farmacéutica"
                     [preload]="true">
        </plex-select>
    </plex-wrapper>
    <plex-text class="mb-2" *ngIf="afterInit" label="Preparación" [(ngModel)]="registro.valor.preparacion"
               name="indicaciones" [multiline]="true">
    </plex-text>

    <!-- FRECUENCIA -->
    <plex-title size="sm" titulo="Frecuencia"></plex-title>

    <plex-wrapper *ngFor="let frecuencia of registro.valor.frecuencias; let i = index">
        <plex-select [(ngModel)]="frecuencia.frecuencia" name="frecuencia" [data]="frecuencias$ | async"
                     label="Frecuencia" [preload]="true" [required]="!registro.valor.unicaVez"
                     [disabled]="registro.valor.unicaVez">
        </plex-select>

        <plex-text label="Velocidad Infusión" [(ngModel)]="frecuencia.velocidad" name="velocidad"></plex-text>

        <plex-datetime type="time" name="hora" label="Horario" [(ngModel)]="frecuencia.horario"
                       [required]="frecuencia.frecuencia?.type === 'number' || registro.valor.unicaVez" [min]="fechaMin"
                       [max]="fechaMax" [disabled]="frecuencia.frecuencia && frecuencia.frecuencia.type !== 'number'"
                       span="1">
        </plex-datetime>

        <plex-grid *ngIf="i === 0" cols="2">
            <plex-bool class="mb-3" type="slide" name="boolUnicaVez" justify="start" label="Única Vez"
                       [(ngModel)]="registro.valor.unicaVez" (change)="onChangeUnicaVez($event)" span="2">
            </plex-bool>
            <plex-text *ngIf="registro.valor.unicaVez" [multiline]="true" [(ngModel)]="registro.valor.motivoUnicaVez"
                       name="indicaciones" span="2">
            </plex-text>
        </plex-grid>
    </plex-wrapper>

    <plex-title size="sm" titulo="Aclaraciones y/o Comentarios"></plex-title>
    <div class="mr-3 mt-2">
        <plex-text *ngIf="afterInit" [html]="true" height="100" [(ngModel)]="registro.valor.indicaciones"
                   name="aclaraciones">
        </plex-text>
    </div>
</form>

<ng-container *ngIf="soloValores">
    <plex-grid cols="3" size="sm">
        <plex-label titulo="Medicamento" [subtitulo]="registro.valor.nombre || 'Sin especificar'">
        </plex-label>
        <plex-label *ngIf="registro.valor.via" titulo="Via de Administración"
                    [subtitulo]="registro.valor.via.term || 'Sin especificar'">
        </plex-label>
        <plex-label *ngIf="registro.valor.presentacion" titulo="Unidad de presentación"
                    [subtitulo]="registro.valor.presentacion.term || 'Sin especificar'">
        </plex-label>
    </plex-grid>

    <plex-title size="sm" titulo="Frecuencia"></plex-title>

    <plex-grid cols="3" *ngFor="let frecuencia of registro.valor.frecuencias">
        <plex-label titulo="Frecuencia"
                    [subtitulo]="registro.valor.unicaVez ? 'Única vez' : frecuencia.frecuencia?.nombre || ''">
        </plex-label>
        <plex-label titulo="Velocidad Infusión" [subtitulo]="frecuencia.velocidad || 'Sin especificar'">
        </plex-label>
        <plex-label *ngIf="frecuencia.horario" titulo="Horario"
                    [subtitulo]="frecuencia.horario ? (frecuencia.horario | hora) : frecuencia.frecuencia?.nombre">
        </plex-label>
        <plex-label *ngIf="registro.valor.motivoUnicaVez" span="3" titulo="Motivo Única Vez" span="3"
                    [subtitulo]="registro.valor.motivoUnicaVez">
        </plex-label>
    </plex-grid>

    <plex-label titulo="Preparación" *ngIf="registro.valor.preparacion" [subtitulo]="registro.valor.preparacion">
    </plex-label>

    <div *ngIf="registro.valor.indicaciones" class="mt-3">
        <plex-label titulo="Aclaraciones y/o Comentarios">
        </plex-label>
        <div class="mb-3 aclaraciones">
            <span [innerHTML]="registro.valor.indicaciones"></span>
        </div>
    </div>
</ng-container>