<ng-container *ngIf="referenceSet.length > 0">
    <div class="row">
        <div class="col-12">
            <plex-badge *ngFor="let rf of referenceSet" type="danger">
                {{ rf }}
            </plex-badge>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!soloValores">
    <plex-text *ngIf="afterInit" label="Informes" [(ngModel)]="registro.valor" id="informes" name="informes"
               [html]="true" rows="3" [readonly]="false" placeholder="Ingrese un informe" (change)="emitChange()">
    </plex-text>
</ng-container>

<p *ngIf="soloValores" class="readonly">
    <!-- <span>Informes</span> -->
    <ng-container>
        <span [innerHTML]="registro.valor"></span>
    </ng-container>
</p>