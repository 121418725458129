<!--Header de estadisticas-->
<div class="mt-3">
    <plex-title titulo="INFORMACIÓN ÚTIL" size="md"></plex-title>
</div>

<plex-grid class="pt-4" cols="5" size="sm">
    <plex-icon justify="center" name="account-check" size="xxl"></plex-icon>
    <h5 class="mr-4" span="4"><b>Paciente con dni</b> escanee el documento para validar sus datos </h5>
</plex-grid>

<plex-grid class="pt-2" cols="5" size="sm">
    <plex-icon justify="center" name="account-outline" size="xxl"></plex-icon>
    <h5 class="mr-4" span="4"><b>Paciente sin dni</b> puede ingresar sus datos a través del botón "Nuevo Paciente"</h5>
</plex-grid>