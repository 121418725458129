<plex-layout *ngIf="!showCrearAgenda" main="6">
    <plex-layout-main>
        <div *ngIf="autorizado && showReasignarTurno" class="plex-layout">

            <plex-title titulo="REASIGNACIÓN DE TURNOS">
                <plex-button type="danger" (click)="cancelar()">Volver</plex-button>
            </plex-title>

            <plex-list [selectable]="false">
                <plex-item>
                    <plex-label *ngIf="agendaAReasignar.length === 0" class="flex-column" icon="calendar" size="xl"
                                direction="column" titulo="No quedan Turnos para reasignar en esta Agenda">
                    </plex-label>

                    <plex-icon name="calendario-fecha" type="info" size="xl"></plex-icon>

                    <plex-label titulo="Agenda Origen" case="capitalize"
                                subtitulo="{{agendaAReasignar.horaInicio | date: 'EEEE'}} {{agendaAReasignar.horaInicio | fecha}} 
                        de {{agendaAReasignar?.horaInicio|date:'HH:mm'}} a {{agendaAReasignar.horaFin | date: 'HH:mm'}} hs" size="lg">
                    </plex-label>

                    <plex-label titulo="Prestaciones" case="capitalize"
                                subtitulo="{{ agendaAReasignar.tipoPrestaciones | enumerar:['term']:'/' }}" size="lg">
                    </plex-label>

                    <plex-label *ngIf="agendaAReasignar.profesionales?.length" titulo="Profesionales"
                                subtitulo="{{ agendaAReasignar.profesionales | enumerar:['apellido','nombre'] }}"
                                size="lg">
                    </plex-label>

                    <plex-label *ngIf="agendaAReasignar.profesionales?.length === 0" titulo="Profesionales"
                                subtitulo="Sin profesionales asignados" size="lg">
                    </plex-label>
                </plex-item>
            </plex-list>

            <!--Turnos-->
            <plex-title titulo="TURNOS" size="sm"></plex-title>
            <plex-table *ngFor="let bloque of agendaAReasignar.bloques | sortBloques" class="table table-striped">
                <tr *ngIf="bloque === agendaAReasignar.bloques[0]">
                    <th class="text-center w-25">Nombre</th>
                    <th class="text-center">Tipo Turno</th>
                    <th class="text-center">Prestación</th>
                    <th class="text-center">Estado</th>
                </tr>

                <tr *ngFor="let turno of bloque.turnos; let i=index" class="hover"
                    [class.selected]="estaSeleccionado(turno)">
                    <ng-container *ngIf="turno.paciente?.id">
                        <td class="text-center" (click)="seleccionarTurno(turno, bloque, false)">
                            <span *ngIf="turno && turno.paciente">{{ turno.paciente | nombre }} </span>
                            <small>
                                <span>|
                                    {{ turno.paciente.numeroIdentificacion ? 'DOC EXT:' : 'DNI:' }} {{ turno.paciente |
                                    documento }}
                                </span>
                            </small>
                            <small>
                                <span
                                      *ngIf="turno?.paciente?.carpetaEfectores && turno.paciente.carpetaEfectores[0]?.nroCarpeta">
                                    <br /> Nro Carpeta
                                    <span *ngFor="let carpeta of turno.paciente.carpetaEfectores | slice:0:1;">{{
                                        carpeta.nroCarpeta }}</span>
                                </span>
                            </small>
                        </td>
                        <td class="text-center" (click)="seleccionarTurno(turno, bloque, false)">
                            <plex-badge type="info" *ngIf="turno?.tipoTurno">{{ turno.tipoTurno ?
                                tiposDeTurnos[turno.tipoTurno].nombre
                                : 'sin-tipo' }}
                            </plex-badge>
                        </td>
                        <td class="text-center" (click)="seleccionarTurno(turno, bloque, false)">
                            <small>
                                <span *ngIf="turno?.tipoPrestacion?.nombre" case="capitalize">
                                    {{turno.tipoPrestacion.nombre}}
                                </span>
                            </small>
                        </td>
                        <td class="text-center" (click)="seleccionarTurno(turno, bloque, false)">
                            <plex-badge *ngIf="turno?.reasignado?.siguiente" type="info badge-reasignar">
                                <plex-icon name="check"></plex-icon>
                                ya reasignado
                            </plex-badge>
                            <plex-badge *ngIf="!turno?.reasignado?.siguiente" type="warning badge-reasignar">
                                <plex-icon name="account-convert"></plex-icon>
                                sin reasignar
                            </plex-badge>
                        </td>
                    </ng-container>
                </tr>
            </plex-table>
        </div>

    </plex-layout-main>

    <plex-layout-sidebar type="invert" *ngIf="!showCrearAgenda">
        <reasignar-turno-agendas [agendasSimilares]="agendasSimilares" [turnoAReasignar]="turnosSeleccionados[0]"
                                 [agendaAReasignar]="agendaAReasignar" [datosAgenda]="datosAgenda"
                                 [agendaDestino]="agendaDestino" [smsStatus]="smsActivo"
                                 (turnoReasignadoEmit)="reasignacionManualAgendas($event)"
                                 (crearAgendaEmit)="crearAgenda()">
        </reasignar-turno-agendas>
    </plex-layout-sidebar>

    <permisos *ngIf="!autorizado" [permisos]="permisosRequeridos" [botonCancelar]="true" (cancelarEmit)="cancelar()">
    </permisos>

</plex-layout>

<planificar-agenda *ngIf="showCrearAgenda" (volverAlGestor)="cancelar()">
</planificar-agenda>