<div class="mt-2">
    <plex-title size="sm" titulo="Agregar relaciones"></plex-title>
</div>

<paciente-buscar label="Buscar paciente" (searchStart)="onSearchStart()" (searchEnd)="onSearchEnd($event.pacientes)"
                 (searchClear)="onSearchClear()">
</paciente-buscar>

<plex-loader *ngIf="loading" type="ball-pulse"></plex-loader>

<paciente-listado [pacientes]="posiblesRelaciones" (selected)="seleccionarRelacionEntrante($event)" [offset]="160"
                  *ngIf="posiblesRelaciones.length > 0" [showRelaciones]="false">
</paciente-listado>

<div *ngIf="posiblesRelaciones && !posiblesRelaciones.length && !searchClear" class="alert alert-danger">
    <plex-icon name="account-alert"></plex-icon> No se encontró ningún paciente..
</div>

<!-- lista de un único elemento -->
<plex-list *ngIf="relacionEntrante.length">
    <plex-item *ngFor="let unaRel of relacionEntrante" class="">
        <!-- caso paciente relacionado -->
        <img *ngIf="unaRel.referencia" [ngClass]="{'img-fallecido' : unaRel.fechaFallecimiento}"
             [mpiFotoPaciente]="{id: unaRel.referencia, fotoId: unaRel.fotoId}">
        <!-- caso paciente aun no relacionado -->
        <img *ngIf="!unaRel.referencia && unaRel.id && unaRel.fotoId"
             [ngClass]="{'img-fallecido' : unaRel.fechaFallecimiento}"
             [mpiFotoPaciente]="{id: unaRel.id, fotoId: unaRel.fotoId}" alt="">

        <plex-label titulo="{{ unaRel | nombre }}" subtitulo="{{ unaRel | documento }}">
        </plex-label>

        <plex-label *ngIf="unaRel.fechaNacimiento" titulo="{{ unaRel | edad }}"
                    subtitulo="{{ (unaRel.fechaNacimiento | fecha:'utc') || 'Desconocida' }}">
        </plex-label>

        <plex-grid size="sm" type="full">
            <plex-select class="vinculo" [(ngModel)]="unaRel.relacion" name="nuevaRelacion" [data]="parentescoModel"
                         [required]="true" placeholder="Seleccione vínculo...  ">
            </plex-select>
        </plex-grid>

        <!-- Se muestra con un 'OR' porque no todas las relaciones guardan el genero -->
        <plex-badge type="info">{{ unaRel.genero || unaRel.sexo }}</plex-badge>

        <plex-badge *ngIf="unaRel.estado" type="" class=""
                    [ngClass]="{'text-success' : unaRel.estado === 'validado' , 'text-warning' : unaRel.estado === 'temporal'} ">
            {{ unaRel.estado }}
        </plex-badge>

        <plex-badge type="danger" *ngIf="unaRel.fechaFallecimiento"> Fallecido
        </plex-badge>

        <plex-button label="" type="success" size="sm" icon="plus" tooltip="Agregar" [disabled]="!unaRel.relacion"
                     (click)="addRelacion(unaRel)">
        </plex-button>

        <plex-button type="danger" size="sm" icon="close" tooltip="Borrar" (click)="relacionEntrante=[]">
        </plex-button>
    </plex-item>
</plex-list>