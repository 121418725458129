<plex-tabs size="full" [activeIndex]="showTab">
    <plex-tab icon="information" label="Datos">
        <!--Header de estadisticas-->
        <ng-container *ngIf="paciente">
            <paciente-detalle [paciente]="paciente" orientacion="horizontal" [fields]="pacienteFields">
            </paciente-detalle>
            <br>
            <update-contacto-direccion [pac]="paciente"></update-contacto-direccion>
        </ng-container>
    </plex-tab>
    <plex-tab icon="clock" label="Turnos">
        <turnos-paciente [paciente]='paciente' [turnos]="turnosPaciente$ | async" [operacion]="'operacionTurnos'"
                         (turnosPacienteChanged)="refresh()" *plTab>
        </turnos-paciente>
        <ng-container *ngIf="demandaInsatisfecha">
            <demandaInsatisfecha (demandaCerrada)="cerrarDemandaInsatisfecha()" [paciente]='paciente'>
            </demandaInsatisfecha>
        </ng-container>
    </plex-tab>
    <plex-tab icon="history" label="Historial" *ngIf="!(paciente | pacienteRestringido)">
        <fieldset *plTab>
            <ul class="list-group">
                <li *ngFor="let turno of ultimosTurnos$ | async; let i=index" class="list-group-item"
                    [appHover]="'active'">
                    <div class="list-group-item-text">
                        <div>
                            <plex-badge *ngIf="turno.asistencia === 'noAsistio' && turno.estado !== 'suspendido'"
                                        type="danger">NO ASISTIÓ</plex-badge>
                            <plex-badge *ngIf="turno.estado === 'fuera-agenda'" type="warning">
                                FUERA DE AGENDA</plex-badge>
                            <plex-badge *ngIf="turno.asistencia !== 'noAsistio' && (turno.estado === 'asignado' || turno.estado === 'turnoDoble')"
                                        type="success">
                                ASIGNADO</plex-badge>
                            <plex-badge *ngIf="turno.estado === 'liberado' || turno.estado === 'suspendido' "
                                        type="danger">
                                {{turno.estado | uppercase}}</plex-badge>

                            | {{turno.horaInicio | date:'dd/MM/yyyy HH:mm'}}
                        </div>

                        <div *ngIf="turno.estado === 'liberado'">Por {{turno.updatedBy.nombreCompleto}} el
                            {{turno.updatedAt
                            | fecha}} a las {{turno.updatedAt | date: 'HH:mm'}}</div>

                        <div *ngIf="turno.tipoPrestacion?.term">{{turno.tipoPrestacion.term}}</div>
                        <div *ngFor="let profesional of turno.profesionales">
                            {{profesional | nombre}}
                        </div>
                        <div *ngIf="turno.paciente.obraSocial">
                            Obra Social: {{turno.paciente.obraSocial.nombre}}
                        </div>
                        <div *ngIf="!turno.paciente.obraSocial">
                            Sin obra social
                        </div>
                        <div>
                            <em class="small">{{turno.organizacion.nombre}}</em>
                        </div>
                    </div>
                </li>
            </ul>
        </fieldset>
    </plex-tab>
    <plex-tab icon="folder-account" label="Carpetas">
        <carpeta-paciente *plTab [pacienteSeleccionado]="paciente"> </carpeta-paciente>
    </plex-tab>
</plex-tabs>