<plex-wrapper>
    <plex-select [(ngModel)]="via" name="conceptos" [snomedExpression]="viaExpresssion" (change)="refreshListado()"
                 label="Via de Administracion">
    </plex-select>

    <plex-select [(ngModel)]="producto" name="conceptos2" [snomedExpression]="'<<312415009'" (change)="refreshListado()"
                 label="Ingrediente" [preload]="false">
    </plex-select>

    <plex-select [(ngModel)]="unidadPresentacion" name="conceptos3" [snomedExpression]="unidadPresentacionExpression"
                 (change)="refreshListado()" label="Unidad de Presentación">
    </plex-select>

    <plex-select [(ngModel)]="potencia" name="conceptos4" [snomedExpression]="potenciaExpression"
                 (change)="refreshListado()" label="Potencia">
    </plex-select>
    <!-- <plex-select [(ngModel)]="rolTerapeutico" name="conceptos5" [snomedExpression]="rolTerapeuticoExpression"
             (change)="refreshListado()" label="Rol Terapeutico">
</plex-select> -->

</plex-wrapper>

<plex-list [height]="'300px'">
    <plex-item *ngFor="let concepto of listado" (click)="onClick(concepto)">
        <plex-label> {{ concepto.term }} </plex-label>
    </plex-item>
</plex-list>