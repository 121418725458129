<plex-layout [main]="seleccionada ? 8 : 12">
    <plex-layout-main>
        <plex-title titulo="Campañas de Salud">
            <plex-button label="Crear" type="success" (click)="crearCampania()"></plex-button>
        </plex-title>
        <plex-wrapper>
            <plex-datetime grow="2" type="date" [(ngModel)]="fechaDesde" (change)="aplicarFiltrosBusqueda()"
                           name="fechaDesde" label="Vigencia desde" [required]="true" [max]="fechaHasta">
            </plex-datetime>
            <plex-datetime grow="2" type="date" [(ngModel)]="fechaHasta" (change)="aplicarFiltrosBusqueda()"
                           name="fechaHasta" label="Vigencia hasta" [required]="true" [min]="fechaDesde">
            </plex-datetime>
        </plex-wrapper>
        <plex-label *ngIf="!campanias?.length" type="warning" size="xl" direction="column" titulo='Sin resultados'
                    subtitulo="No se encontraron campañas que coincidan con los criterios de búsqueda">
        </plex-label>
        <plex-table *ngIf="campanias?.length" [columns]="columns" #table="plTable" [offset]="0">
            <plex-table-columns></plex-table-columns>
            <tr class="hover" *ngFor="let campania of campanias" (click)="seleccionCampania(campania)">
                <td>{{campania.asunto}}</td>
                <td>{{campania.vigencia.desde | fecha}}</td>
                <td>{{campania.vigencia.hasta | fecha}}</td>
                <td>
                    <plex-badge *ngIf="campania.activo" type="success">
                        {{campaniaSaludService.getEstado(campania)}} </plex-badge>
                    <plex-badge *ngIf="!campania.activo" type="danger">
                        {{campaniaSaludService.getEstado(campania)}} </plex-badge>
                </td>
            </tr>
        </plex-table>
    </plex-layout-main>
    <plex-layout-sidebar type="invert">
        <campaniaSaludVisualizacion *ngIf="seleccionada && mostrarVisualizacionCampania" [campania]="seleccionada"
                                    (modificarOutput)='editarCampania()' (cerrar)="cerrarCampania()">
        </campaniaSaludVisualizacion>
        <campaniaForm *ngIf="seleccionada &&  !mostrarVisualizacionCampania" [campania]="seleccionada"
                      (guardar)='guardarCampania($event)' (cancelar)='cancelarEdicionCampania($event)'></campaniaForm>
    </plex-layout-sidebar>
</plex-layout>
