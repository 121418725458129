<!-- Tipo de búsqueda -->
<div class="row mt-1">
    <div class="btn-group col botonera principal" role="group" aria-label="Botonera principal">
        <button class="btn btn-primary btn-sm m-0" (click)="setTipoBusqueda('sugeridos')"
                [class.active]="busquedaActual === 'sugeridos'">
            SUGERIDOS
        </button>
        <button class="btn btn-primary btn-sm m-0" (click)="setTipoBusqueda('misFrecuentes')"
                [class.active]=" busquedaActual === 'misFrecuentes'">
            MIS FRECUENTES
        </button>
        <button class="btn btn-primary btn-sm btn-block m-0" (click)="setTipoBusqueda('buscadorBasico')"
                [class.active]="busquedaActual === 'buscadorBasico'">
            BUSCADOR BÁSICO
        </button>
        <button class="btn btn-primary btn-sm btn-block m-0" (click)="setTipoBusqueda('frecuentesTP')"
                [class.active]="busquedaActual === 'frecuentesTP'">
            FRECUENTES POR PRESTACION
        </button>
    </div>
</div>
<!-- Fin Tipo de búsqueda -->

<snomed-buscar (onSearch)="recibeResultados($event)" *ngIf="busquedaActual === 'buscadorBasico'" [autofocus]="true"
               id="buscador"></snomed-buscar>

<div class="buscador"
     *ngIf="busquedaActual === 'sugeridos' || busquedaActual === 'misFrecuentes' || busquedaActual === 'frecuentesTP'">
    <plex-text [(ngModel)]="search" name="search" (change)="buscar()" placeholder=" Buscar ..." [autoFocus]="true">
    </plex-text>
</div>

<div *ngIf="descendienteSearch && busquedaActual === 'buscadorBasico'">
    <plex-badge type="info">
        DESCENDIENTES DE {{ descendienteSearch.term.substr(0, 30) }}
        <plex-button (click)="removeDescendiente()" icon="close">
        </plex-button>
    </plex-badge>
</div>

<!-- MAESTRO DE PROBLEMAS - BD:tipoProblema -->
<div class="menu-buscador">
    <!-- Botones filtros -->
    <ng-container *ngIf="results">
        <div class="container-filtros">
            <ul class="botones-filtros">
                <li>
                    <small *ngIf="getCantidadResultados('todos')" class="contador badge-todos ml-1 float-right"
                           [class.active]="filtroActual === 'todos'">
                        {{ getCantidadResultados('todos')}}
                    </small>
                    <button (click)="filtroBuscadorSnomed('todos')" [class.active]="filtroActual === 'todos'"
                            [disabled]="getCantidadResultados('todos') === 0" class="btn btn-block btn-todos">
                        <i class="adi adi-semantics"></i>
                        TODOS
                    </button>
                </li>
                <li>
                    <small *ngIf="getCantidadResultados('hallazgos')" class="contador badge-hallazgo ml-1 float-right"
                           [class.active]="filtroActual === 'hallazgos'">
                        {{ getCantidadResultados('hallazgos') }}
                    </small>
                    <button (click)="filtroBuscadorSnomed('hallazgos')" [class.active]="filtroActual === 'hallazgos'"
                            [disabled]="getCantidadResultados('hallazgos') === 0" class="btn btn-block btn-hallazgo">
                        <i class="adi adi-lupa-ojo"></i>
                        HALLAZGOS
                    </button>
                </li>

                <li>
                    <small *ngIf="getCantidadResultados('trastornos')" class="contador badge-trastorno ml-1 float-right"
                           [class.active]="filtroActual === 'trastornos'">
                        {{ getCantidadResultados('trastornos') }}
                    </small>
                    <button (click)="filtroBuscadorSnomed('trastornos')" [class.active]="filtroActual === 'trastornos'"
                            [disabled]="getCantidadResultados('trastornos') === 0" class="btn btn-block btn-trastorno">
                        <i class="adi adi-trastorno"></i>
                        TRASTORNOS
                    </button>
                </li>

                <li>
                    <small *ngIf="getCantidadResultados('procedimientos')"
                           class="contador badge-procedimiento ml-1 float-right"
                           [class.active]="filtroActual === 'procedimientos'">
                        {{ getCantidadResultados('procedimientos') }}
                    </small>
                    <button (click)="filtroBuscadorSnomed('procedimientos')"
                            [class.active]="filtroActual === 'procedimientos'"
                            [disabled]="getCantidadResultados('procedimientos') === 0"
                            class="btn btn-block btn-procedimiento">
                        <i class="adi adi-termometro"></i>
                        PROCEDIM.
                    </button>
                </li>
                <li>
                    <small *ngIf="getCantidadResultados('planes')" class="contador badge-solicitud ml-1 float-right"
                           [class.active]="filtroActual === 'planes'">
                        {{ getCantidadResultados('planes') }}
                    </small>
                    <button (click)="filtroBuscadorSnomed('planes')" class="btn btn-block btn-solicitud"
                            [class.active]="filtroActual === 'planes'"
                            [disabled]="getCantidadResultados('planes') === 0">
                        <i class="adi adi-mano-corazon"></i>
                        SOLICITUDES
                    </button>
                </li>

                <li>
                    <small *ngIf="getCantidadResultados('productos')" class="contador badge-producto ml-1 float-right"
                           [class.active]="filtroActual === 'productos'">
                        {{ getCantidadResultados('productos') }}
                    </small>
                    <button (click)="filtroBuscadorSnomed('productos')" [class.active]="filtroActual === 'productos'"
                            [disabled]="getCantidadResultados('productos') === 0" class="btn btn-block btn-producto">
                        <i class="adi adi-pildoras"></i>
                        INSUMOS
                    </button>
                </li>
            </ul>
        </div>

        <div>
            <ng-container *ngIf="seccion$ | async as seccion">
                Sección: {{ seccion.term }} <br>
            </ng-container>

            <ng-container>
                <span *ngIf="ondontogramaDientes$ | async as dientes; else filtros">
                    Filtros:
                    <span class="text-secondary ml-1">
                        (
                        <ng-container *ngFor="let c of dientes; let idx=index; let $last=last">
                            <ng-container *ngIf="c.concepto.term">
                                <span *ngIf="c.cara">
                                    diente
                                </span>
                                {{ c.concepto.term }}
                                <span *ngIf="c.cara && c.cara !== 'pieza'">
                                    , cara {{ c.cara }}
                                    <ng-container *ngIf="!$last">
                                        /
                                    </ng-container>
                                </span>
                            </ng-container>
                        </ng-container>
                        )
                    </span>
                </span>
                <ng-template #filtros>
                    <ng-container *ngIf="getSemanticTagFiltros() as filtros">
                        Filtros:
                        <span class="text-secondary ml-1">
                            {{ filtros }}
                        </span>
                    </ng-container>
                </ng-template>
            </ng-container>
            <ng-container
                          *ngIf="results && results[busquedaActual] && results[busquedaActual][filtroActual] && results[busquedaActual][filtroActual]?.length === 0">
                <h4 class="pt-3 pb-3 text-center">No se han encontrado resultados.</h4>

                <p class="text-center"
                   *ngIf="search && (busquedaActual === 'sugeridos' || busquedaActual === 'misFrecuentes' || busquedaActual === 'frecuentesTP')">
                    Intentar con el
                    <a href="javascript: void(0);" (click)="setTipoBusqueda('buscadorBasico');">Buscador básico</a>
                </p>
            </ng-container>
        </div>
    </ng-container>
</div>

<!-- RESULTADOS PRINCIPALES -->
<div *ngIf="filtroActual && results && results[busquedaActual][filtroActual]  && results[busquedaActual][filtroActual].length > 0"
     class="buscador-resultados">
    <cdk-virtual-scroll-viewport [itemSize]="55">
        <div *cdkVirtualFor="let item of results[busquedaActual][filtroActual]; let i = index" class="rup-card mini"
             [ngClass]="item | semanticClass: item.esSolicitud || filtroActual === 'planes'">
            <div class="rup-header">
                <div class="icon-rup drag-handle p-0 pt-1" draggable
                     [dragScope]="['registros-rup' , 'vincular-registros-rup']" [dragData]="item"
                     (onDragStart)="dragStart($event)" (onDragEnd)="dragEnd($event)">
                    <i class="adi {{ item | semanticIcon: item.esSolicitud || filtroActual === 'planes'  }}"></i>
                </div>
                <div class="rup-border">
                    <div class="row p-0 m-0 border-secondary border-left-0">
                        <div class="col-6 p-0 m-0">
                            <div class="p-0 pl-2" [ngClass]="{'actions-left': filtroActual === 'equipamientos'}">
                                <snomed-link [concepto]="item"></snomed-link>
                                <span *ngIf="item.frecuencia" class="small">
                                    ({{item.frecuencia}})
                                </span>
                                <snomed-sinonimo [concepto]="item"></snomed-sinonimo>
                            </div>
                        </div>
                        <div class="col-6 p-0 m-0">
                            <div class="d-flex justify-content-end">
                                <plex-badge size="sm" [type]="item | semanticClass: filtroActual === 'planes' || item.esSolicitud">
                                    {{ (item.esSolicitud || filtroActual === 'planes') ? 'solicitud' :
                                    item.semanticTag }}
                                </plex-badge>
                                <plex-button type="info" size="sm" icon="plus" (click)="seleccionarConcepto(item, i)"
                                             tooltip="agregar a la consulta" tooltipPosition="left">
                                </plex-button>
                                <plex-button class="ml-1" type="info" size="sm" icon="graph"
                                             tooltip="filtrar por descendientes" tooltipPosition="left"
                                             (click)="filtrarPorDescendientes(item)">
                                </plex-button>
                            </div>
                        </div>
                    </div>
                    <div class="row p-0 m-0">
                        <div class="col-12 p-0 m-0 ml-2">
                            <ng-container *ngIf="busquedaActual === 'sugeridos'">
                                <span *ngIf="item?.sugeridoPor" class="sugerido">
                                    <i>Sugerido por {{ item.sugeridoPor }}</i>
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>