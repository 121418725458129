<plex-layout main="9" *ngIf="!showPrintForm">
    <plex-layout-main>
        <plex-title titulo="Padrón de cobertura social"></plex-title>
        <plex-wrapper>
            <plex-int [(ngModel)]="searchTerm" name="searchTerm" (change)="buscar()" placeholdtitler="Ingrese DNI"
                      [autoFocus]="autofocus" prefix="🔍">

            </plex-int>
            <plex-select *ngIf="periodos.length" [(ngModel)]="periodoSelect" name="periodoPadron" [data]="periodos"
                         placeholder="Seleccione un periodo" (change)="setPeriodo($event.value)" [required]="true">
            </plex-select>
        </plex-wrapper>
        <plex-label *ngIf="!searchTerm" direction="column" type="info" size="xl" icon="magnify"
                    titulo="Complete los campos para realizar su búsqueda">
        </plex-label>
        <!-- Error por paciente no encontrado -->
        <plex-label *ngIf="usuarios && !usuarios.length && searchTerm && !loading && periodoSelect" type="warning"
                    size="xl" direction="column" titulo='Sin resultados'
                    subtitulo="No se encontró ningún profesional que coincida con los criterios de búsqueda">
        </plex-label>
        <!--Loader-->
        <plex-loader *ngIf="loading" type="ball-pulse"></plex-loader>
        <plex-table *ngIf="usuarios && usuarios.length && searchTerm" [columns]="columns" #table="plTable" [offset]="0">
            <plex-table-columns>
            </plex-table-columns>
            <tr *ngFor="let usuario of usuarios">
                <td *ngIf="usuario.dni; else sinDatos">
                    {{usuario.dni}}
                </td>
                <td *ngIf="usuario.nombre; else sinDatos">{{usuario.nombre}}</td>
                <td *ngIf="usuario.financiador; else programaIC">
                    {{usuario.financiador}}
                </td>
                <td *ngIf="usuario.fechaAlta; else sinDatos">
                    Fecha de alta: {{usuario.fechaAlta | fecha}}
                </td>
                <td>
                    <plex-button label="Imprimir" type="success" [disabled]="requestInProgress"
                                 (click)="imprimirConstatacion(usuario)">
                    </plex-button>
                </td>
                <ng-template #sinDatos>
                    <td>SIN DATOS</td>
                </ng-template>
                <ng-template #programaIC>
                    <td>Programa Incluir Salud</td>
                </ng-template>
                <ng-template #noTransmite>
                    <td>No</td>
                </ng-template>
            </tr>
        </plex-table>
    </plex-layout-main>
    <plex-layout-sidebar type="invert">
        <plex-title titulo="Información" size="md">
            <plex-button label='Errores/Sugerencias' type='warning' (click)="sugerencias($event)" size="sm">
            </plex-button>
        </plex-title>
        <plex-detail>
            <div title case="capitalize" title>PUCO</div>
            <plex-label titulo="Última actualización" subtitulo="{{ultimaActualizacionPuco | fecha}}"></plex-label>
            <plex-label titulo="Padrones disponibles" subtitulo="{{listaPeriodosPuco}}"></plex-label>
        </plex-detail>
        <plex-detail>
            <div title case="capitalize" title>Incluir Salud</div>
            <plex-label titulo="Última actualización" subtitulo="{{ultimaActualizacionProfe | fecha}}"></plex-label>
            <plex-label titulo="Padrones disponibles" subtitulo="{{listaPeriodosProfe}}"></plex-label>
        </plex-detail>

        <plex-detail>
            <div title case="capitalize" title>Programa Sumar</div>
            <plex-label titulo="Última actualización" subtitulo="Sin información"></plex-label>
            <plex-label titulo="Padrones disponibles" subtitulo="Sin información"></plex-label>
        </plex-detail>
    </plex-layout-sidebar>
</plex-layout>
