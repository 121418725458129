<div class="select-novedades">
    <plex-grid type="full" [cols]="2" class="selector" *ngIf="!ocultar">
        <plex-select name="selectModulo" [data]="modulos" [placeholder]="'Seleccione un módulo...'"
                     [(ngModel)]="selectModulo" labelField="nombre + ':' + descripcion" (ngModelChange)="filtrar();"
                     idField="_id">
        </plex-select>
        <div class="social">
            <span>También podés ver nuestras novedades en <a href="https://www.youtube.com/@andesaulavirtual6712"
                   target="_blank">
                    <plex-icon size="md" name="logo-youtube" class="youtube"></plex-icon>
                </a>
            </span>
        </div>
    </plex-grid>
</div>

<div *ngIf="selectModulo" class="titulo size-lg item-catalogo">
    <plex-title titulo="Novedades {{selectModulo.nombre}}">
    </plex-title>
    <plex-grid type="auto" size="md" [cols]="4" class="grid-novedades">
        <plex-card *ngFor="let novedad of filtroNovedades" class="card-novedad">
            <plex-badge size="md" color="{{novedad.modulo.color}}">{{novedad.fecha | date: 'dd/MM/yyyy'}}</plex-badge>
            <p>{{novedad.titulo}}</p>
            <plex-button label="" type="info" icon="eye" (click)="verDetalleNovedad(novedad)">
            </plex-button>
        </plex-card>
    </plex-grid>
</div>