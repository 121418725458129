<plex-title size="sm" titulo="SUSPENDER TURNO">
    <plex-button *ngIf="!suspendio" size="sm" type="success" icon="check" [disabled]="!motivoSuspensionSelect.select"
                 (click)="suspenderTurno()"></plex-button>
    <!--TODO: chequear que todos los mensajes a enviar tuvieron una respuesta-->
</plex-title>
<ng-container class="text-white" *ngIf="showSuspenderTurno">
    <div class="row">
        <div class="col-4">
            <label>Hora del Turno</label>
        </div>
        <div class="col-4">
            <label>Paciente</label>
        </div>
        <div class="col-4">
            <label>Enviar notificación</label>
        </div>
    </div>

    <div class="row" *ngFor="let turno of turnosSeleccionados; let i = index">
        <div class="col-4">
            {{turno.horaInicio | date: 'H:mm'}} hs
        </div>
        <div class="col-4">
            <span *ngIf="turno.paciente?.id">{{turno.paciente | nombre }} {{turno.estado}}</span>
            <span *ngIf="!turno.paciente?.id && turno.estado !== 'turnoDoble'">sin paciente asignado</span>
            <span *ngIf="turno.estado === 'turnoDoble'">turno doble</span>
        </div>
        <div class="col-4" (click)="seleccionarTurno(turno)">
            <span *ngIf="!turno.paciente?.id">-</span>
            <span *ngIf="!estaSeleccionado(turno) && turno.paciente?.id">
                <plex-icon name="checkbox-blank-outline"></plex-icon>
            </span>
            <span *ngIf="estaSeleccionado(turno) && turno.paciente?.id">
                <plex-icon name="checkbox-marked"></plex-icon>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <plex-select [(ngModel)]="motivoSuspensionSelect.select" name="motivoSuspension" [data]="motivoSuspension"
                         label="Motivo de Suspensión" placeholder="Seleccione un motivo de suspensión">
            </plex-select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="bg-warning p-3 text-white" *ngIf="!motivoSuspensionSelect.select?.nombre">
                Seleccione un motivo de suspensión
            </div>
        </div>
    </div>
</ng-container>