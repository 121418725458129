<plex-layout>
    <plex-layout-main>
        <plex-title titulo="Generar Usuario ">
            <plex-button *ngIf="estaValidado" type="danger" (click)="resetForm()">
                VOLVER</plex-button>
        </plex-title>
        <section justify="center">
            <div class="w-100" *mobile="false">
            </div>
            <form class="w-100" #formInscripcion="ngForm">
                <plex-wrapper *ngIf="!estaValidado">
                    <plex-text [(ngModel)]="profesional.nombre" name="nombre" label="Nombre" required="true"
                        grow="full">
                    </plex-text>
                    <plex-text [(ngModel)]="profesional.apellido" name="apellido" label="Apellido" required="true"
                        grow="full">
                    </plex-text>
                    <plex-int [(ngModel)]="profesional.documento" name="documento" label="Documento" required="true"
                        grow="full" [pattern]="patronDocumento">
                    </plex-int>
                    <plex-select [(ngModel)]="profesional.sexo" label="Seleccione sexo" [data]="sexos" name="sexo"
                        required="true" grow="full">
                    </plex-select>
                    <plex-datetime [(ngModel)]="profesional.fechaNacimiento" name="fechaNacimiento"
                        label="Fecha de Nacimiento" type="date" required="true" grow="full">
                    </plex-datetime>
                    <div class="mt-3" grow="full" *ngIf="captchaEnabled">
                        <re-captcha [(ngModel)]=" profesional.recaptcha" name="recaptcha" (resolved)="resolved($event)">
                        </re-captcha>
                    </div>
                    <plex-button class="mt-3" *ngIf="captchaEnabled" [disabled]="formInscripcion.invalid || !recaptcha"
                        type="success" (click)="getProfesional()" grow="full">
                        VALIDAR</plex-button>
                    <plex-button class="mt-3" *ngIf="!captchaEnabled" [disabled]="formInscripcion.invalid"
                        type="success" (click)="getProfesional()" grow="full">
                        VALIDAR</plex-button>
                </plex-wrapper>
            </form>
            <div class="w-100" *mobile="false">
            </div>
        </section>
        <section justify="center">
            <div class="w-100" *mobile="false">
            </div>
            <form class="w-100" #formValidado="ngForm">
                <plex-wrapper *ngIf="estaValidado">
                    <plex-text [(ngModel)]="profesional.nombreCompleto" name="nombreCompleto" label="Apellido y Nombre"
                        [readonly]="true" grow="full">
                    </plex-text>
                    <plex-text [(ngModel)]="profesional.documento" name="documento" label="Documento" [readonly]="true"
                        grow="full">
                    </plex-text>
                    <plex-text [(ngModel)]="profesional.fechaNacimiento" name="fechaNacimiento"
                        label="Fecha de nacimiento" [readonly]="true" grow="full"></plex-text>
                    <plex-label titulo="{{formacion.profesion}}" *ngFor="let formacion of profesional.matriculas"
                        subtitulo="matrícula {{ formacion.numeroMatricula }}" grow="full"></plex-label>
                    <plex-text email [(ngModel)]="profesional.email" name="email" label="Email"
                        [required]="!existeUsuario" grow="full">
                    </plex-text>
                    <plex-phone [(ngModel)]="profesional.telefono" name="telefono" label="Teléfono" grow="full"
                        [pattern]="patronContactoNumerico">
                    </plex-phone>
                    <plex-button class="mt-3" [disabled]="enProceso || formValidado.invalid" *ngIf="!existeUsuario"
                        type="success" (click)="createUser()" grow="full">
                        Nuevo Usuario
                    </plex-button>
                </plex-wrapper>
            </form>
            <div class="w-100" *mobile="false">
            </div>
        </section>
        <section justify="center">
            <div class="w-100" *mobile="false"></div>
            <div class="w-100" *mobile="false">
                <plex-label type="info" icon="informacion" size="lg"
                    titulo="Los datos deben ser ingresados tal cual figuran en el DNI."></plex-label>
            </div>
            <div class="w-100" *mobile="false"></div>
        </section>
    </plex-layout-main>
</plex-layout>