<plex-layout [main]="showReporteC2 && diagnostico ? 7 : 12">
    <plex-layout-main>

        <plex-title titulo="reportes">
            <plex-button [disabled]="formularioValidacion.invalid" type="success" label="Generar Reporte"
                         (click)="imprimir()" position="right" class="ml-2"></plex-button>
        </plex-title>
        <form #formularioValidacion="ngForm">
            <plex-grid size="lg" type="full">
                <plex-wrapper>
                    <plex-datetime type="date" [(ngModel)]="horaInicio" (change)="refreshSelection($event,'horaInicio')"
                                   name="horaInicio" label="Desde" class="fechas" [required]="true">
                    </plex-datetime>
                    <plex-datetime type="date" [(ngModel)]="horaFin" (change)="refreshSelection($event,'horaFin')"
                                   name="horaFin" label="Hasta" class="fechas" [required]="true">
                    </plex-datetime>
                    <plex-select label="Zona" [data]="zonaSanitaria$ | async"
                                 (change)="refreshSelection($event,'zonaSanitaria')" [(ngModel)]="zonaSanitaria"
                                 name="zona" [readonly]="totalOrganizaciones">
                    </plex-select>
                    <plex-select [data]="organizaciones$ | async" label="Organización"
                                 (change)="refreshSelection($event,'organizacion')" [(ngModel)]="organizacion"
                                 name="organizacion" [readonly]="totalOrganizaciones || zonaSanitaria">
                    </plex-select>
                    <plex-select *ngIf="tipoReportes?.nombre === 'Reporte C2'" label="Diagnostico"
                                 (change)="refreshSelection($event,'cie10')" [(ngModel)]="cie10" name="diagnostico"
                                 (getData)="codigoCIE10($event)">
                    </plex-select>
                    <plex-select [(ngModel)]="tipoReportes" name="tipoReportes" [data]="opciones"
                                 label-field="Tipo de reportes" label="Seleccione reporte" [required]="true"
                                 (change)="refreshSelection($event,'reporte')">
                    </plex-select>
                </plex-wrapper>
            </plex-grid>
        </form>
        <plex-title *ngIf="!inicio" titulo="Listado de reportes" size="sm"></plex-title>
        <div *ngIf="!inicio && estaVacio" justify="center" class="h-50">
            <plex-label titulo="No hay resultados para esta búsqueda" direction="column"
                        subtitulo="Edite algún filtro y genere nuevamente un reporte" type="warning" size="xl"
                        icon="close"></plex-label>
        </div>
        <div *ngIf="inicio" justify="center" class="h-50">
            <plex-label titulo="Comience completando los filtros" direction="column"
                        subtitulo="Elija las opciones de filtrado y luego haga click en el botón 'Generar Reporte'"
                        type="info" size="xl" icon="magnify"></plex-label>
        </div>
        <reporteC2 *ngIf="showReporteC2 && diagnosticos.length" [diagnosticos]="diagnosticos"
                   (select)="diagnostico = $event">
        </reporteC2>
        <cantidadConsultaXPrestacion *ngIf="showCantidadConsultaXPrestacion && diagnosticos.length"
                                     [diagnosticos]="diagnosticos">
        </cantidadConsultaXPrestacion>
    </plex-layout-main>
    <plex-layout-sidebar *ngIf="showReporteC2 && diagnostico" type="invert">
        <plex-title titulo="Listado de Pacientes {{diagnostico.reporteC2}}">
            <plex-button size="sm" type="danger" [icon]="'close'" (click)="diagnostico = null">
            </plex-button>
        </plex-title>
        <plex-list>
            <plex-item *ngFor="let paciente of diagnostico.pacientes;" class="">
                <plex-label titulo="{{ paciente | nombre }}"
                            subtitulo="{{ paciente | documento }}">
                </plex-label>
                <plex-label titulo="{{paciente.sexo}}">
                </plex-label>
                <plex-label titulo="{{paciente | edad}}">
                </plex-label>
            </plex-item>
        </plex-list>
    </plex-layout-sidebar>
</plex-layout>