<div *ngIf="showData">
    <plex-title titulo="Turnos suspendidos" size="md">
        <plex-badge [type]="estadosAgenda[agenda.estado]?.class" class="mr-1">{{
            estadosAgenda[agenda.estado]?.nombre }}
        </plex-badge>
        <plex-help class="mr-1" type="help" icon="information-variant" tooltip="Detalle de agenda"
                   titulo="Detalle de agenda">
            <div class="container mb-4">
                <div class="row">
                    <div class="col-12">
                        <label>Creada por</label> {{ agenda.createdBy | nombre }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label>Fecha</label> {{ agenda.horaInicio | date: 'EEE' | uppercase }} {{ agenda.horaInicio
                        | date:
                        'dd/MM/yyyy'}}, {{ agenda.horaInicio | date: 'HH:mm'}} a {{ agenda.horaFin | date:
                        'HH:mm'}} hs
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label>Tipos de prestación</label>
                        <div *ngFor="let tipoPrestacion of agenda.tipoPrestaciones">
                            {{tipoPrestacion.nombre}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label>Equipo de Salud</label>
                        <span *ngIf="agenda.profesionales?.length == 0" class="text-danger">Equipo de
                            Salud
                            no
                            asignado</span>
                        <ng-container *ngIf="agenda.profesionales">
                            <div *ngFor="let profesional of agenda.profesionales">
                                {{profesional | nombre}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label>Espacio físico</label>
                        <span *ngIf="agenda.espacioFisico?.nombre || agenda.otroEspacioFisico?.nombre">{{agenda |
                            espacioFisico}}</span>
                        <span *ngIf="!agenda.espacioFisico?.nombre && !agenda.otroEspacioFisico?.nombre"
                              class="text-danger">Espacio físico no asignado</span>
                    </div>
                </div>
                <div *ngIf="agenda.dinamica" class="row">
                    <div class="col-12">
                        <label>Cupos disponibles</label>
                        <span *ngIf="agenda.cupo > -1">{{agenda.cupo}}</span>
                        <span *ngIf="agenda.cupo == -1">Sin límite</span>
                    </div>
                </div>
                <div *ngIf="agenda.estado === 'suspendida'" class="row">
                    <div class="col-12">
                        <label>Motivo de suspensión</label>
                        <span *ngIf="agenda.motivoDeSuspension">{{agenda.motivoDeSuspension}}</span>
                    </div>
                </div>
            </div>
        </plex-help>
        <plex-help *ngIf="agenda.nota" class="mr-1" type="help" icon="message">
            {{ agenda.nota }}
        </plex-help>
        <plex-button type="default" icon="email-outline" size="sm" (click)="notificar()"></plex-button>
        <plex-button type="danger" tooltip="Cerrar" icon="close" (click)="cerrarSidebarTurnos()" class="ml-1" size="sm">
        </plex-button>
    </plex-title>
    <div class="py-2 d-flex align-items-center">
        <i *ngIf="todosSeleccionados" class="mdi mdi-checkbox-marked hover" (click)="seleccionarTodos()"
           title="Seleccionar todos" titlePosition="right"></i>

        <plex-icon *ngIf="!todosSeleccionados" name="checkbox-blank-outline" class="hover"
                   (click)="seleccionarTodos()"></plex-icon>
        Seleccionar todos
    </div>
    <small><i>*Agenda suspendida, no se dan turnos</i></small>
    <!--Turnos-->

    <ng-container *ngFor="let bloque of agenda.bloques | sortBloques let k = index">
        <div class="py-1 d-flex align-items-center" *ngIf="bloque.descripcion || bloque.citarPorBloque">
            <span *ngIf="!agenda.nominalizada && bloque.descripcion">{{bloque.descripcion}} de {{ bloque.horaInicio |
                date: 'HH:mm'}} a {{ bloque.horaFin | date: 'HH:mm'}}hs</span>
            <span *ngIf="agenda.nominalizada && bloque.descripcion">{{bloque.descripcion}}</span>
            <plex-badge *ngIf="bloque.citarPorBloque" type="info">Citación por segmento</plex-badge>
        </div>
        <plex-table>
            <tr *ngFor="let turno of bloque.turnos; let i=index" class="hover" (click)="seleccionarTurno(turno)"
                [ngClass]="{'text-white': estaSeleccionado(bloque.turnos[i])}">
                <!-- Checkbox seleccion -->
                <td>
                    <span
                          *ngIf="turno?.paciente?.id && turno?.estado === 'suspendido' && turno.paciente.telefono!=='' && !turno.reasignado?.siguiente">
                        <i *ngIf="estaSeleccionado(bloque.turnos[i])" class="mdi mdi-checkbox-marked"></i>
                        <i *ngIf="!estaSeleccionado(bloque.turnos[i])" class="mdi mdi-checkbox-blank-outline"></i>
                    </span>
                </td>
                <!-- Hora turno -->
                <td>
                    <strong *ngIf="turno">{{ turno.horaInicio | date: 'HH:mm' }}</strong>
                </td>
                <!-- Teléfono del paciente, si tiene -->
                <td>
                    <small *ngIf="turno?.paciente?.id" [ngClass]="{'text-danger' : turno?.estado === 'suspendido'}">{{
                        turno.paciente |
                        nombre }}
                    </small>
                    <small *ngIf="turno?.paciente?.id">
                        <span
                              *ngIf="turno.paciente.telefono && turno.paciente.telefono !== '' && turno.paciente.telefono.indexOf('-')===-1 ; else variosTelefono">
                            <br>
                            Teléfono: {{ turno.paciente.telefono }}
                        </span>
                        <ng-template #variosTelefono>
                            <span *ngIf="turno.paciente.telefono && turno.paciente.telefono !== ''; else noTelefono">
                                <br>
                                Teléfono: {{ turno.paciente.telefono.slice(0,turno.paciente.telefono.indexOf('-'))
                                }}
                            </span>
                        </ng-template>
                        <ng-template #noTelefono>
                            <br>
                            <span>Sin teléfono registrado</span>
                        </ng-template>
                    </small>
                    <!-- poner código de detalle. opcion 2-->
                </td>
                <td>
                    <div *ngIf="turno.paciente?.telefono && turno.avisoSuspension && !turno.reasignado?.siguiente">
                        <plex-badge *ngIf="turno.avisoSuspension === 'fallido'" type="danger"
                                    title="Notificación fallida">
                            <plex-icon name="message-alert"></plex-icon>
                        </plex-badge>
                        <plex-badge *ngIf="turno.avisoSuspension ==='enviado'" type="success"
                                    title="Notificación enviada">
                            <plex-icon name="message-text-outline"></plex-icon>
                        </plex-badge>
                        <plex-badge *ngIf="turno.avisoSuspension ==='no enviado'" type="info"
                                    title="Notificación pendiente">
                            <plex-icon name="message-processing"></plex-icon>
                        </plex-badge>
                    </div>
                    <plex-badge *ngIf="turno?.nota" size="sm" type="warning" tooltip="{{turno.nota}}">
                        <plex-icon name="message"></plex-icon>
                    </plex-badge>
                </td>
                <!-- Badge que indica si el turno está reasignado a otra agenda -->
                <td>
                    <plex-badge *ngIf="turno?.paciente?.id && turno?.estado === 'suspendido' && turno.reasignado?.siguiente"
                                type="info">Reasignado
                    </plex-badge>
                </td>
                <!-- Ver detalle del paciente -->
                <td>
                    <plex-help *ngIf="turno.paciente" type="info" icon="account-details" titulo="Detalle del paciente"
                               tooltip="Detalle del paciente" class="mr-3">
                        <div class="container mb-4">
                            <div class="row">
                                <div class="col-12">
                                    <label>Apellido</label> {{ turno.paciente.apellido }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Nombre</label> {{ turno.paciente.nombre }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Documento / Identificacion extranjera</label>
                                    {{ turno.paciente.documento }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Fecha de nacimiento</label> {{ turno.paciente.fechaNacimiento |
                                    date:'dd/MM/yyyy' }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Sexo / Genero</label> {{ turno.paciente.sexo }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Teléfono de contacto</label>
                                    {{ turno.paciente.telefono || 'Sin teléfono registrado' }}
                                    <br>
                                </div>
                            </div>
                            <div class="row" *ngIf="turno.paciente.email">
                                <div class=" col-12">
                                    <label>Email de contacto</label> {{ turno.paciente.email }}
                                </div>
                            </div>
                        </div>
                    </plex-help>
                </td>
            </tr>
        </plex-table>
        <plex-table>
            <plex-title size="sm" titulo="Sobreturnos Suspendidos"
                        *ngIf="agenda.sobreturnos && agenda.sobreturnos.length > 0">

            </plex-title>
            <tr *ngFor="let sobreturno of agenda.sobreturnos; let i=index" class="hover"
                (click)="seleccionarTurno(sobreturno)"
                [ngClass]="{'text-white': estaSeleccionado(agenda.sobreturnos[i])}">
                <!-- Checkbox seleccion -->
                <td>
                    <span
                          *ngIf="sobreturno?.paciente?.id && sobreturno?.estado === 'suspendido' && sobreturno.paciente.telefono!=='' && !sobreturno.reasignado?.siguiente">
                        <i *ngIf="estaSeleccionado(agenda.sobreturnos[i])" class="mdi mdi-checkbox-marked"></i>
                        <i *ngIf="!estaSeleccionado(agenda.sobreturnos[i])" class="mdi mdi-checkbox-blank-outline"></i>
                    </span>
                </td>
                <!-- Hora turno -->
                <td>
                    <strong *ngIf="sobreturno">{{ sobreturno.horaInicio | date: 'HH:mm' }}</strong>
                </td>
                <!-- Teléfono del paciente, si tiene -->
                <td>
                    <small *ngIf="sobreturno?.paciente?.id"
                           [ngClass]="{'text-danger' : sobreturno?.estado === 'suspendido'}">{{
                        sobreturno.paciente |
                        nombre }}
                    </small>
                    <small *ngIf="sobreturno?.paciente?.id">
                        <span
                              *ngIf="sobreturno.paciente.telefono && sobreturno.paciente.telefono !== '' && sobreturno.paciente.telefono.indexOf('-')===-1 ; else variosTelefono">
                            <br>
                            Teléfono: {{ sobreturno.paciente.telefono}}
                        </span>
                        <ng-template #variosTelefono>
                            <span
                                  *ngIf="sobreturno.paciente.telefono && sobreturno.paciente.telefono !== ''; else noTelefono">
                                <br>
                                Teléfono: {{
                                sobreturno.paciente.telefono.slice(0,sobreturno.paciente.telefono.indexOf('-'))}}
                            </span>
                        </ng-template>
                        <ng-template #noTelefono>
                            <br>
                            <span>Sin teléfono registrado</span>
                        </ng-template>
                    </small>
                </td>
                <td>
                    <ng-container
                                  *ngIf="sobreturno.paciente?.telefono && sobreturno.avisoSuspension && !sobreturno.reasignado?.siguiente">
                        <plex-badge *ngIf="sobreturno.avisoSuspension === 'fallido'" type="danger" title="SMS fallido">
                            <plex-icon name="message-alert"></plex-icon>
                        </plex-badge>
                        <plex-badge *ngIf="sobreturno.avisoSuspension ==='enviado'" type="success" title="SMS enviado">
                            <plex-icon name="message-text-outline"></plex-icon>
                        </plex-badge>
                        <plex-badge *ngIf="sobreturno.avisoSuspension ==='no enviado'" type="info"
                                    title="SMS pendiente">
                            <plex-icon name="message-processing"></plex-icon>
                        </plex-badge>
                    </ng-container>
                    <plex-badge *ngIf="sobreturno?.nota" size="sm" type="warning" tooltip="{{sobreturno.nota}}">
                        <plex-icon name="message"></plex-icon>
                    </plex-badge>
                </td>
                <!-- Badge que indica si el turno está reasignado a otra agenda -->
                <td>
                    <small *ngIf="sobreturno?.paciente?.id && sobreturno?.estado === 'suspendido' && sobreturno.reasignado?.siguiente"
                           class="badge-info float-right">Reasignado
                    </small>
                </td>
                <!-- Ver detalle del paciente -->
                <td>
                    <plex-help *ngIf="sobreturno.paciente" class="mr-1" type="help" icon="account-details"
                               titulo="Detalle del paciente" tooltip="Detalle del paciente" class="mr-3">

                        <div class="container mb-4">
                            <div class="row">
                                <div class="col-12">
                                    <label>Apellido</label> {{ sobreturno.paciente.apellido }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Nombre</label> {{ sobreturno.paciente.nombre }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Documento / Identificacion extranjera</label>
                                    {{ sobreturno.paciente.documento }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Fecha de nacimiento</label> {{ sobreturno.paciente.fechaNacimiento |
                                    date:'dd/MM/yyyy' }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Sexo / Genero</label> {{ sobreturno.paciente.sexo}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Teléfono de contacto</label>
                                    {{ sobreturno.paciente.telefono || 'Sin teléfono registrado' }}
                                </div>
                            </div>
                            <div class="row" *ngIf="sobreturno.paciente.email">
                                <div class=" col-12">
                                    <label>Email de contacto</label> {{ sobreturno.paciente.email }}
                                </div>
                            </div>
                        </div>
                    </plex-help>
                </td>
            </tr>
        </plex-table>
    </ng-container>
</div>


<ng-container *ngIf="showConfirmar">
    <form #formulario="ngForm">
        <plex-title titulo="Suspender Agenda" size="md"></plex-title>
        <plex-select [(ngModel)]="motivoSuspensionSelect.select" name="motivoSuspension" [data]="motivoSuspension"
                     label="Motivos de Suspensión" placeholder="Seleccione un motivo de suspensión" [required]="true">
        </plex-select>
        <div class="mt-3 float-right">
            <plex-button type="danger" label="Cancelar" (click)="cancelar()">
            </plex-button>
            <plex-button class="ml-1" [disabled]="!formulario.valid" type="success" label="Confirmar"
                         (click)="suspenderAgenda()">
            </plex-button>
        </div>
    </form>
</ng-container>