<section class="mt-2">

    <div *ngIf="tipoPaciente === 'extranjero'">
        <plex-title size="sm" titulo="Registro de paciente extranjero">
            <plex-bool *ngIf="!nuevoPaciente" [(ngModel)]="botonRegistroDNI" label="Registrar DNI argentino"
                       type="slide" (change)="registrarArgentino()" name="registroDNI"
                       [readonly]="disableRegistro || paciente.estado === 'validado'"></plex-bool>
        </plex-title>

        <form #formExtranjero="ngForm">
            <plex-wrapper>
                <plex-text label="Apellido" [(ngModel)]="pacienteExtranjero.apellido" name="apellido"
                           [disabled]="botonRegistroDNI" required="true">
                </plex-text>
                <plex-text label="Nombre" [(ngModel)]="pacienteExtranjero.nombre" name="nombre"
                           [disabled]="botonRegistroDNI" required="true">
                </plex-text>

                <plex-select [(ngModel)]="pacienteExtranjero.sexo" label="Seleccione sexo" [data]="sexos" name="sexo"
                             required="true" [disabled]="botonRegistroDNI" placeholder="Seleccione..."
                             (change)="completarGenero()">
                </plex-select>
            </plex-wrapper>
            <plex-wrapper>
                <plex-datetime label="Fecha de Nacimiento" [max]="hoy" type="date"
                               [(ngModel)]="pacienteExtranjero.fechaNacimiento" name="fechaNacimiento" required="true"
                               grow="3" [disabled]="botonRegistroDNI">
                </plex-datetime>

                <plex-select label="Tipo de identificación" [(ngModel)]="pacienteExtranjero.tipoIdentificacion"
                             [data]="tipoIdentificacion" name="tipoIdentificacion" placeholder="Seleccione..." grow="3"
                             [disabled]="botonRegistroDNI">
                </plex-select>
                <plex-text label="Número de identificación" [(ngModel)]="pacienteExtranjero.numeroIdentificacion"
                           name="numeroIdentificacion" grow="3" [disabled]="botonRegistroDNI">
                </plex-text>
            </plex-wrapper>
        </form>
    </div>

    <form #formBasico="ngForm">
        <ng-container [ngSwitch]="tipoPaciente">
            <div *ngSwitchCase="'bebe'">
                <plex-title size="sm" titulo="Registro de bebé"></plex-title>

                <plex-wrapper>
                    <plex-text label="Apellido" [(ngModel)]="paciente.apellido" name="apellidoBebe" [required]="true"
                               grow="4">
                    </plex-text>

                    <plex-text label="Nombre" [(ngModel)]="paciente.nombre" name="nombreBebe" [pattern]="nombrePattern"
                               [required]="true" grow="4">
                    </plex-text>

                    <plex-select [(ngModel)]="paciente.sexo" label="Seleccione sexo" [data]="sexos" name="sexoBebe"
                                 [required]="true" placeholder="Seleccione..." grow="4">
                    </plex-select>

                    <plex-datetime label="Fecha y hora de Nacimiento" [max]="hoy" type="datetime"
                                   [readonly]="paciente.estado === 'validado'" [(ngModel)]="paciente.fechaNacimiento"
                                   name="fechaNacimientoBebe" [required]="true" grow="4">
                    </plex-datetime>
                </plex-wrapper>


                <!-- DATOS DEL PROGENITOR PARA EL BEBE-->
                <span class="mt-4">
                    <plex-title size="sm" titulo="Datos de Progenitor/a o tutor/a"></plex-title>
                </span>

                <div class="search-container mt-2" *ngIf="showBuscador; else showRelacion">

                    <paciente-buscar label="Buscar" (searchStart)="onSearchStart()" (searchEnd)="onSearchEnd($event)"
                                     (searchClear)="onSearchClear()">
                    </paciente-buscar>

                    <paciente-listado *ngIf="busquedaTutor?.length" [pacientes]="busquedaTutor"
                                      (selected)="onPacienteSelected($event)" [showRelaciones]="false">
                    </paciente-listado>
                    <div *ngIf="!busquedaTutor?.length && !searchClear" class="alert alert-danger">
                        <plex-icon name="account-alert"></plex-icon> No se encontró ningún paciente..
                    </div>
                </div>

                <ng-template #showRelacion>
                    <plex-wrapper>
                        <plex-text *ngIf="relacionBebe.documento" label="Documento" [(ngModel)]="relacionBebe.documento"
                                   name="documentoRelacion" [readonly]="true">
                        </plex-text>

                        <plex-text *ngIf="relacionBebe.numeroIdentificacion" label="Número de identificación"
                                   [(ngModel)]="relacionBebe.numeroIdentificacion" name="numeroIdentificacionRelacion"
                                   [readonly]="true">
                        </plex-text>

                        <plex-text label="Apellido" [(ngModel)]="relacionBebe.apellido" name="apellidoRelacion"
                                   [readonly]="true">
                        </plex-text>

                        <plex-text label="Nombre" [(ngModel)]="relacionBebe.nombre" name="nombreRelacion"
                                   [readonly]="true">
                        </plex-text>
                    </plex-wrapper>

                    <plex-wrapper>
                        <plex-select [(ngModel)]="relacionBebe.sexo" label="Seleccione sexo" [data]="sexos"
                                     name="sexoRelacion" type="horizontal" [readonly]="true" grow="3">
                        </plex-select>

                        <plex-datetime label="Fecha de Nacimiento" [max]="hoy" type="date" [readonly]="true"
                                       [(ngModel)]="relacionBebe.fechaNacimiento" name="fechaNacimientoRelacion"
                                       grow="3">
                        </plex-datetime>

                        <plex-select [(ngModel)]="relacionBebe.relacion" name="tipoRelacion" label="Relación"
                                     [data]="parentescoModel" [required]="true" placeholder="Seleccione..." grow="3">
                        </plex-select>
                    </plex-wrapper>

                    <div class="row mt-4">
                        <div class="col-4">
                            <plex-button label="Cambiar" type="info" (click)="cambiarRelacion()">
                            </plex-button>
                        </div>
                    </div>
                </ng-template>
            </div>

            <div *ngSwitchCase="'extranjero'">
                <div *ngIf="botonRegistroDNI">
                    <plex-title size="sm" titulo="Registro de paciente argentino"></plex-title>

                    <plex-wrapper>
                        <plex-int class="mr-3" label="Número de DNI ARGENTINO" [(ngModel)]="paciente.documento"
                                  [readonly]="validado || noPoseeDNI" [pattern]="patronDocumento" name="documento"
                                  [required]="true" (change)="checkDisableValidar()" grow="3">
                        </plex-int>
                        <plex-select [(ngModel)]="paciente.sexo" label="Seleccione sexo" [data]="sexos" name="sexo"
                                     required="true" placeholder="Seleccione..." [readonly]="validado"
                                     (change)="completarGenero(); checkDisableValidar()" grow="3">
                        </plex-select>

                        <plex-datetime label="Fecha de Nacimiento" [max]="hoy" type="date" [readonly]="true"
                                       [(ngModel)]="paciente.fechaNacimiento" name="fechaNacimiento"
                                       [required]="!noPoseeDNI" grow="3">
                        </plex-datetime>
                    </plex-wrapper>
                    <plex-wrapper>
                        <plex-text label="Apellido" [(ngModel)]="paciente.apellido" name="apellido" required="true"
                                   [readonly]="true">
                        </plex-text>

                        <plex-text label="Nombre" [(ngModel)]="paciente.nombre" name="nombre" required="true"
                                   [readonly]="true">
                        </plex-text>

                        <plex-select [(ngModel)]="paciente.estadoCivil" name="estadoCivil" [data]="estadosCiviles"
                                     label="Seleccione estado Civil">
                        </plex-select>
                    </plex-wrapper>
                </div>
            </div>

            <div *ngSwitchDefault>
                <plex-title size="sm" titulo="Registro de paciente">
                    <plex-bool *ngIf="!validado" [(ngModel)]="noPoseeDNI" label="No posee DNI ARGENTINO" type="slide"
                               name="noPoseDNI" (change)="limpiarDocumento()"></plex-bool>
                </plex-title>
                <plex-wrapper>
                    <plex-int class="mr-3" label="Número de DNI ARGENTINO" [(ngModel)]="paciente.documento"
                              [readonly]=" validado || noPoseeDNI " [pattern]="patronDocumento" name="documento"
                              [required]="!noPoseeDNI" (change)="checkDisableValidar()" grow="3">
                    </plex-int>

                    <plex-select [(ngModel)]="paciente.sexo" label="Seleccione sexo" [data]="sexos" name="sexo"
                                 required="true" placeholder="Seleccione..." [readonly]="validado"
                                 (change)="completarGenero(); checkDisableValidar()" grow="3">
                    </plex-select>

                    <plex-datetime label="Fecha de Nacimiento" [max]="hoy" type="date" [readonly]="validado"
                                   [(ngModel)]="paciente.fechaNacimiento" name="fechaNacimiento"
                                   [required]="!noPoseeDNI" grow="3">
                    </plex-datetime>
                </plex-wrapper>

                <plex-wrapper>
                    <plex-text label="Apellido" [(ngModel)]="paciente.apellido" name="apellido" required="true"
                               [readonly]="validado">
                    </plex-text>

                    <plex-text label="Nombre" [(ngModel)]="paciente.nombre" name="nombre" required="true"
                               [readonly]="validado">
                    </plex-text>

                    <plex-select [(ngModel)]="paciente.estadoCivil" name="estadoCivil" [data]="estadosCiviles"
                                 label="Seleccione estado Civil">
                    </plex-select>
                </plex-wrapper>
            </div>
        </ng-container>


        <!-- IDENTIDAD DE GENERO -->
        <ng-container *ngIf="tipoPaciente !== 'bebe'">
            <plex-title titulo="identidad de género" size="sm" class="mt-2"></plex-title>
            <plex-wrapper>
                <plex-text label="Nombre" [(ngModel)]="paciente.alias" name="nombreAutopercibido"
                           [pattern]="nombrePattern"></plex-text>

                <plex-select label="Seleccione género" [(ngModel)]="paciente.genero" [data]="generos" name="genero">
                </plex-select>
            </plex-wrapper>
        </ng-container>

        <!-- Reporte de error en datos -->
        <ng-container *ngIf="validado">
            <plex-bool [(ngModel)]="paciente.reportarError" name="reportarError" type="slide"
                       label="Considero que los datos son incorrectos y quiero reportar el error">
            </plex-bool>
            <div class="mt-2" *ngIf="paciente.reportarError">
                <plex-text label="Descripción del error" [(ngModel)]="paciente.notaError" name="notaError"
                           [required]="paciente.reportarError ? 'true' : 'false'"> </plex-text>
            </div>
        </ng-container>
    </form>

</section>