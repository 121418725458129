<plex-title size="sm" titulo="Agregar Nota">
    <plex-button type="success" size="sm" icon="check" (click)="guardarNota()"></plex-button>
</plex-title>
<ng-container>
    <div class="row">
        <div class="col-4">
            <label>Hora del Turno</label>
        </div>
        <div class="col-8">
            <label>Paciente</label>
        </div>
    </div>
    <div class="row" *ngFor="let turno of turnosSeleccionados; let i = index">
        <div class="col-4">
            {{turno.horaInicio | date: 'H:mm'}} hs
        </div>
        <div class="col-8">
            <span *ngIf="turno.paciente && turno.paciente.id; else valueIfEmpty">
                {{turno.paciente | nombre }}</span>
            <ng-template #valueIfEmpty>Sin paciente asignado</ng-template>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <plex-text label="Observaciones" name="nota" [multiline]="true" [(ngModel)]="nota"
                (change)="verificarNota()">
            </plex-text>
        </div>
    </div>
</ng-container>