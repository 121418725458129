<plex-layout main="{{ mainSize }}">
    <plex-layout-main>
        <header>
            <plex-title titulo="Alta de pacientes">

                <plex-button label="Volver" type="danger" position="left" (click)="cancel()">
                </plex-button>
                <plex-button *ngIf="(tipoPaciente === '' || tipoPaciente === 'con-dni' || tipoPaciente === 'extranjero') && !showDeshacer"
                             label="Validar Paciente" type="info" position="right" [disabled]="disableValidar"
                             (click)="validarPaciente()">
                </plex-button>
                <plex-button *ngIf="showDeshacer" label="Deshacer Validación" type="warning" position="right"
                             (click)="deshacerValidacion()"></plex-button>
                <plex-button *ngIf="visualizarIgnorarGuardar" [disabled]="disableIgnorarGuardar"
                             label="Ignorar y Guardar" type="warning" position="right" [validateForm]="formulario"
                             (click)="save($event,true)">
                </plex-button>
                <plex-button [disabled]="disableGuardar || (registroDNI && !validado)" label="Guardar" type="success"
                             position="right" [validateForm]="formulario" (click)="save(false)">
                </plex-button>

            </plex-title>
        </header>
        <!-- loader -->
        <plex-loader *ngIf="loading" class="loading" type="ball-pulse"></plex-loader>
        <form #formulario="ngForm" [ngClass]="{'loadMode': loading}" class="h-100">
            <plex-tabs (change)="setMainSize($event)">
                <plex-tab label="datos básicos" icon="">
                    <datos-basicos #datosBasicos [paciente]="pacienteModel" [tipoPaciente]="tipoPaciente"
                                   (changes)="datosBasicosForm($event)" name="datos basicos">
                    </datos-basicos>
                </plex-tab>

                <plex-tab label="datos de contacto" icon="">
                    <datos-contacto #datosContacto [paciente]="pacienteModel" (mobileApp)="mobileNotification($event)">
                    </datos-contacto>
                </plex-tab>

                <plex-tab *ngIf="tipoPaciente !== 'bebe'" label="Relaciones" icon="account-multiple">
                    <!-- FAMILIARES: No se muestra para el caso del bebé -->
                    <relaciones-pacientes #relaciones [paciente]="pacienteModel"
                                          (actualizar)="actualizarRelaciones($event)">
                    </relaciones-pacientes>
                </plex-tab>

                <plex-tab label="Notas" icon="file-multiple">
                    <notas-paciente [notas]="pacienteModel.notas" (notasNew)="notasNotification($event)">
                    </notas-paciente>
                </plex-tab>

                <plex-tab label="Documentos" icon="file-multiple" *ngIf="documentacionPermiso">
                    <documentos-paciente [paciente]="pacienteModel"></documentos-paciente>
                </plex-tab>


            </plex-tabs>
        </form>
    </plex-layout-main>

    <plex-layout-sidebar [type]="'invert'">
        <div [ngClass]="{'loadMode': loading}">
            <!-- Pacientes candidatos -->
            <div *ngIf='(pacientesSimilares && pacientesSimilares.length); else showPanel'>
                <plex-title titulo="Pacientes Candidatos" size="md"></plex-title>
                <plex-list>
                    <plex-item label="Seleccionar" *ngFor="let elem of pacientesSimilares"
                               (click)="onSelect(elem.paciente)">

                        <img [ngClass]="{'img-fallecido' : elem.paciente.fechaFallecimiento}"
                             [mpiFotoPaciente]="elem.paciente" />

                        <plex-label *ngIf="!elem.paciente.numeroIdentificacion" titulo="{{ elem.paciente | nombre }}"
                                    subtitulo="{{ (elem.paciente.documento | number) || 'Sin DNI' }}">
                        </plex-label>

                        <plex-label *ngIf="elem.paciente.numeroIdentificacion" titulo="{{ elem.paciente | nombre }}"
                                    subtitulo="{{ elem.paciente.numeroIdentificacion }}"></plex-label>

                        <plex-label titulo="{{ elem.paciente | edad }}"
                                    subtitulo="{{ elem.paciente.fechaNacimiento | fecha:'utc' }}">
                        </plex-label>

                        <plex-label class="{{ width >= 1812 ? 'add-height' : null }}"
                                    titulo="Similitud: {{elem._score * 100}} %">
                        </plex-label>

                        <plex-badge *ngIf="elem.paciente.fechaFallecimiento" type="danger">Fallecido:
                            {{ elem.paciente.fechaFallecimiento | fecha:'utc'}}
                        </plex-badge>

                        <plex-badge type="info">{{ elem.paciente.genero }}
                        </plex-badge>

                        <plex-badge type="" class=""
                                    [ngClass]="{'text-success' : elem.paciente.estado === 'validado' , 'text-warning' : elem.paciente.estado === 'temporal'} ">
                            {{ elem.paciente.estado }}
                        </plex-badge>

                    </plex-item>
                </plex-list>
            </div>
            <ng-template #showPanel>
                <paciente-panel [paciente]="pacienteModel" [direction]="detailDirection"
                                [showRelaciones]="showRelaciones" (changeRelacion)="toRelacionesOnChange($event)">
                </paciente-panel>
            </ng-template>
        </div>
    </plex-layout-sidebar>

</plex-layout>