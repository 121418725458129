<plex-layout main="8">
    <plex-layout-main>
        <form #form="ngForm">
            <plex-title size="md" titulo="Agregar Sobreturno"></plex-title>
            <span *ngIf="showSobreturno && paciente">
                <div *ngIf="paciente?.id" class="row">
                    <div class="col">
                        <label>Paciente</label>
                        <div>{{paciente | nombre}}</div>
                    </div>
                    <div class="col" *ngIf="modelo.obraSocial !== 'SUMAR'">
                        <plex-radio [data]="obraSocialPaciente" [(ngModel)]="modelo.obraSocial" name="os"
                                    (change)="seleccionarObraSocial($event)" label="Seleccione una Obra Social">
                        </plex-radio>
                        <plex-select *ngIf="showListaPrepagas" [(ngModel)]="modelo.prepaga" name="prepagas"
                                     [data]="prepagas" label="Seleccione una Prepaga" idField="nombre"
                                     labelField="nombre">
                        </plex-select>
                    </div>
                    <div class="col" *ngIf="modelo.obraSocial === 'SUMAR'">
                        <label>Programa</label>
                        <div>{{modelo.obraSocial}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <plex-phone *ngIf="paciente" label="Verifique el número telefónico:" [(ngModel)]="telefono"
                                    (change)="cambioTelefono=true" name="telefono"></plex-phone>
                        <plex-text *ngIf="carpetaEfector" (change)="cambiarCarpeta()" label="Nro Carpeta"
                                   name="nroCarpeta" [(ngModel)]="carpetaEfector.nroCarpeta"></plex-text>
                    </div>
                    <div class="col">
                        <plex-text label="Observaciones" name="nota" multiline="true" [(ngModel)]="nota"
                                   (change)="verificarNota()"></plex-text>
                    </div>
                </div>
                <div class="row" *ngIf="agenda && agenda.tipoPrestaciones.length > 1">
                    <div class="col">
                        <plex-select [(ngModel)]="tipoPrestacion" name="tipoPrestacion" label="Tipo de prestación"
                                     [data]="agenda.tipoPrestaciones" placeholder="Seleccione el tipo de prestación"
                                     [required]="true">
                        </plex-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <plex-datetime type="time" [(ngModel)]="horaTurno" [min]="inicio" [max]="fin" [required]="true"
                                       name="horaTurno" label="Hora Turno">
                        </plex-datetime>
                    </div>
                    <div *ngIf="agenda.link" class="col">
                        <plex-text placeholder="Ingrese link turno virtual" [(ngModel)]="turno_link" [required]="true"
                                   name="turno_link" label="Link">
                        </plex-text>
                    </div>
                </div>
            </span>
        </form>
        <ng-container *ngIf="!showSobreturno && pacientesSearch">
            <paciente-buscar (searchStart)="onSearchStart()" (searchEnd)="onSearchEnd($event.pacientes,$event.scan)"
                             (searchClear)="onSearchClear()">
            </paciente-buscar>

            <plex-loader *ngIf="loading"></plex-loader>

            <paciente-listado *ngIf="pacientes && pacientes.length" [offset]="110" [pacientes]="pacientes"
                              (selected)="onSelect($event)"></paciente-listado>

            <div *ngIf="pacientes && !pacientes.length" class="alert alert-default">
                ¡No se encontró ningún paciente!
            </div>
        </ng-container>
    </plex-layout-main>
    <plex-layout-sidebar>
        <plex-title size="md" titulo="Agenda seleccionada">

        </plex-title>
        <!--Agenda seleccionada-->
        <div class="text-center mt-4" *ngIf="agenda">
            <h4 *ngIf="agenda.link" class="pt-4">
                <span class="mdi mdi-monitor"> Agenda Virtual</span>
            </h4>
            <br>
            <h4>
                <span class="mdi mdi-calendar-today"> Hora Agenda </span>
            </h4>
            <span>{{ agenda.horaInicio | date: 'EEE d MMM' }} | Inicio:
                {{ agenda.horaInicio | date: 'HH:mm' }} - Fin:
                {{ agenda.horaFin | date: 'HH:mm' }}
            </span>
            <br>
            <h4 class="pt-4">
                <span class="mdi mdi-hospital">Prestación </span>
            </h4>
            <div *ngFor="let tipoPrestacion of agenda.tipoPrestaciones">
                {{ tipoPrestacion.nombre }}
            </div>
            <br>
            <h4>
                <span class="mdi mdi-account">Equipo de Salud </span>
            </h4>
            <div *ngFor="let profesional of agenda.profesionales let i = index">
                {{profesional | nombre}}
            </div>

        </div>
    </plex-layout-sidebar>
    <plex-layout-footer>
        <plex-button position="left" type="danger" label="Cancelar" (click)="volver()">
        </plex-button>
        <plex-button position="right" *ngIf="paciente" [validateForm]="form" label="Guardar" type="success"
                     (click)="guardar($event)">
        </plex-button>
    </plex-layout-footer>
</plex-layout>