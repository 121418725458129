<fieldset>
    <plex-title titulo="Historial de Préstamos" size="lg"></plex-title>
    <!-- Filtros -->
    <plex-wrapper>
        <plex-text [(ngModel)]="numeroCarpeta" name="numeroCarpeta" label="Número de carpeta"
                    placeholder="Escriba el número de carpeta" labelField="numero" [required]="true">
        </plex-text>

        <plex-button label="Buscar" type="primary" (click)="buscarHistorial()" grow="2">
        </plex-button>
    </plex-wrapper>

    <plex-loader *ngIf="loading" type="ball-pulse"></plex-loader>

    <plex-title *ngIf="paciente" titulo="Paciente: {{ paciente | nombre }} |
            Documento: {{ paciente | documento }}" size="md"></plex-title>

    <table *ngIf="historial?.length" class="table table-striped">
        <thead>
            <tr>
                <th>Fecha</th>
                <th>Movimiento</th>
                <th>Ubicación</th>
                <th>Observaciones</th>
                <th>Observaciones de Prestamo Manual</th>
                <th>Estado de devolución</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let movimiento of historial">
                <td>
                    {{movimiento.createdAt | date:'dd-MM-yyyy'}}
                </td>
                <td *ngIf="!movimiento.datosSolicitudManual">
                    {{movimiento.estado == 'En archivo' ? 'Devuelta por:' : 'Prestada a:'}}
                    {{movimiento.datosPrestamo.turno.profesionales |
                    enumerar:['apellido','nombre'] }}
                    <br>
                    <plex-badge type="success">Solicitud Automática</plex-badge>
                </td>
                <td *ngIf="movimiento.datosSolicitudManual">
                    {{movimiento.estado == 'En archivo' ? 'Devuelta por:' : 'Prestada a:'}}
                    <span *ngIf="movimiento.datosSolicitudManual.profesional">
                        {{movimiento.datosSolicitudManual.profesional.apellido}},
                        {{movimiento.datosSolicitudManual.profesional.nombre}}
                    </span>
                    <span *ngIf="!movimiento.datosSolicitudManual.profesional">
                        {{movimiento.datosSolicitudManual.responsable.apellido}},
                        {{movimiento.datosSolicitudManual.responsable.nombre}}
                    </span>
                    <br>
                    <plex-badge type="warning">Solicitud Manual</plex-badge>
                </td>
                <td>
                    <span *ngIf="movimiento.datosSolicitudManual">
                        {{movimiento.datosSolicitudManual.espacioFisico?.nombre}}
                    </span>
                    <span *ngIf="!movimiento.datosSolicitudManual">
                        {{movimiento.datosPrestamo.turno.espacioFisico?.nombre}}
                        {{movimiento.datosPrestamo.turno.espacioFisico ? ' - ' : ''}}
                        {{movimiento.datosPrestamo.turno.espacioFisico?.servicio?.nombre}}
                    </span>
                </td>
                <td>
                    <span *ngIf="movimiento.estado === 'En archivo'">
                        {{movimiento.datosDevolucion?.observaciones}}
                    </span>
                    <span *ngIf="movimiento.estado === 'Prestada'">
                        {{movimiento.datosPrestamo?.observaciones}}
                    </span>
                </td>
                <td>
                    <span
                          *ngIf="movimiento.datosSolicitudManual">{{movimiento.datosSolicitudManual.observaciones}}</span>
                </td>
                <td>
                    <span *ngIf="movimiento.estado === 'En archivo'">
                        {{movimiento.datosDevolucion?.estado}}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="!loading && historial && !historial.length" justify="center" class="pt-4">
        <plex-label *ngIf="paciente" direction="column"
                    titulo="No hay movimientos de prestamos de la carpeta consultada" size="xl">
            <plex-icon prefix="mdi" name="folder-remove"></plex-icon>
        </plex-label>
        <plex-label *ngIf="!paciente" direction="column" titulo="No existe este número de carpeta en esta organización"
                    size="xl">
            <plex-icon prefix="mdi" name="folder-remove"></plex-icon>
        </plex-label>
    </div>
</fieldset>