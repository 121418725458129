<plex-title titulo="Ejecutar consultas">
    <plex-button *ngIf="!queries?.length" type="success" (click)="descargar()" [disabled]="form.invalid">
        Descargar CSV
    </plex-button>
    <ng-content></ng-content>
</plex-title>
<form #form="ngForm">
    <plex-grid type="full" size="sm">
        <plex-select class="bi-select" [(ngModel)]="consultaSeleccionada" name="select" [required]="true"
                     [data]="queries$ | async" labelField="nombre" idField="_id" placeholder="Seleccione consulta"
                     (change)="getArgumentos()">
        </plex-select>
        <ng-container *ngIf="!inProgress">
            <ng-container *ngFor="let argumento of argumentos">
                <plex-bool *ngIf="argumento.tipo === 'salida'" [(ngModel)]="argumento.check"
                           [label]="argumento.label?argumento.label:argumento.key" name={{argumento.key}}>
                </plex-bool>
            </ng-container>
        </ng-container>
    </plex-grid>
    <ng-container *ngIf="consultaSeleccionada">
        <span *ngIf='consultaSeleccionada.descripcion' [innerHtml]="descripcion"></span>
        <plex-title titulo="Argumentos"></plex-title>
        <plex-wrapper>
            <ng-container *ngFor="let argumento of argumentos" [ngSwitch]="argumento.tipo">
                <plex-datetime *ngSwitchCase="'date'" [label]="argumento.label ? argumento.label : argumento.key"
                               grow="auto" name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                               [required]="argumento.required">
                </plex-datetime>
                <ng-container *ngSwitchCase="'organizacion'">
                    <plex-select *ngIf="argumento.key === 'organizacionOrigen'"
                                 [label]="argumento.label ? argumento.label : argumento.key" grow="2"
                                 name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                                 [required]="argumento.required" [data]="organizacionesOrigenFiltradas">
                    </plex-select>
                    <plex-select *ngIf="argumento.key === 'organizacionDestino'"
                                 [label]="argumento.label ? argumento.label : argumento.key" grow="2"
                                 name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                                 [required]="argumento.required" [data]="organizacionesDestinoFiltradas">
                    </plex-select>
                </ng-container>
                <plex-select *ngSwitchCase="'unidadOrganizativa'"
                             [label]="argumento.label ? argumento.label : argumento.key" grow="full"
                             name="{{ argumento.key }}" labelField="term" [(ngModel)]="argumentos[argumento.key]"
                             [required]="argumento.required" (getData)="loadUnidadesOrganizativas($event)">
                </plex-select>
                <plex-select *ngSwitchCase="'conceptoTurneable'"
                             [label]="argumento.label ? argumento.label : argumento.key" grow="2"
                             name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]" idField="conceptId"
                             [required]="argumento.required" tmPrestaciones preload="true">
                </plex-select>
                <plex-text *ngSwitchCase="'string'" [label]="argumento.label ? argumento.label : argumento.key" grow="2"
                           name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                           [required]="argumento.required">
                </plex-text>
                <plex-select *ngSwitchCase="'profesional'" [label]="argumento.label ? argumento.label : argumento.key"
                             grow=" 2" name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                             [required]="argumento.required" labelField="nombreCompleto"
                             (getData)="loadProfesionales($event)">
                </plex-select>
                <plex-select *ngSwitchCase="'zonaSanitaria'" [label]="argumento.label ? argumento.label : argumento.key"
                             grow="2" name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                             [required]="argumento.required" [disabled]="disabledFiltroZonas" [data]="zonasSanitarias"
                             (change)="changeZona($event.value, argumento.key)">
                </plex-select>
                <plex-select *ngSwitchCase="'select-static'" [label]="argumento.label ? argumento.label : argumento.key"
                             grow="auto" name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                             [required]="argumento.required" [data]="argumento.items">
                </plex-select>
                <plex-select *ngSwitchCase="'formsEpidemiologia'"
                             [label]="argumento.label ? argumento.label : argumento.key" grow=" 2"
                             name="{{ argumento.key }}" [(ngModel)]="argumentos[argumento.key]"
                             [required]="argumento.required" labelField="type" (getData)="loadFomTypes($event)">
                </plex-select>
            </ng-container>
        </plex-wrapper>
    </ng-container>
</form>
<div justify="center" class="h-75">
    <plex-label *ngIf="!queries?.length" titulo="Comience seleccionando una consulta" direction="column"
                subtitulo="Complete los filtros requeridos y presione en el botón Descargar CSV para obtener su reporte "
                type="default" size="xl" icon="arrow-up">
    </plex-label>
</div>