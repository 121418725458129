<fieldset *ngIf="autorizado">
    <legend>REASIGNACIÓN | DISPONIBILIDAD DE TURNOS</legend>

    <h5>Reasignación automática</h5>

    <div class="row ">
        <div class="col-12 ">

            <div id="accordion " role="tablist " aria-multiselectable="true " class="accordion-reasignar ">
                <div *ngIf="agendasReasignar?.length> 0 && agendasReasignar[0].turno">
                    <div *ngFor="let agendaReasignar of agendasReasignar; let indiceTurno = index">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <plex-badge *ngIf="agendaReasignar.turno?.reasignado?.siguiente" type="info reasignado">
                                    <plex-icon name="check"></plex-icon>> Turno ya reasignado
                                </plex-badge>
                                <plex-badge *ngIf="agendaReasignar?.agendas?.length > 0 && !agendaReasignar.turno?.reasignado?.siguiente"
                                            type="warning reasignado">
                                    <plex-icon name="account-convert"></plex-icon> Turno sin reasignar
                                </plex-badge>
                                <plex-badge *ngIf="agendaReasignar?.agendas?.length === 0 && !agendaReasignar?.turno?.reasignado?.siguiente"
                                            type="danger reasignado">
                                    <plex-icon name="account-alert"></plex-icon> Turno sin agendas candidatas para
                                    reasignación
                                </plex-badge>

                                <br>
                                <span>
                                    <b>Paciente: </b> {{ agendaReasignar?.turno.paciente | nombre }} |
                                    <small *ngIf="agendaReasignar?.turno.paciente.documento !== ''">DNI
                                        {{ agendaReasignar?.turno.paciente.documento }}</small>
                                    <small *ngIf="agendaReasignar?.turno.paciente.documento === ''">Sin documento
                                        (temporal)</small>
                                    <br>
                                </span>
                                <span>
                                    <b>Prestación: </b> {{ agendaReasignar?.turno.tipoPrestacion.term }}
                                    <br>
                                </span>
                                <span>
                                    <b>Turno suspendido: </b>
                                    {{ agendaAReasignar?.horaInicio | date: 'EEE' | uppercase }} {{
                                    agendaAReasignar?.horaInicio | fecha }} a las {{ agendaReasignar?.turno.horaInicio
                                    | date: 'HH:mm' }} hs.
                                    <br>
                                </span>

                                <span *ngIf="agendaReasignar?.turno?.motivoSuspension">
                                    <b>Motivo: </b> {{ agendaReasignar?.turno.motivoSuspension }}
                                    <br>
                                </span>
                                <span *ngIf="paneles[indiceTurno] !== agendaReasignar.turno?._id && agendaReasignar.agendas && !agendaReasignar.turno?.reasignado?.siguiente && agendaReasignar?.agendas?.length > 0"
                                      class="hover text-info"
                                      (click)="togglePanel(agendaReasignar.turno._id, indiceTurno)">
                                    <i class="mdi mdi-arrow-down-drop-circle-outline"></i> Ver agendas candidatas</span>
                                <span *ngIf="paneles[indiceTurno] === agendaReasignar.turno?._id && agendaReasignar.agendas && !agendaReasignar.turno?.reasignado?.siguiente"
                                      class="hover text-info"
                                      (click)="togglePanel(agendaReasignar.turno._id, indiceTurno)">
                                    <i class="mdi mdi-arrow-up-drop-circle-outline"></i> Ocultar agendas
                                    candidatas</span>
                                <span *ngIf="paneles[indiceTurno] !== agendaReasignar.turno?._id && (!agendaReasignar.agendas || agendaReasignar.turno?.reasignado?.siguiente)"
                                      class="hover text-info"
                                      (click)="togglePanel(agendaReasignar.turno._id, indiceTurno)">
                                    <i class="mdi mdi-arrow-down-drop-circle-outline"></i> Ver detalles de la
                                    reasignación</span>
                                <span *ngIf="paneles[indiceTurno] === agendaReasignar.turno?._id && (!agendaReasignar.agendas || agendaReasignar.turno?.reasignado?.siguiente)"
                                      class="hover text-info"
                                      (click)="togglePanel(agendaReasignar.turno._id, indiceTurno)">
                                    <i class="mdi mdi-arrow-up-drop-circle-outline"></i> Ocultar detalles de la
                                    reasignación</span>
                                <plex-button *ngIf="agendaReasignar?.agendas?.length === 0 && !agendaReasignar?.turno?.reasignado?.siguiente"
                                             type="primary" label="Reasignación Manual de Turnos"
                                             (click)="reasignarTurnos()"></plex-button>
                            </div>

                            <!-- TURNOS A REASIGNAR -->
                            <div *ngIf="agendaReasignar?.turno && paneles[indiceTurno] === agendaReasignar.turno?._id && !agendaReasignar.turno?.reasignado?.siguiente"
                                 class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <table *ngIf="agendaReasignar?.agendas?.length > 0" class="table table-inverse">
                                    <tr *ngFor="let agenda of agendaReasignar.agendas; let i = index">
                                        <td class="col-10 bg-warning"
                                            *ngIf="(getFecha(hoy) === getFecha(agenda.horaInicio) && getHora(agendaReasignar.turno.horaInicio) > getHora(hoy)) || (getFecha(hoy) !== getFecha(agenda.horaInicio))">
                                            <div *ngIf="agendasOcultas[i] === agenda._id"
                                                 class="d-flex w-100 justify-content-between">
                                                <h5 class="mb-1">{{ agenda.horaInicio | date: 'EEE' | uppercase }} {{
                                                    agenda.horaInicio |
                                                    fecha }}
                                                    <i class="mdi mdi-clock"></i>
                                                    {{ agendaReasignar.turno.horaInicio | date:'HH:mm'}} a {{
                                                    agendaReasignar.turno.horaFin
                                                    | date:'HH:mm'}} hs.
                                                </h5>
                                            </div>
                                            <!-- REASIGNAR -->
                                            <a class="flex-column align-items-start hover"
                                               (click)="seleccionarCandidata(indiceTurno, i, agenda.horaInicio)"
                                               [hidden]="agendasOcultas[i] === agenda._id" title="Reasignar turno">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">{{ agenda.horaInicio | date: 'EEE' | uppercase }}
                                                        {{ agenda.horaInicio
                                                        | fecha }}
                                                        <i class="mdi mdi-clock"></i>
                                                        {{ agendaReasignar.turno.horaInicio | date:'HH:mm'}} a {{
                                                        agendaReasignar.turno.horaFin
                                                        | date:'HH:mm'}} hs.
                                                    </h5>
                                                    <small>Similitud: {{ agenda.similitud }}%</small>
                                                </div>
                                                <p class="mb-1">
                                                    Tipo Prestación: {{ agendaReasignar.turno.tipoPrestacion.nombre }}
                                                </p>
                                                <p class="mb-1" *ngIf="agenda.profesionales?.length > 0">
                                                    Equipo de Salud:
                                                    {{ agenda.profesionales | enumerar:['apellido', 'nombre'] }}
                                                </p>
                                            </a>
                                        </td>
                                        <td class="col-2"
                                            *ngIf="(getFecha(hoy) === getFecha(agenda.horaInicio) && getHora(agendaReasignar.turno.horaInicio) > getHora(hoy)) || (getFecha(hoy) !== getFecha(agenda.horaInicio)) ">
                                            <button *ngIf="agendaReasignar?.agendas?.length > 0"
                                                    class="btn btn-sm btn-danger hover"
                                                    (click)="ocultarAgendaCandidata(agenda._id, i)">
                                                <i *ngIf="agendasOcultas[i] === agenda._id" class="mdi mdi-eye"></i>
                                                <i *ngIf="agendasOcultas[i] !== agenda._id" class="mdi mdi-eye-off"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <!-- TURNOS YA REASIGNADOS -->
                            <div class="container"
                                 *ngIf="agendaReasignar?.turno?.reasignado?.siguiente && paneles[indiceTurno] === agendaReasignar.turno?._id">
                                <div class="row">
                                    <div class="col-12 bg-success text-white card-block">
                                        Reasignado al Turno:
                                        <b>{{ agendaReasignar.agendas[0]?.horaInicio | date: 'EEE' | uppercase }} {{
                                            agendaReasignar.agendas[0]?.horaInicio
                                            | fecha}}, {{ agendaReasignar.turno?.horaInicio | date: 'HH:mm'}}
                                            hs</b>
                                        <div>Prestación:
                                            <b>{{agendaReasignar?.turno?.tipoPrestacion?.nombre}}</b>
                                        </div>
                                        <div *ngIf="agendaReasignar.agendas[0].profesional">Equipo de Salud:
                                            <b>{{profesional | nombre}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</fieldset>
<fieldset *ngIf="agendasReasignar?.length === 0">
    No hay alternativas para reasignación de turnos
</fieldset>
<permisos *ngIf="!autorizado" [permisos]="permisosRequeridos" [botonCancelar]="false" (cancelarEmit)="cancelar()">
</permisos>