<form #form="ngForm">
    <fieldset>
        <plex-title titulo="Contacto / Dirección actual" size="sm"></plex-title>

        <!-- DATOS CONTACTO: tel fijo / tel celular-->
        <plex-wrapper *ngFor="let _unContacto of contactosTelefonicos; let i = index">
            <plex-select [readonly]="soloLectura" [(ngModel)]="_unContacto.tipo" [data]="tipoComunicacion"
                         name="tipo-{{i}}" [required]="true" label="Tipo"
                         (change)="changeTipoContacto(i, _unContacto.tipo)">
            </plex-select>
            <plex-phone [readonly]="soloLectura" label="Número" [(ngModel)]="_unContacto.valor"
                        placeholder="Ej: 2990000000" name="valor-{{i}}" (keyup)="disableGuardar = false"
                        [pattern]="patronContactoNumerico" required="true">
            </plex-phone>
            <ng-container *ngIf="i > 0">
                <plex-button class="pr-3 pb-1" label="" type="danger btn-sm" icon="close" (click)="removeContacto(i)">
                </plex-button>
            </ng-container>
        </plex-wrapper>
        <plex-button title="Agregar contacto" titlePosition="left" position="left" type="primary btn-sm"
                     icon="phone-plus" (click)="addContacto('celular', '')" *ngIf="!soloLectura">
        </plex-button>


        <!-- DATOS DOMICILIO -->
        <plex-wrapper>
            <plex-text class="pr-3" [readonly]="soloLectura" label="Dirección actual" [(ngModel)]="direccion.valor"
                       name="divValor" placeholder="Ej: Avenida Las Flores 1200" (keyup)="disableGuardar = false"
                       grow="full">
            </plex-text>
            <plex-select label="Provincia" [readonly]="soloLectura" [(ngModel)]="direccion.ubicacion.provincia"
                         name="provincia" [data]="provincias" labelField="nombre" required="true"
                         (change)="disableGuardar = false; changeProvincia(direccion.ubicacion.provincia)">
            </plex-select>
            <plex-select *ngIf="localidades.length" [readonly]="soloLectura" label="Localidad"
                         [(ngModel)]="direccion.ubicacion.localidad" name="localidad" [data]="localidades"
                         labelField="nombre" [required]="localidadRequerida"
                         (change)="disableGuardar = false; loadBarrios(direccion.ubicacion.localidad)">
            </plex-select>
        </plex-wrapper>

        <plex-button *ngIf="!soloLectura" class="float-right pr-3" title="guardar" [disabled]="disableGuardar"
                     validateForm="!disableGuardar" type="success" label="Guardar" (click)="save($event)"></plex-button>
    </fieldset>
</form>