<plex-title titulo="Recientes {{fecha ? '- ' + fechaConFormato : ''}}">
    <plex-button *ngIf="fecha" type="danger" size="sm" (click)="volverInicio()">Volver</plex-button>
</plex-title>

<div *ngIf="!fecha">
    <filtro-novedades *ngIf="novedades.length" [novedades]="novedades" (verDetalle)="verDetalle($event)" [modulos]="modulos" [ocultar]="novedad"
                      (filtrarPorModulo)="filtrar($event)">
    </filtro-novedades>
</div>

<ng-container>
    <div *ngIf="!novedades.length" justify="center" class="h-50">
        <plex-label icon="magnify" type="info" size="xl" direction="column" [titulo]="'No hay resultados'"
                    subtitulo="Elija otra fecha para buscar mas novedades">
        </plex-label>
    </div>
</ng-container>

<div *ngIf="!filtroModulo && !fecha">
    <div *ngFor="let modulo of modulos" class="itemCatalogo">
        <plex-title titulo="{{modulo.nombre}}"></plex-title>
        <plex-slider size="xl" class="slider">
            <plex-card *ngFor="let novedad of catalogo[modulo._id]" class="cardNovedad">
                <plex-badge size="md" color="{{modulo.color}}">{{novedad.fecha | date: 'dd/MM/yyyy'}}</plex-badge>
                <p>{{novedad.titulo}}</p>
                <plex-button label="" type="info" icon="eye" (click)="verDetalle(novedad)">
                </plex-button>
            </plex-card>
        </plex-slider>
    </div>
</div>

<div *ngIf="!filtroModulo && fecha">
    <div *ngFor="let modulo of modulos" class="itemCatalogo">
        <plex-title titulo="{{modulo.nombre}}"></plex-title>
        <plex-grid type="full" cols="4">
            <plex-card *ngFor="let novedad of catalogo[modulo._id]" class="cardNovedad">
                <plex-badge size="md" color="{{modulo.color}}">{{modulo.nombre}}</plex-badge>
                <p>{{novedad.titulo}}</p>
                <plex-button label="" type="info" icon="eye" (click)="verDetalle(novedad)">
                </plex-button>
            </plex-card>
        </plex-grid>
    </div>
</div>