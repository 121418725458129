
    <div class="col-sm-12" *ngIf="!showcreate && !showupdate">
        <plex-box title="Especialidades">
            <plex-scroll (on-scroll)="nextPage()" distancia="3"></plex-scroll>
            <form [formGroup]="searchForm">
                <div class="row">
                    <div class="col-md-6">
                        <plex-text formControlName="codigoSisa" label="Codigo Sisa" placeholder="Ingresar código...">
                        </plex-text>
                    </div>
                    <div class="col-md-6">
                        <plex-text formControlName="nombre" label="Nombre" placeholder="Ingrese nombre...">
                        </plex-text>
                    </div>
                </div>
            </form>
        </plex-box>
        <plex-box>
            <div class="row">
                <div class="col-sm-12">
                    <plex-button type="primary" label="Nueva Especialidad" title="Nueva Especialidad" (click)="showcreate = !showcreate">
                    </plex-button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Codigo Sisa</th>
                            <th>Nombre</th>
                            <th>Disciplina</th>
                            <th>Habilitado</th>
                            <th>Actualizar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let especialidad of datos">
                            <td>{{especialidad.codigo? especialidad.codigo.sisa: ''}}</td>
                            <td>{{especialidad.nombre}}</td>
                            <td>{{especialidad.disciplina}}</td>
                            <td>
                                <plex-bool type="slide" [(ngModel)]="especialidad.activo" label="" name="especialidad.activo" (click)="activate(especialidad)"></plex-bool>
                            </td>
                            <td>
                                <plex-button type="primary" label="Actualizar" (click)="onEdit(especialidad)">
                                </plex-button>
                            </td>
                            <!-- <td>
                                <plex-button icon="delete" type="danger" label="Borrar" *ngIf="especialidad.habilitado" (click)="onDisable(especialidad)"></plex-button>
                                <plex-button icon="check" type="success" label="Activar" *ngIf="!especialidad.habilitado" (click)="onEnable(especialidad)">
                                </plex-button>
                            </td>-->
                        </tr>
                    </tbody>
                </table>
            </div>
            <plex-loader *ngIf="loader"></plex-loader>
            <div *ngIf="finScroll" class="alert alert-warning">
                <p style="text-align: center;"> No se encontraron más resultados </p>
<!-- pasar a un componente de plex -->
</div>
</plex-box>
</div>
<div class="col-sm-12" *ngIf="showcreate">
    <div class="clear"></div>
    <especialidad-create-update (data)='onReturn($event)'></especialidad-create-update>
</div>
<div class="col-sm-12" *ngIf="showupdate">
    <div class="clear"></div>
    <especialidad-create-update [seleccion]="seleccion" (data)='onReturn($event)'></especialidad-create-update>
</div>
