import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-visualizacion-informacion',
    templateUrl: './visualizacion-informacion.component.html',
    styleUrls: ['visualizacion-informacion.scss']
})
export class VisualizacionInformacionComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}
