<div class="novedades-home">
    <plex-list>
        <plex-item *ngFor="let novedad of novedades$; let indiceNovedad = index"
            (click)="onSelectedNovedadChange(novedad)">
            <img *ngIf="novedad.imagenes.length > 0" [src]="getFoto(novedad)" alt="">
            <!-- Si no hay imagen, mostrar logo de Andes por default -->
            <div ngProjectAs="img" *ngIf="novedad.imagenes.length === 0" class="svg-logo item-default-img mr-3" svgLogo>
            </div>
            <plex-label titulo="{{novedad.titulo}}" subtitulo="">
            </plex-label>
            <plex-badge size="md" type="info">{{novedad.fecha | date: 'dd/MM/yyyy'}}</plex-badge>
            <plex-badge size="md" type="success"> {{novedad.modulo? novedad.modulo.nombre : 'general'}} </plex-badge>
        </plex-item>
    </plex-list>
</div>
