<plex-layout main="{{ showSidebar ? 8 : 12 }}">
    <plex-layout-main>
        <form #formulario="ngForm" [ngClass]="{'loadMode': loading}">

            <plex-tabs *ngIf="!imprimirSolicitudes" (change)="onTabsChange()">
                <plex-tab label="Solicitudes de Carpetas">
                    <app-listar-solicitudes (recargarPrestamosEmit)="prestamos.getCarpetas({}, null)"
                                            (prestarCarpetaEmitter)="onPrestarCarpeta($event)"
                                            (mostrarSolicitudManualEventEmitter)="mostrarSolicitudManual()"
                                            (imprimirSolicitudesEmit)="imprimirCarpetas($event)"
                                            (devolverCarpetaEmitter)="onDevolver($event)" #solicitudes>
                    </app-listar-solicitudes>
                </plex-tab>
                <plex-tab label="Prestamos de Carpetas">
                    <app-listar-prestamos (recargarSolicitudesEmit)="solicitudes.getCarpetas({}, null)"
                                        (devolverCarpetaEmit)="onDevolverCarpeta($event)" #prestamos>
                    </app-listar-prestamos>
                </plex-tab>
                <plex-tab label="Historial de Prestamos">
                    <app-historial-carpetas></app-historial-carpetas>
                </plex-tab>
            </plex-tabs>
            <imprimir-solicitudes [solicutudes]="carpetas" *ngIf="imprimirSolicitudes"
                                (volverAlListadoEmit)="cancelarImprimir()"></imprimir-solicitudes>
        </form>
    </plex-layout-main>

    <plex-layout-sidebar *ngIf="showSidebar" type="invert">

        <!-- ----------- Búsqueda de pacientes -------------- -->
        <ng-container *ngIf="pacientesSearch">
            <plex-title titulo="Buscar Paciente" size="md">
                <plex-button icon="close" type="danger" size="sm" (click)="hideSidebar()"></plex-button>
            </plex-title>

            <paciente-buscar (searchStart)="searchStart()" (searchEnd)="searchEnd($event)"
                            (searchClear)="onSearchClear()">
            </paciente-buscar>

            <plex-loader *ngIf="loading" class="loading" type="ball-pulse"></plex-loader>

            <paciente-listado *ngIf="resultadoBusqueda && resultadoBusqueda.length" [pacientes]="resultadoBusqueda"
                            (selected)="onSelectPaciente($event)" [offset]="110">
            </paciente-listado>
            <div *ngIf="!searchClear && resultadoBusqueda && !resultadoBusqueda.length" class="alert alert-danger">
                <plex-icon name="account-alert"></plex-icon> No se encontró ningún paciente..
            </div>
        </ng-container>

        <app-prestar-hc *ngIf="verPrestar" [prestar]="carpetaSeleccionada" (cancelPrestarEmit)="hideSidebar($event)"
                        (carpetaPrestadaEmit)="onCarpetaPrestada()"></app-prestar-hc>

        <solicitud-manual-hc type="invert" *ngIf="verSolicitudManual" [pacienteSeleccionado]="paciente"
                            (crearNuevaCarpetaEmmiter)="crearNuevaCarpeta()"
                            (cancelSolicitudManualEmit)="hideSidebar($event)"
                            (nuevaCarpetaManualEmit)="onCarpetaPrestada($event)">
        </solicitud-manual-hc>

        <app-devolver-hc *ngIf="verDevolver" [devolver]="carpetaSeleccionada" (cancelDevolverEmit)="hideSidebar()"
                        (carpetaDevueltaEmit)="onCarpetaDevuelta()">
        </app-devolver-hc>

        <fieldset *ngIf="verNuevaCarpeta">
            <plex-title titulo="{{paciente.apellido}}, {{ paciente.alias || paciente.nombre }}" size="md">
                <plex-button icon="close" type="danger" size="sm" (click)="hideSidebar()"></plex-button>
            </plex-title>
            <plex-loader *ngIf="loading" class="loading" type="ball-pulse"></plex-loader>
            <carpeta-paciente [turnoSeleccionado]="null" [pacienteSeleccionado]="paciente"
                            (guardarCarpetaEmit)="afterComponenteCarpeta($event)"
                            (cancelarCarpetaEmit)="afterComponenteCarpeta($event) ">
            </carpeta-paciente>
        </fieldset>

    </plex-layout-sidebar>
</plex-layout>