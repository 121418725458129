<fieldset>
    <plex-title titulo="Préstamos de Carpetas" size="lg">
        <plex-button *ngIf="carpetasSeleccionadas.length > 0" type='success' icon="clipboard-arrow-left"
                     title="Devolver todas" (click)="devolverCarpetas()">
        </plex-button>
    </plex-title>

    <!-- Filtros -->
    <plex-wrapper>
        <plex-datetime type="date" [(ngModel)]="fechaDesde" (change)="getCarpetas($event, 'fechaDesde')"
                       name="fechaDesde" label="Desde" class="fechas" grow="4">
        </plex-datetime>

        <plex-datetime type="date" [(ngModel)]="fechaHasta" (change)="getCarpetas($event, 'fechaHasta')"
                       name="fechaHasta" label="Hasta" class="fechas" grow="4">
        </plex-datetime>

        <div grow="2">
            <label>&nbsp;</label>
            <plex-bool class="pl-4 float-right" [(ngModel)]="sortDescending" label="Order Nro. Carpeta Descendente"
                       (change)="sortCarpetas()">
            </plex-bool>
        </div>
        <div grow="1">
            <label>&nbsp;</label>
            <plex-button class="pt-2 float-right" type="primary"
                         [icon]="mostrarMasOpciones ? 'chevron-up' : 'chevron-down'"
                         (click)="mostrarMasOpciones = !mostrarMasOpciones"></plex-button>
        </div>
    </plex-wrapper>

    <plex-wrapper *ngIf="mostrarMasOpciones">
        <plex-select [(ngModel)]="tipoPrestacion" (change)="getCarpetas($event, 'prestaciones')" tmPrestaciones
                     preload="true" name="prestaciones" label="Prestación" placeholder="Buscar una prestación">
        </plex-select>

        <plex-select [(ngModel)]="espacioFisico" (change)="getCarpetas($event,'espacioFisico')" name="espacioFisico"
                     (getData)="loadEspacios($event)" label="Espacio Físico" placeholder="Buscar un espacio físico"
                     labelField="nombre + '(' + servicio.nombre + ')'" ngModelOptions="{standalone: true}">
        </plex-select>
        <plex-select [(ngModel)]="profesional" name="profesional" (change)="getCarpetas($event, 'profesionales')"
                     (getData)="loadProfesionales($event)" label="Profesional"
                     placeholder="Escriba el apellido del Profesional" labelField="apellido + ' ' + nombre">
        </plex-select>
    </plex-wrapper>

    <plex-loader *ngIf="loading" type="ball-pulse"></plex-loader>

    <table *ngIf="carpetas.length" class="table table-striped table-sm">
        <thead>
            <tr>
                <th (click)="switchMarcarTodas()">
                    <i *ngIf="marcarTodas" class="mdi mdi-checkbox-marked"></i>
                    <i *ngIf="!marcarTodas" class="mdi mdi-checkbox-blank-outline"></i>
                </th>
                <th>Prestamo</th>
                <th>Carpeta</th>
                <th>Paciente</th>
                <th>Ubicación</th>
                <th>Prestación</th>
                <th>Responsables</th>
                <th>Observaciones</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let carpeta of carpetas" [ngClass]="{'bg-inverse text-white': estaSeleccionada(carpeta)}">
                <td (click)="switchSeleccionCarpeta(carpeta)">
                    <i *ngIf="estaSeleccionada(carpeta)" class="mdi mdi-checkbox-marked"></i>
                    <i *ngIf="!(estaSeleccionada(carpeta))" class="mdi mdi-checkbox-blank-outline"></i>
                </td>
                <td>
                    {{carpeta.fecha | date:'dd-MM-yyyy'}}
                </td>
                <td>{{carpeta._id}}</td>
                <td>
                    <b>
                        {{carpeta.paciente | nombre }}
                    </b>
                    <br> Documento:
                    <b>{{carpeta.paciente | documento }}</b>
                </td>
                <td *ngIf='!carpeta.datosSolicitudManual'>
                    {{carpeta.datosPrestamo.turno.espacioFisico?.nombre}}
                    {{carpeta.datosPrestamo.turno.espacioFisico ? ' - ' : ''}}
                    {{carpeta.datosPrestamo.turno.espacioFisico?.servicio?.nombre}}
                </td>
                <td *ngIf='carpeta.datosSolicitudManual'>
                    {{carpeta.datosSolicitudManual.espacioFisico?.nombre}}
                    {{carpeta.datosSolicitudManual.espacioFisico ? ' - ' : ''}}
                    {{carpeta.datosSolicitudManual.espacioFisico?.servicio?.nombre}}
                </td>
                <td *ngIf='!carpeta.datosSolicitudManual'>
                    {{carpeta.datosPrestamo.turno.tipoPrestacion?.term}}
                </td>
                <td *ngIf='carpeta.datosSolicitudManual'>
                    {{carpeta.datosSolicitudManual.prestacion?.term}}
                </td>
                <td *ngIf='!carpeta.datosSolicitudManual'>
                    {{carpeta.datosPrestamo.turno.profesionales | enumerar:['apellido','nombre'] }}
                </td>
                <td *ngIf='carpeta.datosSolicitudManual'>
                    {{carpeta.datosSolicitudManual.profesional ?
                    carpeta.datosSolicitudManual.profesional.nombre + ', ' +
                    carpeta.datosSolicitudManual.profesional.apellido
                    : ''}}
                </td>
                <td *ngIf='!carpeta.datosSolicitudManual'>
                    {{carpeta.datosPrestamo.observaciones}}
                </td>
                <td *ngIf='carpeta.datosSolicitudManual'>
                    {{carpeta.datosSolicitudManual.observaciones}}
                </td>
                <td *ngIf="carpeta.estado == 'Prestada'">
                    <button class="btn btn-primary" label="Devolver" (click)="devolver(carpeta)"
                            [disabled]="carpetasSeleccionadas.length > 0">Devolver
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="!loading && !carpetas.length" justify="center" class="pt-4">
        <plex-label direction="column" titulo="No hay prestamos para los filtros consultados"
                    subtitulo="Comience realizando una búsqueda mediante los filtros en la parte superior de la pantalla"
                    size="xl">
            <plex-icon prefix="mdi" name="folder-remove"></plex-icon>
        </plex-label>
    </div>
</fieldset>