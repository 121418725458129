<plex-layout [main]='6'>
    <plex-layout-main>
        <plex-title *ngIf="!pacienteSelected" titulo="búsqueda de paciente"></plex-title>
        <div class="row" *ngIf="!pacienteSelected">
            <div class="col-12">
                <paciente-buscar (searchStart)="searchStart()" (searchEnd)="searchEnd($event)"
                                 (searchClear)="onSearchClear()">
                </paciente-buscar>
            </div>
        </div>
        <div class=" row">
            <div class="col-12">
                <paciente-listado *ngIf="resultadoBusqueda && resultadoBusqueda.length && !pacienteSelected"
                                  [pacientes]="resultadoBusqueda" (selected)="onSelect($event)">
                </paciente-listado>
            </div>
        </div>
        <div *ngIf="showLabel" justify="center" class="h-75">
            <plex-label icon="arrow-up" type="default" size="xl" direction="column"
                        titulo='Comience buscando y seleccionando un paciente en la barra superior'>
            </plex-label>
        </div>
        <div *ngIf="modalAccepted">
            <plex-title titulo="Paciente">
                <plex-button type="link" icon="flecha-izquierda" (click)="onSearchClear()" tooltip="Volver">
                </plex-button>
            </plex-title>
            <paciente-detalle orientacion="horizontal" *ngIf="pacienteSelected" [paciente]="pacienteSelected"
                              reload="true">
            </paciente-detalle>
            <form #form="ngForm">
                <plex-title titulo="Filtrar solicitud de exportación">
                    <plex-button size="sm" type="success" (click)="exportar()"
                                 [disabled]="(form.invalid || !modalAccepted) && !hudsCompleta">
                        Generar
                    </plex-button>
                </plex-title>
                <plex-wrapper>
                    <div class="d-flex align-items-end" grow="full">
                        <plex-datetime grow="auto" label="Desde" name="fechaDesde" [(ngModel)]="fechaDesde"
                                       [disabled]="hudsCompleta" required>
                        </plex-datetime>
                        <plex-datetime grow="auto" label="Hasta" name="fechaHasta" [(ngModel)]="fechaHasta"
                                       [disabled]="hudsCompleta" required>
                        </plex-datetime>
                        <plex-bool grow="auto" name="hudsCompleta" [(ngModel)]="hudsCompleta" label="HUDS Completa"
                                   type="slide" (change)="cambiarHudsCompleta()">
                        </plex-bool>
                    </div>
                    <div class="d-flex mt-3 {{ hudsCompleta ? 'disabled': '' }}">
                        <plex-bool type="checkbox" name="alergia" [(ngModel)]="excluirVacunas" label="Excluir vacunas">
                        </plex-bool>
                        <plex-bool type="checkbox" name="alergia" [(ngModel)]="excluirLaboratorio" label="Excluir Laboratorios">
                        </plex-bool>
                    </div>
                    <plex-select grow="full" label="Prestaciones" name="prestacion" idField="conceptId"
                                 [(ngModel)]="prestacion" tmPrestaciones [preload]="true">
                    </plex-select>
                </plex-wrapper>
            </form>
        </div>
    </plex-layout-main>
    <plex-layout-sidebar [type]="'invert'">
        <descargas-pendientes></descargas-pendientes>
    </plex-layout-sidebar>
</plex-layout>