<!-- Moléculas -->
<ng-container *ngIf="elementoRUP.requeridos?.length">
    <plex-wrapper class="w-100">
        <plex-grid cols="2">
            <ng-container *ngIf="!valorManual">
                <ng-container *ngFor="let item of elementoRUP.requeridos; let i = index">
                    <div *ngIf="item.elementoRUP" class="pr-3">
                        <rup [elementoRUP]="item.elementoRUP" [paciente]="paciente" [params]="item.params"
                             [prestacion]="prestacion" [registro]="registro.registros[i]" [soloValores]="soloValores"
                             (change)="emitChange2()" (ejecutarAccion)="emitEjecutarAccion($event)"
                             [habilitado]="valorManual">
                        </rup>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="valorManual">
                <ng-container *ngFor="let item of elementoRUP.requeridos; let i = index">
                    <div *ngIf="item.elementoRUP" class="pr-3">
                        <rup [readonly]="false" [elementoRUP]="item.elementoRUP" [paciente]="paciente"
                             [params]="{ title: item.params.title, type: item.params.type }" [prestacion]="prestacion"
                             [registro]="registro.registros[i]" [soloValores]="soloValores" (change)="emitChange2()"
                             (ejecutarAccion)="emitEjecutarAccion($event)" [habilitado]="valorManual">
                        </rup>
                    </div>
                </ng-container>
            </ng-container>

            <div class="d-flex align-items-center w-100">
                <div class="w-100">
                    <!-- Valor/resultado del cálculo -->
                    <ng-container *ngIf="soloValores || !valorEditable">
                        <p>
                            <ng-container *ngIf="!hasRules">
                                <label>{{ registro.concepto.term}} </label>
                                {{registro.valor}}
                            </ng-container>
                            <plex-badge [type]="mensaje.type" *ngIf="mensaje?.texto">
                                {{ mensaje.texto }}
                                <plex-button *ngIf="mensaje.add && !soloValores" (click)="addConcepto(mensaje.add)"
                                             title="Registrar">
                                    <plex-icon name="plus"></plex-icon>
                                </plex-button>
                            </plex-badge>
                        </p>
                    </ng-container>
                    <!-- Campo para ingreso manual del valor del registro -->
                    <form #form="ngForm">
                        <ng-container *ngIf="!soloValores && valorEditable">
                            <plex-float *ngIf="valorManual" [label]="params?.title || registro.concepto.term"
                                        placeholder="Ingrese un valor" [(ngModel)]="registro.valor" label=''
                                        name="valorCalculo" (change)="emitChange2()" [disabled]="!valorManual"
                                        [required]="true" [min]="params?.min" [max]="params?.max">
                            </plex-float>
                            <plex-float *ngIf="!valorManual" [label]="params?.title || registro.concepto.term"
                                        [(ngModel)]="registro.valor" [disabled]="true"
                                        [ngModelOptions]="{standalone: true}" [required]="true" [min]="params?.min"
                                        [max]="params?.max">
                            </plex-float>
                        </ng-container>
                    </form>
                </div>
                <div *ngIf="!soloValores && valorEditable" class="d-flex align-items-start mt-2">
                    <plex-button *ngIf="!valorManual" type="warning" icon="pencil" (click)="changeValorManual(true)"
                                 tooltip="Agregar manualmente" tooltipPosition="left">
                    </plex-button>
                    <plex-button *ngIf="valorManual" class="mr-1" type="danger" icon="close"
                                 (click)="changeValorManual(false)">
                    </plex-button>
                </div>
            </div>
        </plex-grid>
    </plex-wrapper>
</ng-container>