<plex-title size="sm" titulo="Crear campaña">
    <plex-button label="Guardar" type="success" size="sm" [validateForm]="campaniaForm" (click)="save($event)">
    </plex-button>
    <plex-button label="cerrar" type="danger" size="sm" (click)="onCancel()">
    </plex-button>
</plex-title>
<form #campaniaForm="ngForm">
    <plex-wrapper>
        <plex-text grow="full" label="Asunto" [(ngModel)]="campaniaEdit.asunto" name="asunto" [required]="true">
        </plex-text>
        <plex-text grow="full" label="Cuerpo" [(ngModel)]="campaniaEdit.cuerpo" name="cuerpo" [required]="true"
                   [html]="true">
        </plex-text>
        <plex-datetime type="date" [(ngModel)]="campaniaEdit.fechaPublicacion" name="fechaPublicacion"
                       label="Fecha de publicación" [required]="true" [max]="campaniaEdit.vigencia.hasta"
                       [min]="campaniaEdit.vigencia.desde"></plex-datetime>
        <plex-bool label="¿Está activo?" [(ngModel)]="campaniaEdit.activo" name="activo" type="slide">
        </plex-bool>
    </plex-wrapper>
    <plex-title size="sm" titulo="Perfil demográfico destino"></plex-title>
    <plex-wrapper>
        <plex-select grow="full" label="Sexo" [(ngModel)]="campaniaEdit.target.sexo" name="sexo" [data]="sexos">
        </plex-select>
        <plex-int label="Edad desde" [(ngModel)]="campaniaEdit.target.grupoEtario.desde" name="desde">
        </plex-int>
        <plex-int label="Edad hasta" [(ngModel)]="campaniaEdit.target.grupoEtario.hasta" name="hasta">
        </plex-int>
    </plex-wrapper>
    <plex-title size="sm" titulo="Vigencia"></plex-title>
    <plex-wrapper>
        <plex-datetime type="date" [(ngModel)]="campaniaEdit.vigencia.desde" name="fechaDesde" label="Desde"
                       [required]="true" [max]="campaniaEdit.fechaPublicacion">
        </plex-datetime>
        <plex-datetime type="date" [(ngModel)]="campaniaEdit.vigencia.hasta" name="fechaHasta" label="Hasta"
                       [required]="true" [min]="campaniaEdit.fechaPublicacion">
        </plex-datetime>
    </plex-wrapper>
    <plex-title size="sm" titulo="Datos del Acceso"></plex-title>
    <plex-wrapper>
        <plex-text label="Texto acción" [(ngModel)]="campaniaEdit.textoAccion" name="textoAccion"></plex-text>
        <plex-text label="Link" [(ngModel)]="campaniaEdit.link" name="link" [required]="true"></plex-text>
    </plex-wrapper>

    <plex-title size="sm" titulo="Carga de logo">
        <plex-button type="success" size="sm" label="Cargar logo" (click)="upload.click()"></plex-button>
    </plex-title>
    <plex-wrapper>
        <plex-label *ngIf="!imagenSvg && !errorFormato" titulo="No hay un logo cargado"></plex-label>
        <input type="file" (change)="changeListener($event)" style="display:none;" #upload>
        <plex-text style="display:none;" [(ngModel)]="imagenSegura" [required]="true" name="imagenSegura">
        </plex-text>
        <span *ngIf="errorFormato">
            <plex-badge type="danger"> Archivo inválido </plex-badge>
        </span>
        <div *ngIf="!errorFormato" [innerHtml]="imagenSegura"></div>
    </plex-wrapper>
</form>
