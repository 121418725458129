<form #form="ngForm">

    <plex-title titulo="Devolver Carpeta" size="md"></plex-title>

    <plex-select label='Estado' [(ngModel)]="devolverHC.estado" name="nombre" [required]="true"
                 placeholder="Seleccione un estado..." [data]="estadosDevolucion">
    </plex-select>
    <plex-text label="Observaciones" [(ngModel)]="devolverHC.observaciones" name="observaciones"
               placeholder="Ingrese observaciones...">
    </plex-text>

    <div class="pt-2">
        <plex-button class="float-left" label="Cancelar" type="danger" (click)="cancel()">Cancelar
        </plex-button>
        <plex-button class="float-right" label="Guardar" type="success" (click)="save()">Guardar
        </plex-button>
    </div>
</form>