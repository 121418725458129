<plex-layout main="8">
    <plex-layout-main>
        <form #formulario="ngForm">

            <plex-title size="md" titulo="Datos básicos">
                <plex-button label="volver" type="danger" position="left" routerLink='/tm/profesional'></plex-button>
                <plex-button *ngIf='mostrarBtnGuardar' label="Guardar" type="success" position="right"
                             [validateForm]="formulario" (click)='save($event)'>
                </plex-button>
            </plex-title>
            <plex-wrapper>
                <plex-text label="Nombre" [(ngModel)]="profesional.nombre" name="profesional.nombre"
                           [readonly]="validado" [required]="true" grow="3">
                </plex-text>
                <plex-text label="Apellido" [(ngModel)]="profesional.apellido" name="profesional.apellido"
                           [readonly]="validado" [required]="true" grow="3">
                </plex-text>
                <plex-int label="Número de Documento" [(ngModel)]="profesional.documento" name="profesional.documento"
                          [readonly]="validado" [required]="true" grow="3">
                </plex-int>
                <plex-select [data]="sexos" [(ngModel)]="profesional.sexo" name='sexo' placeholder="Seleccione..."
                             label="Sexo" [readonly]="validado" [required]="true" grow="3">
                </plex-select>
                <plex-datetime type="date" label="Fecha de nacimiento" [(ngModel)]="profesional.fechaNacimiento"
                               name="fechaNacimiento" [required]="true" [readonly]="validado" grow="3">
                </plex-datetime>
            </plex-wrapper>

            <plex-title size="md" titulo="Datos de contacto">
                <plex-bool [(ngModel)]="noPoseeContacto" label="Sin datos de contacto" name="noPoseeContacto"
                           type="slide" (change)="limpiarContacto()"></plex-bool>
            </plex-title>
            <plex-wrapper class="mr-1" *ngFor="let _unContacto of profesional.contactos; let i = index">
                <plex-select [data]="tipoComunicacion" [(ngModel)]="_unContacto.tipo" name='tipo-{{i}}'
                             placeholder="Seleccione..." label="Tipo" [readonly]="noPoseeContacto"
                             [required]="!noPoseeContacto" (change)="_unContacto.valor = ''">
                </plex-select>
                <plex-phone *ngIf="contactoTelefonico(i)" label="Número" [(ngModel)]="_unContacto.valor"
                            placeholder="Ej: 2990000000" name="valor-{{i}}" [required]="!noPoseeContacto"
                            [readonly]="noPoseeContacto" [pattern]="patronContactoNumerico">
                </plex-phone>
                <plex-text email label="Dirección" *ngIf="!contactoTelefonico(i)" [(ngModel)]="_unContacto.valor"
                           placeholder="Ej: ejemplo@email.com" required="true" name="valor-{{i}}"
                           [readonly]="noPoseeContacto">
                </plex-text>
                <plex-button *ngIf="i == 0" title="Agregar contacto" type="info" icon="plus" name="agregarContacto"
                             (click)="addContacto('celular', '')" [disabled]="noPoseeContacto">
                </plex-button>
                <plex-button *ngIf="i > 0" type="danger" icon="close" (click)="removeContacto(i)">
                </plex-button>
            </plex-wrapper>
            <plex-title size="md" titulo="Datos profesionales">
                <plex-bool [(ngModel)]="profesional.habilitado"
                           label="{{profesional.habilitado? 'Profesional habilitado':'Profesional deshabilitado'}}"
                           name='habilitado' type="slide"></plex-bool></plex-title>
            <plex-wrapper>
                <plex-select [(ngModel)]="profesional.profesionExterna" name="profesion" label="Profesión"
                             [data]="profesiones" [required]="false" grow="2">
                </plex-select>
                <plex-text [(ngModel)]="profesional.matriculaExterna" name="matriculaExterna" label="Matrícula"
                           [required]="false" grow="2">
                </plex-text>
            </plex-wrapper>

            <plex-grid type="full" cols="2">
                <div>
                    <plex-title size="md" titulo="Otros"> </plex-title>
                    <plex-text [(ngModel)]="profesional.observaciones" name="observaciones" columns="5" multiline="true"
                               placeholder="Observaciones" [required]="false">
                    </plex-text>
                </div>
                <div>
                    <app-firma-profesional [profesional]="profesional" (onFileUploaded)="setFirma($event)">
                    </app-firma-profesional>
                </div>

            </plex-grid>
        </form>
    </plex-layout-main>


    <plex-layout-sidebar type="invert">
        <plex-title titulo="DETALLE" size="md">
            <plex-button *ngIf="profesional.sexo && profesional.documento" type="info" size="sm" [disabled]="validado"
                         label="Validar Renaper" (click)="renaperVerification(profesional)"></plex-button>
            <plex-button *ngIf="profesional.sexo && profesional.documento && seEstaCreandoProfesional" type="warning"
                         size="sm" [disabled]="!validado" label="Deshacer" (click)="validado = false;"></plex-button>
        </plex-title>
        <plex-detail size="lg">
            <img *ngIf="fotoProfesional" [src]="fotoProfesional">
            <div *ngIf="profesional.apellido || profesional.nombre" title> {{ profesional.apellido | uppercase }}, {{
                profesional.nombre | uppercase}}</div>
            <div *ngIf="profesional.documento" subtitle> {{ profesional.documento }} </div>
            <plex-label *ngIf="profesional.fechaNacimiento" titulo="Fecha de Nacimiento"
                        subtitulo="{{ profesional.fechaNacimiento | fecha }}">
            </plex-label>
            <plex-label *ngIf="profesional.fechaNacimiento" titulo="Edad" subtitulo="{{ profesional | edad }}">
            </plex-label>
            <plex-label *ngIf="profesional.sexo" titulo="Sexo" subtitulo="{{ profesional.sexo }}"></plex-label>
        </plex-detail>
    </plex-layout-sidebar>
</plex-layout>