<plex-title titulo="Documentos del paciente" size="sm">
    <plex-button *ngIf="!showAdd" type="success" icon="plus" size="md" (click)="showFormulario()"
                 tooltip="Agregar documento"></plex-button>
    <plex-button *ngIf="showAdd" type="success" icon="check" size="md" (click)="addDocumentos()"
                 tooltip="Agregar documento" [disabled]="invalid"></plex-button>
    <plex-button *ngIf="showAdd" type="danger" icon="close" size="md" (click)="cancelar()" tooltip="Cancelar">
    </plex-button>
</plex-title>
<ng-container *ngIf="showAdd ">
    <div class="grow">
        <plex-select [(ngModel)]="documento.tipo" placeholder="seleccione tipo" labelField="label"
                     [data]="tipoDocumentos" (change)="onTipoChange()">
        </plex-select>

        <upload-file label="Adjuntar archivo" (onUpload)="onUpload($event)" [extensiones]="extensions">
        </upload-file>
    </div>
    <shared-galeria-archivos [files]="archivos | galeria | async" (remove)="imageRemoved($event)" [modulo]="'mpi'">
    </shared-galeria-archivos>
</ng-container>

<plex-accordion *ngIf="!showAdd && paciente.documentos?.length > 0">
    <plex-panel *ngFor="let item of paciente.documentos">
        <div plex-accordion-title class="d-flex justify-content-between align-items-center">
            <span class="d-flex">
                <plex-icon name="documento" type="info" size="xl"></plex-icon>
                <plex-label [titulo]="item.tipo.label"></plex-label>
            </span>
            <span>
                <plex-badge type="info">
                    {{ item.fecha | fecha: 'utc'}}
                    {{item.fecha | date:'HH:mm'}}
                </plex-badge>
                <plex-button type="danger" icon="delete" size="sm" (click)="removeItem(item)"
                             tooltip="Eliminar documento">
                </plex-button>
            </span>
        </div>
        <shared-galeria-archivos [files]="item.archivos | galeria | async" [readonly]="true"></shared-galeria-archivos>
    </plex-panel>
</plex-accordion>