<form class="plex-layout">
    <div id="printpage">
        <section>
            <plex-box>
                <header>
                    <div class="row">
                        <div class="col-6">LISTADO DE SOLICITUDES DE CARPETAS DE PACIENTES</div>
                        <div class="col-6 text-right">{{solicitudes[0].fecha | fecha}}</div>
                    </div>
                </header>
                <table *ngFor="let solicitud of solicitudes">
                    <thead>
                        <th colspan="14">
                            <span *ngIf="!solicitud.datosSolicitudManual">
                                * PROFESIONAL | {{ solicitud.fecha | fecha }} |
                                {{ solicitud.datosPrestamo.turno.profesionales | enumerar:['apellido','nombre'] }} |
                                {{ solicitud.datosPrestamo.turno.tipoPrestacion.term }} -> {{ solicitud.numero}} |
                                {{solicitud.paciente | nombre}}
                            </span>
                            <span *ngIf="solicitud.datosSolicitudManual">
                                <span *ngIf="solicitud.datosSolicitudManual.profesional">
                                    * PROFESIONAL | {{ solicitud.fecha | fecha }} | {{ solicitud.datosSolicitudManual.profesional | nombre
                                    }} -> {{ solicitud.numero }} | {{solicitud.paciente | nombre}}
                                </span>
                                <span *ngIf="!solicitud.datosSolicitudManual.profesional">
                                    * ADMINISTRATIVO | {{ solicitud.fecha | fecha }} | {{ solicitud.datosSolicitudManual.responsable | nombre
                                    }} -> {{ solicitud.numero }} | {{solicitud.paciente | nombre}}
                                </span>
                            </span>
                        </th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let bloque of solicitud.bloques">
                            <tr *ngFor="let turno of bloque.turnos; let i=index">
                                <td *ngIf="turno.estado === 'asignado' && turno.paciente?.carpetaEfectores?.length > 0">
                                    <span *ngFor="let carpeta of turno.paciente.carpetaEfectores">
                                        <span *ngIf="carpeta.organizacion?._id == idOrganizacion && carpeta.nroCarpeta!==null && 
                                        carpeta.nroCarpeta !== '' && !carpeta.nroCarpeta.includes('PDR')">
                                            {{carpeta.nroCarpeta}} - {{ turno.paciente | nombre }}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <br>
                </table>
            </plex-box>
        </section>
    </div>

    <footer>
        <div class="row ">
            <div class="col text-left ">
                <plex-button type="danger " label="Cancelar " (click)="cancelar() "></plex-button>
                <plex-button type="success " label="Imprimir " (click)="imprimir() "></plex-button>
            </div>
            <div class="col-1 text-right ">
            </div>
        </div>
    </footer>
</form>