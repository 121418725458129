export const apiKeys = {
 produccion: 'AIzaSyASDBH6nzhzZFi6LC7Pkcm54s1Xp9iltZI',
 develop: 'AIzaSyDOVYxyP3ReFUiH0QDQwYPA4iLENcvmYW0',
 sanjuan: 'AIzaSyDi7-vRowTEvSqXRIH3bpa17US1MeV08_4'
};

export const mapCenter = [-68.0592033508488, -38.951228864424635];

export const hotjar = {
    key: '1609065'
};
export const analytics = {
    key: 'UA-145168802-2'
};
export const password_recovery = {
    key: 'enabled'
};

export const captcha = {
    key: '6Lc5nUsaAAAAALJ15OtJMKpdW9XSEOl13A_3Hx6i',
    enabled: true
};
