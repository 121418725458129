<div *ngIf="!showCargarSolicitud">
    <plex-title main titulo="Solicitudes">
        <plex-button *ngIf="puedeCrearSolicitud" type="success" label="Cargar Solicitud nueva"
                     (click)="formularioSolicitud()"></plex-button>
    </plex-title>
    <div class="row mt-2">
        <div class="col-12">
            <ng-container *ngIf="solicitudesPrestaciones.length > 0; then lista else mensaje"></ng-container>
            <ng-template #lista>
                <!-- Lista de solicitudes -->
                <table class="table table-striped  table-sm">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Prestación</th>
                            <th>Equipo de Salud/Organización</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prestacion of solicitudesPrestaciones">
                            <td>
                                {{ prestacion.solicitud.fecha | fecha }}
                            </td>
                            <td>
                                {{ prestacion.solicitud.tipoPrestacion.term }}
                            </td>
                            <td>
                                {{ prestacion.solicitud.profesional | nombre }} -
                                {{ prestacion?.solicitud?.organizacion?.nombre }}
                                <span class="text-danger"
                                      *ngIf="prestacion.solicitud?.registros[0]?.valor?.solicitudPrestacion?.autocitado === true">(autocitado)</span>
                            </td>
                            <td class="text-center">
                                <plex-badge *ngIf="prestacion.solicitud?.turno" type="success">Turno Dado</plex-badge>
                                <span *ngIf="verEstado(prestacion)">
                                    <plex-badge *ngIf="prestacion.estados[prestacion.estados.length-1]?.tipo === 'anulada'"
                                                type="danger">
                                        Anulada
                                    </plex-badge>
                                    <plex-badge *ngIf="prestacion.estados[prestacion.estados.length-1]?.tipo === 'asignada'"
                                                type="success">
                                        Asignada
                                    </plex-badge>
                                    <plex-badge *ngIf="pendienteAuditada(prestacion)" type="info">
                                        {{prestacion.estados[prestacion.estados.length-1]?.tipo}}
                                    </plex-badge>
                                </span>
                                <plex-button *ngIf="!prestacion.solicitud?.turno && prestacion.solicitud.turneable"
                                             type="success" size="sm" (click)="darTurno(prestacion)">
                                    Dar turno
                                </plex-button>

                            </td>
                            <td>
                                <plex-button *ngIf="puedeDarTurno(prestacion)" type="info" size="sm"
                                             icon="calendar-plus" tooltip="Dar turno" tooltipPosition="left"
                                             (click)="darTurno(prestacion)">
                                </plex-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>

            <!-- Mensaje -->
            <ng-template #mensaje>
                <plex-icon name="information-outline"></plex-icon> No hay solicitudes pendientes
                <hr>
            </ng-template>
        </div>
    </div>
</div>
<solicitud-turno-ventanilla *ngIf="showCargarSolicitud" [paciente]="paciente"
                            (cerrarSolicitudVentanilla)="cerrarSolicitudVentanilla($event)">
</solicitud-turno-ventanilla>