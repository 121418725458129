<form>
    <div class="buscador">
        <plex-text [(ngModel)]="searchTerm" name="searchTerm" (keyup)="buscar()" placeholder="Buscar ..."
                   [autoFocus]="autofocus"></plex-text>
    </div>
    <table *ngIf="!hideLista" class="table table-bordered table-hover  table-sm ">
        <tbody>
            <tr *ngFor="let item of resultados; let i = index; " class="hover">
                <td (click)="seleccionarPrestacion(item)">
                    ({{item.codigo}}) {{item.nombre}}
                </td>
            </tr>
        </tbody>
    </table>
</form>