<div class="detalle-novedad">
    <div *ngIf="novedad">
        <div class="titulo">
            <div>
                <plex-badge type="info">{{novedad.fecha | date: 'dd/MM/yyyy'}}</plex-badge>
                <plex-badge type="warning">{{novedad.modulo? novedad.modulo.nombre : 'general'}}</plex-badge>
            </div>
            <plex-button type="danger" size="sm" (click)="volverInicio()">Volver</plex-button>
        </div>
        <div class="jumbotron-text">
            <h1 class="display-4">{{ novedad.titulo }}</h1>
        </div>
        <div class="overlay">
            <div class="contenedor-imagen">
                <img *ngIf="novedad.imagenes.length > 0" [src]="fotos[0].url" alt="">
            </div>
        </div>
        <div class="contenedor-nota">
            <div class="contenedor-texto">
                <article [innerHTML]=" novedad.descripcion ">
                </article>
                <div class="galeria-imagenes">
                    <img *ngFor="let item of fotos; let i = index" [src]="item.url" (click)="open(i)" [hidden]="(i===0)"
                         class="img-thumbnail">
                </div>
            </div>
        </div>
    </div>
</div>