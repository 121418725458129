<div *ngIf="paciente">
    <div>
        <img class="foto-paciente" [ngClass]="{'img-fallecido' : paciente.fechaFallecimiento}"
             [mpiFotoPaciente]="paciente" />
    </div>
    <div class="datos-paciente">
        <div>
            <strong>{{paciente | nombre}}</strong>
        </div>
        <div class="small">
            <ng-container *ngIf="paciente.documento !== ''">
                {{ paciente.documento }}
            </ng-container>
            <ng-container *ngIf="paciente.documento === ''">
                <b>Sin documento (temporal)</b>
            </ng-container>
            |
            <ng-container *ngIf="paciente.fechaNacimiento !== null">
                {{ paciente | edad }}
            </ng-container>
            <ng-container *ngIf="paciente.fechaNacimiento === null">
                <b>Sin fecha de nacimiento (temporal)</b>
            </ng-container>
        </div>
    </div>
    <div class="popover popover-bottom" *ngIf="!hidePopup">
        <div class="popover-content">
            <paciente-detalle [paciente]="paciente" size="sm" orientacion="horizontal"></paciente-detalle>
            <plex-button *ngIf="cambiarPaciente" (click)="cambioDePaciente()" label="Cambiar paciente"
                         type="primary btn-sm"></plex-button>
        </div>
    </div>
</div>