<paciente-buscar [hostComponent]="hostComponent" [create]="create" [returnScannedPatient]="returnScannedPatient"
                 (searchStart)="onSearchStart()" (searchEnd)="onSearchEnd($event)" (searchClear)="onSearchClear()"
                 grow="full">
</paciente-buscar>
<plex-loader *ngIf="loading" type="ball-pulse"></plex-loader>

<paciente-listado *ngIf="resultados?.length" [pacientes]="resultados" [editing]="editing"
                  (selected)="onSelected($event)" (edit)="onEdit($event)" (relaciones)="onRelaciones($event)"
                  grow="full" [height]="height" [offset]="offset">
</paciente-listado>
<div *ngIf="resultados && !resultados.length && !searchAreaCleared" class="alert alert-danger" grow="full">
    <plex-icon name="account-alert"></plex-icon> No se encontró ningún paciente
</div>