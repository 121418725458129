<form #formulario="ngForm">
    <fieldset>
        <legend>Detalle de agenda</legend>
        <div class="row">
            <div class="col">
                <label>Agenda </label> {{ agenda.horaInicio | date: 'EEE' | uppercase }}
                {{ agenda.horaInicio | date: 'dd/MM/yyyy'}},
                {{ agenda.horaInicio | date: 'HH:mm'}} a {{ agenda.horaFin | date: 'HH:mm'}} hs
            </div>
            <div class="col-2">
                <plex-badge type="info" *ngIf="agenda.dinamica">Dinámica</plex-badge>
                <plex-badge type="danger" *ngIf="!agenda.nominalizada">No Nominalizada</plex-badge>
                <plex-badge type="{{estadosAgenda[agenda.estado]?.class}}">
                    {{estadosAgenda[agenda.estado]?.nombre}}</plex-badge>

            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Tipos de prestación</label>
                <div *ngFor="let tipoPrestacion of agenda.tipoPrestaciones">{{tipoPrestacion.nombre}}</div>
            </div>
            <div class="col">
                <label>Equipo de Salud</label>
                <span *ngIf="agenda.profesionales?.length == 0" class="text-danger">Equipo de Salud no asignado</span>
                <ng-container *ngIf="agenda.profesionales">
                    <div>{{agenda.profesionales | enumerar:['apellido','nombre']}}</div>
                </ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <table *ngFor="let bloque of agenda.bloques" class="table table-striped">
                    <thead *ngIf="bloque.descripcion">
                        <tr>
                            <th colspan="4">{{bloque.descripcion}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let turno of bloque.turnos; let i=index" class="hover">

                            <td>
                                <strong *ngIf="turno">{{ turno.horaInicio | date: 'HH:mm' }}</strong>
                            </td>
                            <td>
                                <span *ngIf="turno.paciente && turno.paciente.id">{{ turno.paciente | nombre }}
                                </span>
                                <small *ngIf="turno.paciente && turno.paciente.id && turno.paciente.documento !== ''">
                                    <span *ngIf="turno.paciente.documento !== ''">| DNI:
                                        {{ turno.paciente.documento }}</span>
                                </small>
                                <small *ngIf="turno.paciente && turno.paciente.id && turno.paciente.documento === ''">
                                    <span>| Sin documento (temporal)</span>
                                </small>
                                <small>
                                    <span *ngIf="turno?.paciente?.id && turno?.paciente?.carpetaEfectores?.length > 0">
                                        <span *ngFor="let carpeta of turno.paciente.carpetaEfectores">
                                            <span *ngIf="carpeta.organizacion?._id == idOrganizacion">
                                                | Nro Carpeta {{carpeta.nroCarpeta}}
                                            </span>
                                        </span>
                                    </span>
                                </small>
                                <span *ngIf="turno && turno.estado === 'disponible'">Disponible</span>
                                <span [ngClass]="{'text-danger': turno.estado === 'turnoDoble'}"
                                      *ngIf="turno && turno.estado === 'turnoDoble'">Turno Doble
                                </span>
                                <span [ngClass]="{'text-danger': turno.estado === 'suspendido'}"
                                      *ngIf="turno && turno.estado === 'suspendido'">Suspendido</span>
                                <span *ngIf="turno && turno.nota">
                                    <i title="{{turno.nota}}" class="text-warning warning mdi mdi-message"></i>
                                </span>

                            </td>
                        </tr>

                    </tbody>
                </table>
                <table class="table table-striped">
                    <thead *ngIf="agenda.sobreturnos.length > 0">
                        <tr>
                            <th colspan="4">Sobreturnos </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- SOBRETURNOS -->
                        <tr *ngFor="let sobreturno of agenda.sobreturnos; let i=index" class="hover"
                            [ngClass]="{'bg-inverse text-white': estaSeleccionado(agenda.sobreturnos[i])}">
                            <td (click)="seleccionarTurno(sobreturno, -1)">
                                <i *ngIf="estaSeleccionado(agenda.sobreturnos[i])" class="mdi mdi-checkbox-marked"></i>
                                <i *ngIf="!estaSeleccionado(agenda.sobreturnos[i])"
                                   class="mdi mdi-checkbox-blank-outline"></i>
                            </td>
                            <td (click)="seleccionarTurno(agenda.sobreturnos[i], bloque)">
                                <strong *ngIf="sobreturno">{{ sobreturno.horaInicio | date: 'HH:mm' }}</strong>
                            </td>
                            <td (click)="seleccionarTurno(agenda.sobreturnos[i], -1)">
                                <span
                                      *ngIf="sobreturno.paciente && sobreturno.paciente.id">{{ sobreturno.paciente | nombre }}
                                </span>
                                <small>
                                    <span
                                          *ngIf="sobreturno.paciente && sobreturno.paciente.id && sobreturno.paciente.documento !== ''">|
                                        DNI: {{ sobreturno.paciente.documento }}</span>
                                    <span *ngIf="sobreturno.paciente && sobreturno.paciente.id && sobreturno.paciente.documento === ''"
                                          class="text-danger">| DNI: Sin documento (temporal)</span>
                                </small>
                                <small>
                                    <span
                                          *ngIf="sobreturno?.paciente?.id && sobreturno?.paciente?.carpetaEfectores?.length > 0">
                                        <span *ngFor="let carpeta of sobreturno.paciente.carpetaEfectores">
                                            <span *ngIf="carpeta.organizacion?._id == idOrganizacion">
                                                | Nro Carpeta {{carpeta.nroCarpeta}}
                                            </span>
                                        </span>
                                    </span>
                                </small>
                                <span [ngClass]="{'text-danger': sobreturno.estado === 'suspendido'}"
                                      *ngIf="sobreturno && sobreturno.estado === 'suspendido'">Suspendido</span>
                                <span *ngIf="sobreturno && sobreturno.nota">
                                    <i title="{{sobreturno.nota}}" class="text-warning warning mdi mdi-message"></i>
                                </span>

                            </td>
                        </tr>
                    </tbody>
                    <!-- /SOBRETURNOS -->
                </table>
            </div>
        </div>
    </fieldset>
</form>