import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'visualizacion-reglas-top',
    templateUrl: './visualizacionReglasTop.html',
})
export class VisualizacionReglasTopComponent {

    esParametrizado = false;

}
