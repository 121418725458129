<form #form="ngForm">
    <div *ngIf="params?.title; else showTerm">
        <label class="text-capitalize">{{ params.title }}</label>
    </div>
    <ng-template #showTerm>
        <label class="text-capitalize">{{registro.concepto.term}}</label>
    </ng-template>
    <div class="row">
        <div [ngClass]="{'col-5': !soloValores}"
             *ngIf="!soloValores && params?.seleccionarOpcion && conceptosSeleccionar">
            <ng-container *ngFor="let concepto of conceptosSeleccionar; let i = index">
                <plex-bool *ngIf="!soloValores" [(ngModel)]="concepto.checked" (change)="seleccionarOpcion(concepto)"
                           label="{{concepto.term}}" name="concepto-{{concepto.conceptId}}"></plex-bool>
            </ng-container>

        </div>
        <div [ngClass]="{'col-5': !soloValores, 'col-12': soloValores}" *ngIf="!params?.seleccionarOpcion">
            <plex-text [html]="true" *ngIf="!soloValores && afterInit" class="form-control" [(ngModel)]="registro.valor"
                       name="descripcion" [required]="params.required" rows="6" placeholder="Describir tratamiento"
                       id="descripcion" name="descripcion">
            </plex-text>

            <ng-container *ngIf="soloValores">
                <span [innerHTML]="registro.valor"></span>
            </ng-container>

        </div>
        <!-- Area droppable del elemento -->
        <div [ngClass]="{'col-7': !soloValores, 'col-12': soloValores}" droppable [dropScope]="'registros-rup'"
             [dragOverClass]="'drag-target-border'" (onDrop)="onConceptoDrop($event)">
            <div *ngIf="soloValores && registro?.registros?.length === 0" class="add mt-4">
                No hay registros vinculados
            </div>
            <div class="add" *ngIf="!soloValores && !registro?.registros?.length && !ocultarPanel">
                <p>
                    <i class="mdi mdi-plus-circle"></i>
                    <br> Agregar prestaciones
                    <br>asociadas al tratamiento
                </p>
            </div>
            <div *ngIf="registro?.registros?.length">
                <ng-container *ngFor="let registro of registro.registros, let i = index">
                    <div droppable [dropScope]="'vincular-registros-rup'" (onDrop)="vincularRegistros($event, registro)"
                         [dragOverClass]="'drag-target-border'" [ngClass]="{'relacionado': registro?.relacionadoCon}">
                        <div class="branch"></div>
                        <div class="rup-card {{ registro | semanticClass }}">
                            <div class="rup-header">
                                <div class="icon-rup drag-handle" draggable
                                     [dragScope]="['orden-registros-rup', 'vincular-registros-rup', 'borrar-registros-rup']"
                                     [dragClass]="'drag-target-border'" [dragData]="registro">
                                    <i class="adi {{ registro | semanticIcon }}"></i>
                                </div>
                                <div class="title">
                                    {{registro.nombre}}

                                    <div class="vinculadoCon"
                                         *ngIf="registro.relacionadoCon && registro.relacionadoCon.length > 0 && !confirmarDesvincular[registro.id] && (!confirmarEliminar || (confirmarEliminar && indexEliminar != i))">

                                        <plex-badge type="info">
                                            {{registro.relacionadoCon[0].concepto.term }}
                                        </plex-badge>
                                        <a href="javascript:void(0);"
                                           (click)="desvincular(registro, registro.relacionadoCon[0])"
                                           class="desvincular"
                                           *ngIf="registro.relacionadoCon && registro.relacionadoCon.length > 0 && !confirmarDesvincular[i] && !confirmarEliminar && !registro.valor?.origen"
                                           title="Desvincular" class="btn btn-sm btn-primary">
                                            <i class="mdi mdi-link-variant-off"></i>
                                        </a>

                                    </div>
                                </div>
                                <div class="actions"
                                     *ngIf="!confirmarDesvincular[registro.id] && (!confirmarEliminar || (confirmarEliminar && indexEliminar != i) )">
                                    <plex-badge [type]="registro | semanticClass">
                                        {{!registro.esSolicitud ? registro.concepto.semanticTag : 'solicitud'}}
                                    </plex-badge>
                                    <plex-badge type="info ml-1"
                                                *ngIf='registro.esPrimeraVez && prestacion.estados[prestacion.estados.length - 1].tipo === "validada"'>
                                        Es primera vez
                                    </plex-badge>
                                    <a *ngIf="!soloValores" href="javascript:void(0);" title="Quitar de la consulta"
                                       (click)="confirmarEliminarRegistro(registro, 'card')"
                                       class="btn btn-sm btn-danger eliminar">
                                        <i class="mdi mdi-delete"></i>
                                        <i class="mdi mdi-delete-empty"></i>
                                    </a>
                                    <a *ngIf="!soloValores" href="javascript:void(0);"
                                       (click)="cambiaValorCollapse(registro.id)"
                                       class="btn btn-sm btn-primary collapse-card">
                                        <i class="mdi mdi-chevron-down"
                                           [ngClass]="{'spin-in' : !itemsRegistros[registro.id]?.collapse, 'spin-out' : itemsRegistros[registro.id]?.collapse}"></i>
                                    </a>
                                    <rup-acciones-envio-informe [prestacion]="prestacion" [registro]="registro">
                                    </rup-acciones-envio-informe>
                                </div>
                            </div>
                            <div class="rup-body"
                                 [hidden]="itemsRegistros[registro.id]?.collapse || confirmarEliminar || confirmarDesvincular[registro.id]">
                                <!-- ... Body -->
                                <div class="content">
                                    <rup [elementoRUP]="elementosRUPService.elementoRegistro(registro)"
                                         [prestacion]="prestacion" [paciente]="paciente" [registro]="registro"
                                         [soloValores]="soloValores" [params]="elementosRUPService.getParams(registro)">
                                    </rup>
                                </div>
                            </div>
                            <!-- ... Footer -->
                            <div class="rup-footer" *ngIf="registro.relacionadoCon || confirmarEliminar">
                                <!-- <div class="type"></div> -->
                                <div class="text-center col"
                                     *ngIf="registro.relacionadoCon && registro.relacionadoCon.length> 0 && confirmarDesvincular[registro.id]">
                                    <div class="confirmarDesvincular"> ¿Confirmar desvinculación con
                                        <b>{{registro.relacionadoCon[0].concepto.term}}</b>?
                                        <div class="buttons">
                                            <plex-button (click)="cancelarDesvincular(registro.id)" label="Cancelar"
                                                         type="default"></plex-button>
                                            <plex-button (click)="confirmarDesvinculacion(registro.id, i)"
                                                         label="Desvincular" type="success"></plex-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center col"
                                     *ngIf="confirmarEliminar && scopeEliminar === 'card' && indexEliminar === i">
                                    <div class="confirmarDesvincular">
                                        <div>¿Quitar de esta consulta?</div>
                                        <div class="buttons">
                                            <plex-button type="danger" label="Cancelar"
                                                         (click)="confirmarEliminar = false;" class="hover">
                                            </plex-button>
                                            <plex-button type="success" label="Confirmar" (click)="eliminarRegistro()"
                                                         class="confirm hover"></plex-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</form>