<div *ngIf="ready$ | async">
    <ng-container *ngIf="prestacion">

        <plex-title main titulo="{{ prestacion.solicitud.tipoPrestacion.term }}" size="sm">
            <plex-button *ngIf="hasPacs" type="info" (click)="onPacs()" size="sm">
                VER IMAGEN
            </plex-button>
            <plex-button *ngIf="puedeDescargarInforme" type="info" size="sm" icon="download" title="Descargar PDF"
                         [disabled]="requestInProgress" (click)="descargarInforme()" titlePosition="left">
            </plex-button>
            <plex-button *ngIf="_puedeEditar" type="info" size="sm" icon="pencil" title="Editar prestación"
                         [disabled]="requestInProgress" (click)="abrirPrestacion()" titlePosition="left">
            </plex-button>
            <ng-content></ng-content>
        </plex-title>
        <plex-grid *ngIf="prestacion.solicitud.ambitoOrigen === 'internacion'" responsive colsMd="3" colsLg="4"
                   type="full">
            <plex-label icon="paciente" size="md" case="capitalize" titulo="Nombre del paciente"
                        subtitulo="{{ prestacion.paciente | nombre }}">
            </plex-label>
            <plex-label icon="reloj" size="md" case="capitalize" titulo="Fecha De Validación"
                        subtitulo="{{ prestacion.estadoActual.createdAt | date: 'EEE dd/MM/yyyy HH:mm' }}">
            </plex-label>
            <plex-label icon="hospital" size="md" case="capitalize" titulo="Organización que Valida"
                        subtitulo="{{ prestacion.estadoActual.createdBy.organizacion.nombre}}">
            </plex-label>
        </plex-grid>
        <plex-grid *ngIf="prestacion.solicitud.ambitoOrigen === 'ambulatorio'" responsive colsMd="3" colsLg="4"
                   type="full">
            <plex-label icon="reloj" size="md" case="capitalize" titulo="Fecha De Inicio Prestación"
                        subtitulo="{{ prestacion.ejecucion.fecha | date: 'EEE dd/MM/yyyy HH:mm' }}">
            </plex-label>
            <plex-label icon="reloj" size="md" case="capitalize" titulo="Fecha De Validación"
                        subtitulo="{{ prestacion.estadoActual.createdAt | date: 'EEE dd/MM/yyyy HH:mm' }}">
            </plex-label>
            <plex-label icon="hospital" span="2" size="md" case="capitalize" titulo="Organización que Valida"
                        subtitulo="{{ prestacion.estadoActual.createdBy.organizacion.nombre}}">
            </plex-label>
            <plex-label icon="medico" size="md" case="capitalize" titulo="Profesional que valida"
                        subtitulo="{{ prestacion.estadoActual.createdBy | nombre }}"></plex-label>
        </plex-grid>
        <plex-grid *ngIf="mostrarMas" responsive colsMd="3" colsLg="4" type="full">
            <plex-label icon="reloj" size="md" case="capitalize"
                        *ngIf="(getTimestamp(prestacion.ejecucion.fecha) - getTimestamp(prestacion.solicitud.fecha)) > 1000"
                        titulo="Fecha De Creación Solicitud"
                        subtitulo="{{ prestacion.solicitud.fecha | date: 'EEE dd/MM/yyyy HH:mm' }}"></plex-label>
            <plex-label *ngIf="prestacion.solicitud.profesionalOrigen" icon="medico" size="md" case="capitalize"
                        titulo="Profesional solicitante"
                        subtitulo="{{ prestacion.solicitud.profesionalOrigen  | nombre }}"></plex-label>
            <plex-label icon="medico" size="md" case="capitalize" titulo="Profesional que inicia"
                        subtitulo="{{  prestacion.solicitud.profesional | nombre }}"></plex-label>

            <plex-label *ngIf="prestacion.unidadOrganizativa" icon="hospital" span="2" size="md" case="capitalize"
                        titulo="Unidad Organizativa" subtitulo="{{ prestacion.unidadOrganizativa.fsn }}">
            </plex-label>
            <plex-label *ngIf="prestacion.solicitud.organizacionOrigen" icon="hospital" span="2" size="md"
                        case="capitalize" titulo="Organización origen"
                        subtitulo="{{ prestacion.solicitud.organizacionOrigen.nombre }}">
            </plex-label>
            <plex-label icon="hospital" size="md" case="capitalize" titulo="Organización Ejecutante"
                        subtitulo="{{ prestacion.solicitud.organizacion.nombre }}">
            </plex-label>
            <plex-label icon="estetoscopio" size="md" case="capitalize" titulo="Ámbito"
                        subtitulo="{{ prestacion.solicitud.ambitoOrigen }}"></plex-label>
        </plex-grid>
        <plex-button type="info" size="md" [icon]="mostrarMas ? 'chevron-up' : 'chevron-down'" class="mt-2"
                     label="{{ mostrarMas ? 'Ocultar' : 'Mostrar más' }}" justify="center" (click)="mostrar()">
        </plex-button>

        <!-- Registros -->
        <plex-title titulo="Registros de la consulta" size="sm"></plex-title>
        <plex-wrapper>
            <ng-container *ngFor="let elemento of prestacion.ejecucion.registros">
                <div class="rup-card {{ elemento | semanticClass }}">
                    <div class="rup-header">
                        <div class="icon-rup">
                            <i class="adi {{ elemento | semanticIcon }}"></i>
                        </div>
                        <div class="title">
                            <ng-container *ngIf="elemento?.nombre">
                                {{ elemento.nombre[0].toUpperCase() + elemento.nombre.slice(1) }}
                            </ng-container>
                            <div class="float-left"
                                 *ngIf="elemento?.relacionadoCon && elemento?.relacionadoCon.length > 1">
                                <b class="clearfix">Relacionado con:</b>
                                <ng-container *ngFor="let relacionado of elemento?.relacionadoCon">
                                    <plex-badge size="sm" type="info mr-1">
                                        {{ relacionado | relacionRUP }}
                                    </plex-badge>
                                </ng-container>
                            </div>
                        </div>
                        <div class="actions">
                            <plex-badge *ngIf="elemento?.relacionadoCon && elemento?.relacionadoCon.length === 1"
                                        size="sm" type="info">
                                <b> Relacionado con: </b>
                                {{ elemento.relacionadoCon[0].concepto?.term[0].toUpperCase() +
                                elemento.relacionadoCon[0].concepto?.term.slice(1)}}
                            </plex-badge>
                            <plex-badge size="sm" type="info" *ngIf="elemento.esDiagnosticoPrincipal">
                                Procedimiento / diagnóstico principal
                            </plex-badge>
                        </div>
                    </div>
                    <div class="rup-body">
                        <div class="legend">
                            <span>
                                {{ (elemento.esSolicitud) ? 'solicitud' : elemento.concepto.semanticTag}}
                            </span>
                        </div>
                        <div class="content">
                            <div class="row" *ngIf="prestacion.paciente">
                                <div class="col-12">
                                    <rup [elementoRUP]="elementosRUPService.elementoRegistro(elemento)"
                                         [params]="elementosRUPService.getParams(elemento)" [prestacion]="prestacion"
                                         [registro]="elemento" [paciente]="prestacion.paciente" [soloValores]="true"
                                         [vistaHUDS]="false">
                                    </rup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="prestacion.ejecucion.registros.length === 0">
                <plex-label type="info" titulo="Sin registros"
                            subtitulo="La prestación aún no ha sido validada. Posiblemente aún se encuentra en ejecución."
                            size="lg" class="my-4 w-100" icon="zoom" direction="column">
                </plex-label>
            </ng-container>
        </plex-wrapper>
    </ng-container>
</div>