<plex-title titulo="Detalle de campaña" size="md">
    <plex-button size="sm" label="Modificar" type="success" (click)="modificar()"></plex-button>
    <plex-button size="sm" label="Cerrar" type="danger" (click)="cancelar()"></plex-button>
</plex-title>
<div class="text-center" [innerHTML]="imagen"></div>
<plex-label titulo="Asunto"></plex-label>
<div [innerHtml]="campania.cuerpo"></div>
<plex-title titulo="Perfil Demográfico Destino" size="sm"></plex-title>
<plex-label titulo="Sexo" subtitulo="{{(campania.target?.sexo | titlecase) || 'Indistinto'}}">
</plex-label>
<plex-label titulo="Edad desde" subtitulo="{{(campania.target?.grupoEtario?.desde) || 'Indistinto'}}">
</plex-label>
<plex-label titulo="Edad hasta" subtitulo="{{(campania.target?.grupoEtario?.hasta)|| 'Indistinto'}}">
</plex-label>
<plex-title titulo="Vigencia" size="sm">
    <plex-badge *ngIf="campania.activo" type="success">
        {{campaniaSaludService.getEstado(campania)}}</plex-badge>
    <plex-badge *ngIf="!campania.activo" type="danger">
        {{campaniaSaludService.getEstado(campania)}}</plex-badge>
</plex-title>
<plex-label titulo="Fecha desde" subtitulo="{{campania.vigencia?.desde | fecha}}">
</plex-label>
<plex-label titulo="Fecha hasta" subtitulo="{{campania.vigencia?.hasta | fecha}}">
</plex-label>
<plex-title titulo="Datos del acceso" size="sm"></plex-title>
<plex-label titulo="Texto acción" subtitulo="{{campania.textoAccion | uppercase}}"></plex-label>
<a href="{{campania.link}}">
    <plex-label titulo="Link" subtitulo="{{campania.link}}"></plex-label>
</a>
