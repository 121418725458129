<plex-title size="md" titulo="Firma Profesional">
    <input #upload class="invisible" type="file" (change)="onImageChange($event)" accept="image/*">
    <plex-button size="sm" label="Adjuntar" type="info" [disabled]='disabledCargar' (click)="upload.click()">
    </plex-button>
</plex-title>
<plex-grid size="lg" type="auto">
    <plex-card *ngIf='urlFirma && !loading'>
        <img [src]="urlFirma" alt="">
    </plex-card>
    <plex-loader *ngIf="loading" type="ball-pulse"></plex-loader>
</plex-grid>