<form #form="ngForm">
    <div class="row flex-column">
        <div class="col-12">
            <ng-container *ngIf="!soloValores; else showSoloValores">
                <plex-datetime [(ngModel)]="registro.valor" [label]="params?.title || registro.concepto.term"
                               name="DateValue" [type]="params?.type || 'date'" [required]="params?.required || false"
                               (change)="onChange()" [readonly]="habilitado">
                </plex-datetime>
            </ng-container>
            <ng-template #showSoloValores>
                <div *ngIf="params?.title; else showTerm">
                    <label class="text-capitalize">{{ params.title }}</label>
                </div>
                <ng-template #showTerm>
                    <label class="text-capitalize">{{ registro.concepto.term }}</label>
                </ng-template>
                {{DateFormat}}
            </ng-template>
        </div>
    </div>
</form>