<ng-container *ngIf="params && !soloValores">
    <div class="container odontograma no-gutters p-0">
        <div>
            <div *ngIf="ultimoOdontograma || odontograma" class="row">
                <div class="col-12">
                    <div class="ngx-pop">
                        &nbsp;
                        <span *ngIf="popOverText?.concepto">
                            Diente {{ popOverText.concepto.term }}
                            <span *ngIf="popOverText?.cara && popOverText?.cara === 'anulada'">(pieza ausente)</span>
                            <span *ngIf="popOverText?.cara && popOverText?.cara === 'pieza'">(pieza completa)</span>
                            <span
                                  *ngIf="popOverText?.cara && popOverText?.cara !== 'pieza' && popOverText?.cara !== 'anulada'">(cara
                                {{ popOverText.cara }})</span>
                            <ng-container *ngIf="popOverText?.relacion?.length > 0 && showRelacion">
                                <span *ngFor="let rel of popOverText?.relacion">
                                    <span *ngIf="rel?.concepto?.term"> - {{ rel.concepto.term }} ({{
                                        rel.concepto.semanticTag }})</span>
                                </span>
                            </ng-container>
                            <ng-container *ngIf="popOverText?.relacion?.concepto && showRelacion">
                                - {{ popOverText.relacion.concepto.term }}
                                ({{ popOverText.relacion.concepto.semanticTag }})
                            </ng-container>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- ÚLTIMO ODONTOGRAMA -->
        <div class=" row no-gutters cargando-odontograma" *ngIf="cargandoUltimoOdontograma">
            <div class="col-12">
                Cargando último odontograma...
            </div>
        </div>
        <div class="row no-gutters último-odontograma p-0 pb-1" *ngIf="ultimoOdontograma"
             [ngClass]="{'hidden': !showUltimoOdontograma, 'visible': showUltimoOdontograma}">
            <div class="col-12 no-gutters pt-2 title">
                <a class="toggle-último float-right hover btn btn-sm btn-info mdi mdi-chevron-{{ showUltimoOdontograma ? 'up' : 'down' }}"
                   (click)="toggleUltimo()" title="{{ showUltimoOdontograma ? 'Ocultar' : 'Mostrar' }}"></a>
                <h6>
                    <a *ngIf="ultimoOdontogramaIndex > 0" (click)="odontogramaAnterior()"
                       class="hover text-warning text-bold mdi mdi-24px mdi-chevron-left"></a>
                    {{ ultimoOdontogramaIndex === odontogramasHUDS.length - 1 ? 'Último odontograma' : 'Odontograma del' }}
                    {{ (ultimoOdontograma.createdAt | fecha)}}
                    <a *ngIf="ultimoOdontogramaIndex < odontogramasHUDS.length - 1" (click)="odontogramaSiguiente()"
                       class="hover text-warning text-bold mdi mdi-24px mdi-chevron-right"></a>
                </h6>
            </div>
            <ng-container *ngIf="ultimoOdontograma && relaciones">
                <!-- CUADRANTE -->
                <ng-container *ngFor="let cuadrante of cuadrantes">
                    <div class="col-6 no-gutters {{ cuadrante }}"
                         [ngClass]="{'hidden': !showUltimoOdontograma, 'visible': showUltimoOdontograma}">
                        <ng-container
                                      *ngFor="let diente of ultimoOdontograma.valor.odontograma[cuadrante]; let i=index">
                            <div class="diente-container text-center">
                                <span class="text-diente">{{ diente.concepto.term }}</span>
                                <svg *ngIf="!piezaAnulada(diente.concepto.conceptId)" xmlns="http://www.w3.org/2000/svg"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" id="cuadrante-sup-der" width="32px"
                                     height="32px" x="0px" y="0px" baseProfile="basic" version="1.1" viewBox="0 0 55 50"
                                     xml:space="preserve">
                                    <!-- DISTAL/MESIAL -->
                                    <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                          (mouseenter)="showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'), true, 0)"
                                          d="M18.042,27.5c0-2.197,0.89-4.187,2.33-5.627l-9.339-9.339c-3.831,3.83-6.2,9.122-6.2,14.966c0,5.845,2.369,11.137,6.2,14.967l9.34-9.34C18.933,31.687,18.042,29.698,18.042,27.5z"
                                          class="diente distal"
                                          [ngClass]="'diente-' + classRelacion(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.concepto?.semanticTag"
                                          title="(esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')" />
                                    <ng-container
                                                  *ngIf="getRegistrosRelAnterior(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.length">
                                        <ng-container
                                                      *ngFor="let reldistal of getRegistrosRelAnterior(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')); let d=index">
                                            <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  [ngClass]="{'registrada': caraValor(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')) !== -1}"
                                                  d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                                  class="diente distal {{ 'diente-' + reldistal.concepto.semanticTag }}"
                                                  title="(esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')"
                                                  (mouseenter)="showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'), true, d)" />
                                            <text (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  fill="#FFFFFF" x="8" y="31"
                                                  font-size="14">{{ getRegistrosRelAnterior(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')).length }}</text>
                                        </ng-container>
                                    </ng-container>
                                    <!-- VESTIBULAR/LINGUAL -->
                                    <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                          (mouseenter)="showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'), true, 0)"
                                          d="M26,19.542c2.197,0,4.187,0.891,5.627,2.331l9.34-9.339c-3.831-3.831-9.122-6.2-14.967-6.2c-5.845,0-11.137,2.369-14.967,6.2l9.339,9.339C21.813,20.433,23.802,19.542,26,19.542z"
                                          class="diente vestibular"
                                          [ngClass]="'diente-' + classRelacion(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.concepto?.semanticTag"
                                          title="(esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')" />
                                    <ng-container
                                                  *ngIf="getRegistrosRelAnterior(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.length">
                                        <ng-container
                                                      *ngFor="let relvestibular of getRegistrosRelAnterior(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')); let v=index">
                                            <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  (mouseenter)="showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'), true, v)"
                                                  [ngClass]="{'registrada': caraValor(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')) !== -1}"
                                                  d="M26,19.542c2.197,0,4.187,0.891,5.627,2.331l9.34-9.339c-3.831-3.831-9.122-6.2-14.967-6.2c-5.845,0-11.137,2.369-14.967,6.2l9.339,9.339C21.813,20.433,23.802,19.542,26,19.542z"
                                                  class="diente vestibular {{ 'diente-' + relvestibular.concepto.semanticTag }}"
                                                  title="(esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')" />
                                            <text (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  fill="#FFFFFF" x="21" y="17"
                                                  font-size="14">{{ getRegistrosRelAnterior(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')).length }}</text>
                                        </ng-container>
                                    </ng-container>
                                    <!-- MESIAL/DISTAL -->
                                    <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                          (mouseenter)="showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'), true, 0)"
                                          d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                          class="diente mesial"
                                          [ngClass]="'diente-' + classRelacion(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.concepto?.semanticTag"
                                          title="(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')" />
                                    <ng-container
                                                  *ngIf="getRegistrosRelAnterior(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.length">
                                        <ng-container
                                                      *ngFor="let relMesial of getRegistrosRelAnterior(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')); let m=index">
                                            <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  (mouseenter)="showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'), true, m)"
                                                  [ngClass]="{'registrada': caraValor(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')) !== -1}"
                                                  d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                                  class="diente mesial {{ 'diente-' + relMesial.concepto.semanticTag }}"
                                                  title="(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')" />
                                            <text (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  fill="#FFFFFF" x="37" y="32"
                                                  font-size="14">{{ getRegistrosRelAnterior(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')).length }}</text>
                                        </ng-container>
                                    </ng-container>
                                    <!-- PALATINA/VESTIBULAR -->
                                    <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                          (mouseenter)="showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'), true, 0)"
                                          d="M26,35.458c-2.198,0-4.188-0.891-5.627-2.331l-9.34,9.34c3.831,3.831,9.122,6.2,14.967,6.2c5.845,0,11.136-2.369,14.966-6.199l-9.34-9.34C30.187,34.567,28.198,35.458,26,35.458z"
                                          class="diente palatina"
                                          [ngClass]="'diente-' + classRelacion(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))?.concepto?.semanticTag"
                                          title="(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')" />
                                    <ng-container
                                                  *ngIf="getRegistrosRelAnterior(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))?.length">
                                        <ng-container
                                                      *ngFor="let relpalatina of getRegistrosRelAnterior(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')); let p=index">
                                            <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  (mouseenter)="showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'), true, p)"
                                                  [ngClass]="{'registrada': caraValor(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')) !== -1}"
                                                  d="M26,35.458c-2.198,0-4.188-0.891-5.627-2.331l-9.34,9.34c3.831,3.831,9.122,6.2,14.967,6.2c5.845,0,11.136-2.369,14.966-6.199l-9.34-9.34C30.187,34.567,28.198,35.458,26,35.458z"
                                                  class="diente palatina {{ 'diente-' + relpalatina.concepto.semanticTag }}"
                                                  title="(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')" />
                                            <text (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  fill="#FFFFFF" x="22" y="46"
                                                  font-size="14">{{ getRegistrosRelAnterior(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')).length }}</text>
                                        </ng-container>
                                    </ng-container>
                                    <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                          (mouseenter)="showTooltip(diente, 'oclusal', true, 0)"
                                          d="M26,19.542c-2.198,0-4.188,0.891-5.628,2.331c-1.44,1.44-2.33,3.43-2.33,5.627c0,2.198,0.891,4.188,2.331,5.627s3.429,2.331,5.627,2.331c2.197,0,4.187-0.89,5.626-2.33c1.44-1.44,2.332-3.43,2.332-5.628c0-2.197-0.891-4.187-2.331-5.627C30.188,20.433,28.198,19.542,26,19.542z"
                                          class="diente oclusal"
                                          [ngClass]="'diente-' + classRelacion(diente, 'oclusal')?.concepto?.semanticTag"
                                          title="oclusal" />
                                    <ng-container *ngIf="getRegistrosRelAnterior(diente, 'oclusal')?.length">
                                        <ng-container
                                                      *ngFor="let reloclusal of getRegistrosRelAnterior(diente, 'oclusal'); let o=index">
                                            <path (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  (mouseenter)="showTooltip(diente, 'oclusal', false, o)"
                                                  [ngClass]="{ 'registrada': caraValor(diente, 'oclusal') !== -1}"
                                                  d="M26,19.542c-2.198,0-4.188,0.891-5.628,2.331c-1.44,1.44-2.33,3.43-2.33,5.627c0,2.198,0.891,4.188,2.331,5.627s3.429,2.331,5.627,2.331c2.197,0,4.187-0.89,5.626-2.33c1.44-1.44,2.332-3.43,2.332-5.628c0-2.197-0.891-4.187-2.331-5.627C30.188,20.433,28.198,19.542,26,19.542z "
                                                  class="diente oclusal {{ 'diente-' + reloclusal.concepto.semanticTag }}"
                                                  title="oclusal" />
                                            <text (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  fill="#FFFFFF" x="22" y="32"
                                                  font-size="14">{{ getRegistrosRelAnterior(diente, 'oclusal').length }}</text>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                                  *ngFor="let relClass of getRegistrosRelAnterior(diente, 'pieza'); let a=index">
                                        <g class="pieza" title="pieza completa">
                                            <!-- Círculo MINI -->
                                            <circle (mouseenter)="showTooltip(diente, 'pieza', true, a)"
                                                    (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                    class="evolucionada" cx="46.285" cy="7.311" r="5.801"
                                                    [ngClass]="'diente-' + relClass.concepto.semanticTag" />
                                            <text (click)="emitEjecutarAccion($event, [diente, cuadrante, odontogramasHUDS])"
                                                  fill="#FFFFFF" x="43" y="12"
                                                  font-size="14">{{ getRegistrosRelAnterior(diente, 'pieza').length }}</text>
                                        </g>
                                        <!-- Círculo GRANDE -->
                                        <circle *ngIf="ultimoOdontograma && tieneEvolucion(diente, 'pieza')" cx="26"
                                                cy="27.5" r="22.246"
                                                [ngClass]="'diente-' + relClass.concepto.semanticTag + '-outline'" />
                                    </ng-container>
                                </svg>
                                <svg *ngIf="piezaAnulada(diente.concepto.conceptId)" xmlns="http://www.w3.org/2000/svg"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" id="cuadrante-sup-der" width="32px"
                                     height="32px" x="0px" y="0px" baseProfile="basic" version="1.1" viewBox="0 0 55 50"
                                     xml:space="preserve">
                                    <g (mouseenter)="showTooltip(diente, 'anulada')">
                                        <circle class="pieza-anulada" cx="26" cy="27.5" r="22.246">
                                        </circle>
                                        <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" stroke-width="3"
                                              x1="8" x2="44" y1="10" y2="44"></line>
                                        <line fill="none" stroke="#FFFFFF" stroke-width="3" stroke-miterlimit="10"
                                              x1="8" x2="44" y1="44" y2="10" />
                                    </g>
                                </svg>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <!-- FIN ÚLTIMO ODONTOGRAMA -->


        <!-- ODONTOGRAMA DE ESTA CONSULTA -->
        <div *ngIf="!soloValores" class="row no-gutters">
            <div class="col-12 no-gutters pt-3">
                <h6>Odontograma de esta consulta</h6>
            </div>
            <ng-container *ngFor="let cuadrante of cuadrantes">
                <!-- CUADRANTE -->
                <div class="col-6 no-gutters {{ cuadrante }}" *ngIf="odontograma[cuadrante].length">
                    <ng-container *ngFor="let diente of odontograma[cuadrante]; let i=index">
                        <div class="diente-container text-center"
                             *ngIf="(ocultarTemporales && diente.concepto.term >= 51) || diente.concepto.term < 51">
                            <span class="text-diente">{{ diente.concepto.term }}</span>
                            <svg *ngIf="!piezaAnulada(diente.concepto.conceptId)" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink" id="cuadrante-sup-der" width="32px"
                                 height="32px" x="0px" y="0px" baseProfile="basic" version="1.1" viewBox="0 0 55 50"
                                 xml:space="preserve">

                                <!-- DISTAL/MESIAL -->
                                <path (click)="seleccionarDiente(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')); showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'), false, 0, true)"
                                      [ngClass]="{'seleccionada': estaSeleccionada(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))}"
                                      d="M18.042,27.5c0-2.197,0.89-4.187,2.33-5.627l-9.339-9.339c-3.831,3.83-6.2,9.122-6.2,14.966c0,5.845,2.369,11.137,6.2,14.967l9.34-9.34C18.933,31.687,18.042,29.698,18.042,27.5z"
                                      class="diente distal {{ 'diente-' + getClassRegistro(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.concepto.semanticTag }}"
                                      title="(esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')" />

                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.length">
                                    <ng-container
                                                  *ngFor="let reldistal of getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')); let d=index">
                                        <path (click)="seleccionarDiente(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')); showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'), false, d, true)"
                                              [ngClass]="{'seleccionada': estaSeleccionada(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))}"
                                              d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                              class="diente distal {{ 'diente-' + reldistal.concepto.semanticTag }}"
                                              title="(esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')" />
                                    </ng-container>
                                </ng-container>

                                <!-- VESTIBULAR/LINGUAL -->
                                <path (click)="seleccionarDiente(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'), false, 0, true)"
                                      [ngClass]="{'seleccionada': estaSeleccionada(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))}"
                                      d="M26,19.542c2.197,0,4.187,0.891,5.627,2.331l9.34-9.339c-3.831-3.831-9.122-6.2-14.967-6.2c-5.845,0-11.137,2.369-14.967,6.2l9.339,9.339C21.813,20.433,23.802,19.542,26,19.542z"
                                      class="diente vestibular {{ 'diente-' + getClassRegistro(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.concepto.semanticTag }}"
                                      title="(esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')" />

                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.length">
                                    <ng-container
                                                  *ngFor="let relvestibular of getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')); let v=index">
                                        <path (click)="seleccionarDiente(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'), false, v, true)"
                                              [ngClass]="{'seleccionada': estaSeleccionada(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))}"
                                              d="M26,19.542c2.197,0,4.187,0.891,5.627,2.331l9.34-9.339c-3.831-3.831-9.122-6.2-14.967-6.2c-5.845,0-11.137,2.369-14.967,6.2l9.339,9.339C21.813,20.433,23.802,19.542,26,19.542z"
                                              class="diente vestibular {{ 'diente-' + relvestibular.concepto.semanticTag }}"
                                              title="(esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')" />
                                    </ng-container>
                                </ng-container>

                                <!-- MESIAL/DISTAL -->
                                <path (click)="seleccionarDiente(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')); showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'), false, 0, true)"
                                      [ngClass]="{'seleccionada': estaSeleccionada(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))}"
                                      d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                      class="diente mesial {{ 'diente-' + getClassRegistro(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.concepto.semanticTag }}"
                                      title="(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')" />


                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.length">
                                    <ng-container
                                                  *ngFor="let relMesial of getRegistrosEjecucion(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')); let m=index">
                                        <path (click)="seleccionarDiente(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')); showTooltip(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'), false, m, true)"
                                              [ngClass]="{'seleccionada': estaSeleccionada(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))}"
                                              d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                              class="diente mesial {{ 'diente-' + relMesial.concepto.semanticTag }}"
                                              title="(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')" />
                                    </ng-container>
                                </ng-container>

                                <!-- PALATINA/VESTIBULAR -->
                                <path (click)="seleccionarDiente(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'), false, 0, true)"
                                      [ngClass]="{'seleccionada': estaSeleccionada(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))}"
                                      d="M26,35.458c-2.198,0-4.188-0.891-5.627-2.331l-9.34,9.34c3.831,3.831,9.122,6.2,14.967,6.2c5.845,0,11.136-2.369,14.966-6.199l-9.34-9.34C30.187,34.567,28.198,35.458,26,35.458z"
                                      class="diente palatina {{ 'diente-' + diente?.concepto.semanticTag }}"
                                      title="(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')" />

                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))?.length">
                                    <ng-container
                                                  *ngFor="let relpalatina of getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')); let p=index">
                                        <path (click)="seleccionarDiente(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'), false, p, true)"
                                              [ngClass]="{'seleccionada': estaSeleccionada(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))}"
                                              d="M26,35.458c-2.198,0-4.188-0.891-5.627-2.331l-9.34,9.34c3.831,3.831,9.122,6.2,14.967,6.2c5.845,0,11.136-2.369,14.966-6.199l-9.34-9.34C30.187,34.567,28.198,35.458,26,35.458z"
                                              class="diente palatina {{ 'diente-' + relpalatina.concepto.semanticTag }}"
                                              title="(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')" />
                                    </ng-container>
                                </ng-container>

                                <!-- OCLUSAL -->
                                <path (click)="seleccionarDiente(diente, 'oclusal'); showTooltip(diente, 'oclusal', false, 0, true)"
                                      [ngClass]="{'seleccionada': estaSeleccionada(diente, 'oclusal'),  'registrada': caraValor(diente, 'oclusal') !==- 1}"
                                      d="M26,19.542c-2.198,0-4.188,0.891-5.628,2.331c-1.44,1.44-2.33,3.43-2.33,5.627c0,2.198,0.891,4.188,2.331,5.627s3.429,2.331,5.627,2.331c2.197,0,4.187-0.89,5.626-2.33c1.44-1.44,2.332-3.43,2.332-5.628c0-2.197-0.891-4.187-2.331-5.627C30.188,20.433,28.198,19.542,26,19.542z"
                                      class="diente oclusal {{ 'diente-' + getClassRegistro(diente, 'oclusal')?.concepto.semanticTag }}"
                                      title="oclusal" />
                                <ng-container *ngIf="getRegistrosEjecucion(diente, 'oclusal')?.length">
                                    <ng-container
                                                  *ngFor="let reloclusal of getRegistrosEjecucion(diente, 'oclusal'); let o=index">
                                        <path (click)="seleccionarDiente(diente, 'oclusal'); showTooltip(diente, 'oclusal', false, o, true)"
                                              [ngClass]="{'seleccionada': estaSeleccionada(diente, 'oclusal'), 'registrada': caraValor(diente, 'oclusal') !==- 1}"
                                              d="M26,19.542c-2.198,0-4.188,0.891-5.628,2.331c-1.44,1.44-2.33,3.43-2.33,5.627c0,2.198,0.891,4.188,2.331,5.627s3.429,2.331,5.627,2.331c2.197,0,4.187-0.89,5.626-2.33c1.44-1.44,2.332-3.43,2.332-5.628c0-2.197-0.891-4.187-2.331-5.627C30.188,20.433,28.198,19.542,26,19.542z "
                                              class="diente oclusal {{ 'diente-' + reloclusal.concepto.semanticTag }} "
                                              title="oclusal" />
                                    </ng-container>
                                </ng-container>

                                <!-- PIEZA -->
                                <g class="pieza"
                                   (click)="seleccionarPieza(diente, 'pieza'); showTooltip(diente, 'pieza', false, 0, true)"
                                   title="pieza completa">
                                    <!-- Círculo MINI -->
                                    <circle class="st1"
                                            [ngClass]="{'seleccionada': estaSeleccionada(diente, 'pieza', false, -1, true)}"
                                            cx="46.285" cy="7.311" r="5.801" />
                                    <path [ngClass]="{'seleccionada': estaSeleccionada(diente, 'pieza')}"
                                          d="M50.668,5.782l-2.925,1.534l2.925,1.542l-0.674,1.25l-2.951-1.631v3.049h-1.507V8.477l-2.961,1.631l-0.674-1.25l2.961-1.542l-2.961-1.534l0.674-1.25l2.961,1.613V3.096h1.507v3.049l2.951-1.613L50.668,5.782z" />
                                    <!-- Círculo GRANDE -->
                                    <circle cx="26" cy="27.5" r="22.246"
                                            [ngClass]="{'seleccionada-outline': estaSeleccionada(diente, 'pieza')}" />
                                </g>
                                <ng-container *ngIf="getRegistrosEjecucion(diente, 'pieza')?.length">
                                    <ng-container
                                                  *ngFor="let relClass of getRegistrosEjecucion(diente, 'pieza'); let pc=index">
                                        <g title="pieza completa">
                                            <!-- Círculo MINI -->
                                            <circle (click)="showTooltip(diente, 'pieza', false, pc, true)"
                                                    class="st1 {{ 'diente-' + relClass.concepto.semanticTag + ' rotate-' + pc }}"
                                                    [ngClass]="{'seleccionada': estaSeleccionada(diente, 'pieza')}"
                                                    cx="46.285" cy="7.311" r="5.801" />
                                            <!-- Asterisco -->
                                            <path [ngClass]="{'seleccionada': estaSeleccionada(diente, 'pieza')}"
                                                  class="{{ 'diente-' + relClass.concepto.semanticTag + ' rotate-' + pc }}"
                                                  d="M50.668,5.782l-2.925,1.534l2.925,1.542l-0.674,1.25l-2.951-1.631v3.049h-1.507V8.477l-2.961,1.631l-0.674-1.25l2.961-1.542l-2.961-1.534l0.674-1.25l2.961,1.613V3.096h1.507v3.049l2.951-1.613L50.668,5.782z" />
                                            <!-- Círculo GRANDE -->
                                            <circle cx="26" cy="27.5" r="22.246"
                                                    [ngClass]="{'seleccionada-outline': estaSeleccionada(diente, 'pieza')}"
                                                    class="{{ 'diente-' + relClass.concepto.semanticTag + '-outline' }}" />
                                        </g>
                                    </ng-container>
                                </ng-container>
                            </svg>
                            <svg *ngIf="piezaAnulada(diente.concepto.conceptId)" xmlns="http://www.w3.org/2000/"
                                 xmlns:xlink="http://www.w3.org/1999/xlink" id="cuadrante-sup-der" width="32px"
                                 height="32px" x="0px" y="0px" baseProfile="basic" version="1.1" viewBox="0 0 55 50"
                                 xml:space="preserve">
                                <g (click)="showTooltip(diente, 'anulada', false, -1, true)">
                                    <circle class="pieza completa pieza-anulada" cx="26" cy="27.5" r="22.246">
                                    </circle>
                                    <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" stroke-width="3" x1="8"
                                          x2="44" y1="10" y2="44"></line>
                                    <line fill="none" stroke="#FFFFFF" stroke-width="3" stroke-miterlimit="10" x1="8"
                                          x2="44" y1="44" y2="10" />
                                </g>
                            </svg>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>
        <div class="row d-flex flex-first">
            <div class="col-4">
                <plex-button class="m-0 p-0" name="ocultarTemporales"
                             label="{{ ocultarTemporales ? 'Ocultar piezas temporales' : 'Mostrar piezas temporales' }}"
                             (click)="toggleOcultarTemporales($event)"></plex-button>
            </div>
            <div class="col-4">
                <plex-bool class="m-0 p-0 margin-fix" [(ngModel)]="seleccionMultiple" name="seleccionMultiple"
                           label="Seleccionar piezas/caras múltiples" (click)="limpiarSeleccion($event)"></plex-bool>
            </div>
            <div class="col-4" *ngIf="piezasSeleccionadas?.length > 0">
                <plex-button class="m-0 p-0" name="limpiar-seleccion" label="Limpiar selección"
                             (click)="limpiarSeleccion($event)"></plex-button>
            </div>
        </div>
        <!-- FIN ODONTOGRAMA DE ESTA CONSULTA -->

    </div>

</ng-container>

<!-- ODONTOGRAMA RESUMEN / VALIDACIÓN -->
<ng-container *ngIf="soloValores && registro?.valor?.odontograma?.cuadranteInferiorDerecho?.length> 0">
    <div class="container odontograma no-gutters p-0 readonly">

        <div class="row no-gutters">
            <div class="col-12 no-gutters">
                <h6>Odontograma Registrado</h6>
            </div>
            <div class="col-12 no-gutters">
                <div class="ngx-pop">
                    &nbsp;
                    <span *ngIf="popOverText?.concepto">
                        Diente {{ popOverText.concepto.term }}
                        <span *ngIf="popOverText?.cara && popOverText?.cara !== 'pieza'">(cara
                            {{ popOverText.cara }})</span>
                        <span *ngIf="popOverText?.cara && popOverText?.cara === 'pieza'">(pieza completa)</span>
                        <ng-container *ngIf="popOverText?.relacion?.length > 0 && showRelacion">
                            <span *ngFor="let rel of popOverText?.relacion">
                                <span *ngIf="rel?.concepto?.term"> - {{ rel.concepto.term }} ({{
                                    rel.concepto.semanticTag }})</span>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="popOverText?.relacion?.concepto && showRelacion">
                            - {{ popOverText.relacion.concepto.term }} ({{ popOverText.relacion.concepto.semanticTag
                            }})
                        </ng-container>
                    </span>
                </div>
            </div>

            <ng-container *ngFor="let cuadrante of cuadrantes">
                <div class="col-6 no-gutters {{ cuadrante }}">
                    <ng-container *ngFor="let diente of odontograma[cuadrante]; let i = index">
                        <div class="diente-container text-center">
                            <span class="text-diente"> {{ diente.concepto.term }}</span>
                            <svg *ngIf="!piezaAnulada(diente.concepto.conceptId)" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink" id="cuadrante-sup-der" width="32px"
                                 height="32px" x="0px" y="0px" baseProfile="basic" version="1.1" viewBox="0 0 55 50"
                                 xml:space="preserve">
                                <!-- DISTAL/MESIAL -->
                                <path (click)="jumpToId(getClassRegistro(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.concepto.conceptId); showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'), false, 0)"
                                      [ngClass]="{'registrada': caraValor(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')) !== -1}"
                                      d="M18.042,27.5c0-2.197,0.89-4.187,2.33-5.627l-9.339-9.339c-3.831,3.83-6.2,9.122-6.2,14.966c0,5.845,2.369,11.137,6.2,14.967l9.34-9.34C18.933,31.687,18.042,29.698,18.042,27.5z"
                                      class="diente distal {{ 'diente-' + getClassRegistro(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.concepto.semanticTag }}"
                                      title="(esCuadranteIzquierdo(cuadrante)" />
                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.length">
                                    <ng-container
                                                  *ngFor="let reldistal of getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')); let d=index">
                                        <path (click)="jumpToId(getClassRegistro(diente,(esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'))?.concepto.conceptId); showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial'), false, d);"
                                              [ngClass]="{'registrada': caraValor(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')) !== -1}"
                                              d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                              class="diente distal {{ 'diente-' + reldistal.concepto.semanticTag }}"
                                              title="(esCuadranteIzquierdo(cuadrante)" />
                                        <text *ngIf="getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')).length > 0"
                                              fill="#FFFFFF" x="8" y="31"
                                              font-size="14">{{ getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'distal' : 'mesial')).length }}</text>
                                    </ng-container>
                                </ng-container>
                                <!-- VESTIBULAR/LINGUAL -->
                                <path (click)="jumpToId(getClassRegistro(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.concepto.conceptId); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'), false, 0)"
                                      [ngClass]="{'registrada': caraValor(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')) !== -1}"
                                      d="M26,19.542c2.197,0,4.187,0.891,5.627,2.331l9.34-9.339c-3.831-3.831-9.122-6.2-14.967-6.2c-5.845,0-11.137,2.369-14.967,6.2l9.339,9.339C21.813,20.433,23.802,19.542,26,19.542z"
                                      class="diente vestibular {{ 'diente-' + getClassRegistro(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.concepto.semanticTag }}"
                                      title="(esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')" />
                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.length">
                                    <ng-container
                                                  *ngFor="let relvestibular of getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')); let v=index">
                                        <path (click)="jumpToId(getClassRegistro(diente,(esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'))?.concepto.conceptId); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual'), false, v)"
                                              [ngClass]="{'registrada': caraValor(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')) !== -1}"
                                              d="M26,19.542c2.197,0,4.187,0.891,5.627,2.331l9.34-9.339c-3.831-3.831-9.122-6.2-14.967-6.2c-5.845,0-11.137,2.369-14.967,6.2l9.339,9.339C21.813,20.433,23.802,19.542,26,19.542z"
                                              class="diente vestibular {{ 'diente-' + relvestibular.concepto.semanticTag }}"
                                              title="(esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')" />
                                        <text *ngIf="getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')).length > 0"
                                              fill="#FFFFFF" x="21" y="17"
                                              font-size="14">{{ getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'vestibular' : 'lingual')).length }}</text>
                                    </ng-container>
                                </ng-container>
                                <!-- MESIAL/DISTAL -->
                                <path (click)="jumpToId(getClassRegistro(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.concepto.conceptId); showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'), false, 0)"
                                      [ngClass]="{'registrada': caraValor(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')) !== -1}"
                                      d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                      class="diente mesial {{ 'diente-' + getClassRegistro(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.concepto.semanticTag }}"
                                      title="(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')" />
                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.length">
                                    <ng-container
                                                  *ngFor="let relMesial of getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')); let m=index">
                                        <path (click)="jumpToId(getClassRegistro(diente,(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'))?.concepto.conceptId); showTooltip(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal'), false, m)"
                                              [ngClass]="{'registrada': caraValor(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')) !== -1}"
                                              d="M40.967,12.533l-9.34,9.339c1.44,1.44,2.331,3.43,2.331,5.627c0,2.198-0.891,4.188-2.332,5.628l9.34,9.34c3.831-3.831,6.2-9.123,6.2-14.968C47.167,21.655,44.797,16.363,40.967,12.533z"
                                              class="diente mesial {{ 'diente-' + relMesial.concepto.semanticTag }}"
                                              title="(esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')" />
                                        <text *ngIf="getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')).length > 0"
                                              fill="#FFFFFF" x="37" y="32"
                                              font-size="14">{{ getRegistrosEjecucion(diente, (esCuadranteIzquierdo(cuadrante) ? 'mesial' : 'distal')).length }}</text>
                                    </ng-container>
                                </ng-container>
                                <!-- PALATINA/VESTIBULAR -->
                                <path (click)="jumpToId(getClassRegistro(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))?.concepto.conceptId); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'), false, 0)"
                                      [ngClass]="{'registrada': caraValor(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')) !== -1}"
                                      d="M26,35.458c-2.198,0-4.188-0.891-5.627-2.331l-9.34,9.34c3.831,3.831,9.122,6.2,14.967,6.2c5.845,0,11.136-2.369,14.966-6.199l-9.34-9.34C30.187,34.567,28.198,35.458,26,35.458z "
                                      class="diente palatina {{ caraValor(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')) !==- 1 ? 'diente-' + getClassRegistro(diente,(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))?.concepto.semanticTag : '' }}"
                                      title="(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')" />
                                <ng-container
                                              *ngIf="getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))?.length">
                                    <ng-container
                                                  *ngFor="let relpalatina of getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')); let p=index">
                                        <path (click)="jumpToId(getClassRegistro(diente,(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'))?.concepto.conceptId); showTooltip(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular'), false, p)"
                                              [ngClass]="{ 'registrada': caraValor(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')) !==- 1}"
                                              d="M26,35.458c-2.198,0-4.188-0.891-5.627-2.331l-9.34,9.34c3.831,3.831,9.122,6.2,14.967,6.2c5.845,0,11.136-2.369,14.966-6.199l-9.34-9.34C30.187,34.567,28.198,35.458,26,35.458z "
                                              class="diente palatina {{ 'diente-' + relpalatina.concepto.semanticTag }} "
                                              title="(esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')" />
                                        <text *ngIf="getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')).length > 0"
                                              fill="#FFFFFF" x="22" y="46"
                                              font-size="14">{{ getRegistrosEjecucion(diente, (esCuadranteInferior(cuadrante) ? 'palatina' : 'vestibular')).length }}</text>
                                    </ng-container>
                                </ng-container>
                                <!-- OCLUSAL -->
                                <path (click)="jumpToId(getClassRegistro(diente,'oclusal')?.concepto.conceptId); showTooltip(diente, 'oclusal', false, 0)"
                                      [ngClass]="{ 'registrada': caraValor(diente, 'oclusal') !==- 1} "
                                      d="M26,19.542c-2.198,0-4.188,0.891-5.628,2.331c-1.44,1.44-2.33,3.43-2.33,5.627c0,2.198,0.891,4.188,2.331,5.627s3.429,2.331,5.627,2.331c2.197,0,4.187-0.89,5.626-2.33c1.44-1.44,2.332-3.43,2.332-5.628c0-2.197-0.891-4.187-2.331-5.627C30.188,20.433,28.198,19.542,26,19.542z "
                                      class="diente oclusal {{ 'diente-' + getClassRegistro(diente, 'oclusal')?.concepto.semanticTag }} "
                                      title="oclusal" />
                                <ng-container *ngIf="getRegistrosEjecucion(diente, 'oclusal')?.length">
                                    <ng-container
                                                  *ngFor="let reloclusal of getRegistrosEjecucion(diente, 'oclusal'); let o=index">
                                        <path (click)="jumpToId(getClassRegistro(diente,'oclusal')?.concepto.conceptId); showTooltip(diente, 'oclusal', false, o)"
                                              [ngClass]="{ 'registrada': caraValor(diente, 'oclusal') !==- 1}"
                                              d="M26,19.542c-2.198,0-4.188,0.891-5.628,2.331c-1.44,1.44-2.33,3.43-2.33,5.627c0,2.198,0.891,4.188,2.331,5.627s3.429,2.331,5.627,2.331c2.197,0,4.187-0.89,5.626-2.33c1.44-1.44,2.332-3.43,2.332-5.628c0-2.197-0.891-4.187-2.331-5.627C30.188,20.433,28.198,19.542,26,19.542z "
                                              class="diente oclusal {{ 'diente-' + reloclusal.concepto.semanticTag }} "
                                              title="oclusal" />
                                        <text *ngIf="getRegistrosEjecucion(diente, 'oclusal').length > 0" fill="#FFFFFF"
                                              x="22" y="32"
                                              font-size="14">{{ getRegistrosEjecucion(diente, 'oclusal').length }}</text>
                                    </ng-container>
                                </ng-container>

                                <!-- PIEZA COMPLETA -->
                                <g class="pieza" title="pieza completa"
                                   (click)="jumpToId(getClassRegistro(diente,'pieza')?.concepto.conceptId); showTooltip(diente, 'pieza', false, 0)">
                                    <!-- Círculo MINI -->
                                    <circle class="st1"
                                            [ngClass]="piezaCompletaValor(diente, 'pieza') !== -1 ? 'diente-' + getClassRegistro(diente, 'pieza')?.concepto.semanticTag : '' "
                                            cx="46.285" cy="7.311" r="5.801" />
                                    <!-- Asterisco -->
                                    <path [ngClass]="piezaCompletaValor(diente, 'pieza') !== -1 ? 'diente-' + getClassRegistro(diente, 'pieza')?.concepto.semanticTag + '-outline' : '' "
                                          d="M50.668,5.782l-2.925,1.534l2.925,1.542l-0.674,1.25l-2.951-1.631v3.049h-1.507V8.477l-2.961,1.631l-0.674-1.25l2.961-1.542l-2.961-1.534l0.674-1.25l2.961,1.613V3.096h1.507v3.049l2.951-1.613L50.668,5.782z " />
                                </g>
                                <ng-container *ngIf="getRegistrosEjecucion(diente, 'pieza')?.length">
                                    <ng-container
                                                  *ngFor="let relClass of getRegistrosEjecucion(diente, 'pieza'); let a=index">
                                        <g (click)="showTooltip(diente, 'pieza', false, a)" class="pieza"
                                           title="pieza completa">
                                            <!-- Círculo MINI -->
                                            <circle *ngIf="relClass?.concepto?.semanticTag" cx="46.285" cy="7.311"
                                                    r="5.801" class="st1"
                                                    [ngClass]="'diente-' + relClass.concepto.semanticTag + ' rotate-'+ a" />
                                            <path [ngClass]="'diente-' + getClassRegistro(diente,'pieza')?.concepto.semanticTag"
                                                  d="M50.668,5.782l-2.925,1.534l2.925,1.542l-0.674,1.25l-2.951-1.631v3.049h-1.507V8.477l-2.961,1.631l-0.674-1.25l2.961-1.542l-2.961-1.534l0.674-1.25l2.961,1.613V3.096h1.507v3.049l2.951-1.613L50.668,5.782z " />
                                            <text *ngIf="getRegistrosEjecucion(diente, 'pieza').length > 0"
                                                  fill="#FFFFFF" x="43" y="12"
                                                  font-size="14">{{ getRegistrosEjecucion(diente, 'pieza').length }}</text>
                                        </g>
                                        <!-- Círculo GRANDE -->
                                        <circle cx="26" cy="27.5" r="22.246"
                                                [ngClass]=" 'diente-' + relClass.concepto.semanticTag+ '-outline'" />
                                    </ng-container>
                                </ng-container>
                            </svg>
                            <svg *ngIf="piezaAnulada(diente.concepto.conceptId)" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink" id="cuadrante-sup-der" width="32px"
                                 height="32px" x="0px" y="0px" baseProfile="basic" version="1.1 " viewBox="0 0 55 50"
                                 xml:space="preserve">
                                <g (click)="showTooltip(diente, 'anulada')" (mouseleave)="hideTooltip()">
                                    <circle class="pieza completa pieza-anulada" cx="26" cy="27.5" r="22.246">
                                    </circle>
                                    <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" stroke-width="3" x1="8"
                                          x2="44" y1="10" y2="44"></line>
                                    <line fill="none" stroke="#FFFFFF" stroke-width="3" stroke-miterlimit="10" x1="8"
                                          x2="44" y1="44" y2="10" />
                                </g>
                            </svg>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>
    </div>

</ng-container>