<ng-container *ngIf="!soloValores">
    <div class="row">
        <div class="col-md">
            <plex-text label="Diagnóstico/Motivo" multiline="true" [autoFocus]="true"
                       [(ngModel)]="registro.valor.solicitudPrestacion.motivo" name="motivo"></plex-text>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <plex-text label="Indicaciones" height=100 [html]="true"
                       [(ngModel)]="registro.valor.solicitudPrestacion.indicaciones" name="indicaciones">
            </plex-text>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <plex-select *ngIf="organizaciones.length || registro.valor.solicitudPrestacion.organizacionDestino"
                         [(ngModel)]="registro.valor.solicitudPrestacion.organizacionDestino" name="organizacionDestino"
                         [data]="organizaciones" label="Organización destino" placeholder="Seleccione la organización"
                         labelField="nombre" [required]="true" (change)="onOrganizacionChange()">
            </plex-select>

        </div>
    </div>
    <div class="row" *ngIf="!organizaciones.length">
        <div class="col-md">
            <hr />
            <plex-label *ngIf="!organizaciones.length && !registro.valor.solicitudPrestacion.organizacionDestino"
                        titulo="Esta solicitud NO se regitrará en ninguna bandeja de entrada y no podrá ser resuelta desde el módulo de solicitudes"
                        subtitulo="" type="warning" size="md" icon="alert"></plex-label>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <plex-bool [(ngModel)]="registro.valor.solicitudPrestacion.informe" name="informe" label="Requiere informe"
                       *ngIf="reglaSelected?.destino.informe === 'optional'">
            </plex-bool>
        </div>
    </div>
    <ng-container *ngIf="registro.valor.template">
        <lib-form-builder #template [template]="registro.valor.template" [initial]="registro.valor.solicitudPrestacion">
        </lib-form-builder>
    </ng-container>
</ng-container>
<ng-container *ngIf="soloValores">
    <div class="row">
        <div class="col-md">
            <plex-label titulo="Diagnóstico/Motivo"
                        subtitulo="{{registro.valor.solicitudPrestacion.motivo || 'Sin diagnóstico'}}">
            </plex-label>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <label>Indicaciones</label>
            <span [innerHTML]="registro.valor.solicitudPrestacion.indicaciones || 'Sin indicación'"></span>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <plex-label *ngIf="registro.valor?.solicitudPrestacion?.organizacionDestino?.id"
                        titulo="Organización a la cual se solicita"
                        subtitulo="{{registro.valor.solicitudPrestacion.organizacionDestino.nombre}}"></plex-label>
        </div>
    </div>
    <div class="row" *ngIf="!registro.valor.solicitudPrestacion.organizacionDestino">
        <div class="col-md">
            <plex-label *ngIf="!registro.valor.solicitudPrestacion.organizacionDestino"
                        titulo="Esta solicitud NO se regitrará en ninguna bandeja de entrada y no podrá ser resuelta desde el módulo de solicitudes"
                        subtitulo="" type="warning" size="md" icon="alert"></plex-label>
        </div>
    </div>
    <ng-container *ngIf="registro.valor.template">
        <lib-form-builder #template [template]="registro.valor.template" [initial]="registro.valor.solicitudPrestacion"
                          [readonly]="true">
        </lib-form-builder>
    </ng-container>
</ng-container>