<plex-modal #modal (closed)="notificarAccion(false)">
    <plex-icon name="send-circle" type="info"></plex-icon>
    <plex-modal-title type="info">Enviar procedimiento por email</plex-modal-title>
    <plex-modal-subtitle type="center">Seleccione un servicio de la organización <br>deseada para completar el envío
    </plex-modal-subtitle>
    <main>
        <section class="w-75">
            <plex-select [(ngModel)]="emailSelected" [data]="emails" idField="email" labelField="nombre"
                         placeholder="Seleccione un servicio">
            </plex-select>
            <br>
            <plex-bool label="Enviar adjuntos" [(ngModel)]="adjuntos" name="adjuntos"> </plex-bool>
        </section>
    </main>

    <plex-button modal left type="danger" (click)="notificarAccion(false)">
        CANCELAR
    </plex-button>
    <plex-button modal right type="success" (click)="notificarAccion(true)" [disabled]="emailSelect()">
        ENVIAR
    </plex-button>
</plex-modal>