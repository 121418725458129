<plex-layout>
    <plex-layout-main>
        <header justify responsive>
            <div class="marca">
                <div svgLogo></div>
                <div svgAcronimo></div>
                <div class="provincia">
                    <div>
                        {{ provincia }}
                    </div>
                </div>
            </div>
            <div class="bienvenida">
                <div class="efector">
                    {{ auth.organizacion.nombre }}
                </div>
                <div class="hola">
                    Hola <b>{{ auth.usuario.nombre }}</b>
                </div>
                <div *ngIf="!denied" class="mensaje">
                    ¿En qué vas a trabajar hoy?
                </div>
                <div *ngIf="denied" class="mensaje">
                    ¡Bienvenida/o a ANDES!
                </div>
            </div>

        </header>
        <hr>
        <main *ngIf="!denied && !loading" responsive>
            <plex-loader *ngIf="loading" type="linear"></plex-loader>
            <div class="modulos">
                <ng-container *ngFor="let modulo of modulos">

                    <!-- Módulo principal -->
                    <div *ngIf="modulo.activo" class="modulo" [class.auto-height]="modulo.submodulos?.main?.length >= 4">
                        <div class="principal" [style.color]="modulo.color">
                            <b class="titulo">{{ modulo.nombre }}
                                <a href="#" *ngIf="novedades[modulo._id]?.length > 0" hintType="info"
                                    hint="Novedades del módulo {{ modulo.nombre }}" detach="both" hintIcon="bell"
                                    (click)="irANovedades(modulo._id, $event)"></a>
                            </b>
                            <div class="descripcion">{{ modulo.descripcion }}</div>
                        </div>
                        <!-- Permisos en módulo y submódulos -->
                        <div class="submodulos d-flex align-items-start" *ngIf="modulo?.submodulos?.main?.length">
                            <ng-container *ngFor="let submodulo of modulo.submodulos.main">
                                <a href="#" *ngIf="submodulo.activo" class="submodulo {{submodulo.class}} hover"
                                    [class.mini]="modulo.submodulos.main.length >= 4"
                                    [style.background]="submodulo.color" (click)="redirect(submodulo, $event)">
                                    <plex-icon [name]="submodulo.icono" size="lg"></plex-icon>
                                    <div class="titulo" [innerHTML]="submodulo.nombre"></div>
                                </a>
                            </ng-container>
                        </div>
                        <div class="submodulos d-flex align-items-start" *ngIf="modulo?.submodulos?.secondary?.length">
                            <ng-container *ngFor="let submodulo of modulo.submodulos.secondary">
                                <a href="#" *ngIf="submodulo.activo" class="submodulo {{submodulo.class}} hover"
                                    [class.mini]="modulo.submodulos.main.length >= 4"
                                    [style.background]="submodulo.color" (click)="redirect(submodulo, $event)">
                                    <plex-icon [name]="submodulo.icono" size="lg"></plex-icon>
                                    <div class="titulo" [innerHTML]="submodulo.nombre"></div>
                                </a>
                            </ng-container>
                        </div>
                        <!-- Sólo con permisos en submódulos -->
                        <div class="submodulos d-flex align-items-start" *ngIf="!modulo?.submodulos?.length">
                            <a href="#" *ngIf="modulo.activo" class="submodulo hover" [style.background]="modulo.color"
                                (click)="redirect(modulo, $event)">
                                <plex-icon [name]="modulo.icono" size="lg"></plex-icon>
                                <div class="titulo" [innerHTML]="modulo.nombreSubmodulo"></div>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="secciones">
                <ng-container *ngFor="let seccion of secciones">
                    <a href="#" class="seccion hover" (click)="redirect(seccion, $event)">
                        <div class="principal" [style.background]="seccion.color">
                            <div class="band"></div>
                            <plex-icon class="main" [name]="seccion.icono" size="lg"></plex-icon>
                            <plex-icon class="animated" name="chevron-right" size="lg"></plex-icon>
                        </div>
                        <span class="hover" *ngIf="novedades[seccion._id]?.length > 0" hintType="info"
                            hint="Novedades del módulo {{ seccion.nombre }}" detach="top" hintIcon="bell"
                            (click)="irANovedades(seccion._id, $event)"></span>
                        <span [class.ml-2]="novedades[seccion._id]?.length === 0"></span>
                        <div class="secundario">
                            <div class="titulo ml-2">{{ seccion.nombre }}</div>
                            <div class="subtitulo ml-2">{{ seccion.subtitulo }}</div>
                        </div>
                    </a>
                </ng-container>
            </div>
        </main>
        <div *ngIf="denied && !loading" class="denegado" justify="center">
            <main role="main" class="inner">
                <plex-label icon="llave" type="warning" size="xl" direction="column"
                    titulo="No tenés permisos para ningún módulo de ANDES"
                    subtitulo="Ponete en contacto con el informático local o coordinador de área">
                </plex-label>
            </main>
        </div>
    </plex-layout-main>
</plex-layout>
