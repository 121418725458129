<ng-container *ngIf="paciente">
    <plex-button *ngIf="hudsPermiso && accesoHuds" class="float-right mt-4 mr-1" right="true" type="info" size="sm"
                 tooltip="Ver HUDS del paciente" tooltipPosition="left" icon="historial" (click)="showMotivoAcceso()">
    </plex-button>
    <plex-detail [direction]="orientacion === 'vertical' ? 'column' : 'row'" [size]="size" [items]="datos"
                 [justify]="justificado">
        <img [ngClass]="{'img-fallecido' : paciente?.fechaFallecimiento}" [mpiFotoPaciente]=" paciente" />

        <plex-badge *ngIf="paciente.estado" [type]="estadoBadgeType">
            {{ paciente.estado | uppercase }}
        </plex-badge>
        <plex-badge *ngIf="showDatosTutor()" type="warning"
                    hint="Recién Nacido, no se encuentra registrado en una fuente auténtica" icon="help">
            Sin DNI
        </plex-badge>
        <plex-badge *ngIf="paciente.fechaFallecimiento" type="danger">
            Fallecido: {{ paciente.fechaFallecimiento | fecha}}
        </plex-badge>

        <ng-content select="plex-badge" ngProjectAs="plex-badge"></ng-content>

        <div title>{{ paciente | nombre }}</div>

        <div subtitle>
            <plex-copy *ngIf="paciente.documento" [value]="paciente.documento">{{ paciente.documento | number }}
            </plex-copy>
            <plex-copy *ngIf="paciente.numeroIdentificacion" [value]="paciente.numeroIdentificacion">
                {{ paciente.numeroIdentificacion }}
            </plex-copy>
            <plex-button *ngIf="puedeEditar" type="warning" icon="pencil" tooltip="Editar paciente" size="sm"
                         (click)="editar()">
            </plex-button>
            <ng-container *ngIf="!this.paciente.numeroIdentificacion && !this.paciente.documento">

                <div *ngIf="showDatosTutor()" justify="center">
                    <plex-label *ngIf="relaciones[0].parentesco" titulo="" subtitulo="">
                        {{relaciones[0].parentesco.toUpperCase()}}
                    </plex-label>
                    <plex-copy *ngIf="relaciones[0].documento || relaciones[0].numeroIdentificacion"
                               [value]="relaciones[0].documento || relaciones[0].numeroIdentificacion">
                        {{ (relaciones[0].documento | number) || relaciones[0].numeroIdentificacion}}
                    </plex-copy>
                    <span *ngIf="!relaciones[0].numeroIdentificacion && !relaciones[0].documento">: Sin DNI</span>
                </div>

                <plex-label *ngIf="showDatosTutor()" titulo="" subtitulo=""
                            [direction]="orientacion === 'vertical' ? 'column' : 'row'">{{relaciones[0] | nombre}}
                </plex-label>

                <span *ngIf=" !showDatosTutor()">Sin DNI</span>
            </ng-container>
        </div>

        <plex-label *ngIf="showSexo" titulo="Sexo" subtitulo="{{ paciente.sexo }}"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showSexo" titulo="Identidad de género" subtitulo="{{ paciente.genero }}"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showFechaNacimiento" titulo="Fecha de Nacimiento"
                    subtitulo="{{ (paciente.fechaNacimiento | fecha) || 'Desconocida' }}"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showEdad" titulo="Edad" subtitulo="{{ (paciente | edad) || 'Desconocida' }}"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showCuil" titulo="CUIL" subtitulo="{{ paciente.cuil || 'Sin cuil' }}"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showFinanciador && obraSocial" [titulo]="financiadorLabel"
                    subtitulo="{{ obraSocial.financiador}}" [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="!obraSocial" [titulo]="financiadorLabel" subtitulo="{{ 'Sin obra social' }}"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showNumeroAfiliado && numeroAfiliado" titulo="Número de Afiliado"
                    [subtitulo]="numeroAfiliado" [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showLugarNacimiento" titulo="Lugar de Nacimiento"
                    [subtitulo]="lugarNacimiento || 'No indica'"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showDireccion" titulo="Dirección" [subtitulo]="direccion || 'Sin dirección'"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

        <plex-label *ngIf="showTelefono" titulo="Contacto" [subtitulo]="contacto"
                    [direction]="orientacion === 'vertical' ? 'column' : 'row'">
        </plex-label>

    </plex-detail>

    <!-- Se muestran las notas destacadas de un paciente -->
    <plex-title titulo=" Notas" size="sm" *ngIf="notasDestacadas.length">
    </plex-title>

    <ng-container *ngFor="let n of notasDestacadas">
        <plex-label *ngIf="n.destacada" titulo="{{ n.titulo || 'Sin titulo' }}" [subtitulo]="n.nota" grow="4">
        </plex-label>
    </ng-container>

</ng-container>

<!-- Propuesta para mostrar otros datos preliminares -->
<ng-container *ngIf="showRelaciones">
    <plex-title titulo="Relaciones" size="sm"></plex-title>
    <plex-list>
        <plex-label *ngIf="!relaciones?.length" titulo="No hay ninguna relación registrada" subtitulo=""></plex-label>
        <ng-container *ngIf="relaciones?.length">
            <paciente-relaciones [relaciones]="paciente.relaciones"> </paciente-relaciones>
        </ng-container>
    </plex-list>
</ng-container>

<ng-container *ngIf="documentacionPermiso && showDocumentos && paciente.documentos?.length > 0">
    <plex-title titulo="Documentos adjuntos" size="sm"></plex-title>
    <plex-accordion>
        <plex-panel *ngFor="let item of paciente.documentos">

            <div plex-accordion-title class="d-flex justify-content-between align-items-center">
                <span class="d-flex">
                    <plex-icon name="documento" type="info" size="xl"></plex-icon>
                    <plex-label [titulo]="item.tipo.label"></plex-label>
                </span>
                <span>
                    <plex-badge type="info">
                        {{ item.fecha | fecha: 'utc'}}
                        {{item.fecha | date:'HH:mm'}}
                    </plex-badge>
                </span>
            </div>
            <shared-galeria-archivos [readonly]="true" [files]="item.archivos | galeria | async">
            </shared-galeria-archivos>
        </plex-panel>
    </plex-accordion>

</ng-container>