<form #form="ngForm">
    <plex-title titulo="Solicitud de prestamo de Carpeta" size="md"></plex-title>

    <plex-text label="Paciente" [(ngModel)]="paciente.apellido + ' ' + paciente.alias || paciente.nombre + ' - ' + paciente.documento || paciente.numeroIdentificacion"
               name="paciente" [disabled]="true" [required]="true">
    </plex-text>
    <plex-text label="Nro Carpeta" *ngIf="carpetaEfector" [(ngModel)]="carpetaEfector.nroCarpeta" name="nro-carpeta"
               [disabled]="true" [required]="true">
    </plex-text>
    <plex-select [(ngModel)]="tipoPrestacion" tmPrestaciones preload="true" name="prestaciones" label="Prestación"
                 ngModelOptions="{standalone: true}">
    </plex-select>
    <plex-select [(ngModel)]="espacioFisico" name="espacioFisico" (getData)="loadEspacios($event)"
                 label="Espacio Físico" placeholder="Buscar un espacio físico"
                 labelField="nombre + '(' + servicio.nombre + ')'" ngModelOptions="{standalone: true}">
    </plex-select>
    <plex-select [(ngModel)]="profesional" name="profesional" (getData)="loadProfesionales($event)" label="Profesional"
                 placeholder="Escriba el apellido del Profesional" labelField="apellido + ' ' + nombre">
    </plex-select>
    <plex-text [(ngModel)]="observaciones" label="Observaciones" name="observaciones"
               placeholder="Ingrese observaciones...">
    </plex-text>

    <div class="pt-2">
        <plex-button class="float-left" label="Cancelar" type="danger" (click)="cancel()">
        </plex-button>

        <plex-button class="float-right" label="Guardar" type="success" (click)="save()" validateForm="true">
        </plex-button>
    </div>
</form>