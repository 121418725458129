<small *ngIf="!agenda.nominalizada"><i>*Actividad no nominalizada, no se dan turnos</i></small>
<div *ngIf="showTurnos && agenda.nominalizada">

    <plex-options *ngIf="items.length>1 && !agenda.dinamica" [items]="items" (activated)="cambiarOpcion($event)">
    </plex-options>
    <!-- Tiene turnos disponibles -->
    <ng-container *ngIf="!agenda.dinamica">
        <plex-title size="sm" titulo="Turnos disponibles para este bloque"></plex-title>
        <plex-grid class="turnos_disponibles" responsive type="full">
            <plex-label align="center" size="md" titulo="Del día" subtitulo="{{countBloques[mostrar]?.delDia}}">
            </plex-label>
            <plex-label align="center" size="md" titulo="Programadoss"
                        subtitulo="{{countBloques[mostrar]?.programado}}">
            </plex-label>
            <plex-label align="center" size="md" titulo="Con llave" subtitulo="{{countBloques[mostrar]?.gestion}}">
            </plex-label>
            <plex-label align="center" size="md" titulo="Profesional"
                        subtitulo="{{countBloques[mostrar]?.profesional}}">
            </plex-label>
        </plex-grid>
    </ng-container>
    <plex-title class="titulo-bloque" size="sm"
                titulo="BLOQUE | {{ bloqueSelected.horaInicio | date: 'HH:mm'}} a {{ bloqueSelected.horaFin |date:'HH:mm'}} hs">
        <ng-container *ngIf="agenda | botonesTurnos:turnosSeleccionados as botones">
            <plex-button *ngIf="botones.sacarAsistencia && turnosSeleccionados?.length" class="mr-1"
                         ariaLabel="Registrar inasistencia" size="sm" type="warning" icon="account-remove"
                         (click)="eventosTurno('sacarAsistencia')" tooltip="Registrar inasistencia"
                         tooltipPosition="top">
            </plex-button>
            <plex-button *ngIf="botones.darAsistencia && turnosSeleccionados?.length" class="mr-1"
                         ariaLabel="Registrar asistencia" size="sm" type="success" icon="account-check"
                         (click)="eventosTurno('darAsistencia')" tooltip="Registrar asistencia" tooltipPosition="top">
            </plex-button>

            <plex-help *ngIf="botones.nota && turnosSeleccionados?.length" class="mr-1" type="info"
                       icon="comment-outline" tooltip="Agregar nota" tooltipPosition="top">
                <agregar-nota-turno [agenda]="agenda" [turnosSeleccionados]="turnosSeleccionados"
                                    (saveAgregarNotaTurno)="saveAgregarNotaTurno()">
                </agregar-nota-turno>
            </plex-help>
            <plex-help *ngIf="botones.suspender && turnosSeleccionados?.length" class="mr-1" btnType="danger"
                       icon="stop" tooltip="Suspender turnos" tooltipPosition="left">
                <suspender-turno [agenda]="agenda" [accion]="'suspenderTurno'"
                                 [turnosSeleccionados]="turnosSeleccionados" (saveSuspenderTurno)="saveSuspenderTurno()"
                                 (reasignarTurnoSuspendido)="reasignarTurnoSuspendido($event)">
                </suspender-turno>
            </plex-help>
            <plex-button *ngIf="botones.cambiarDisponible  && turnosSeleccionados?.length" class="mr-1" type="warning"
                         size="sm" icon="undo" tooltip="Cambiar a disponible" tooltipPosition="top"
                         (click)="cambiarADisponible()">

            </plex-button>


        </ng-container>
        <plex-help *ngIf="!agenda.dinamica" type="help" icon="cog">
            <plex-title titulo="Prestaciones asociadas" size="sm"></plex-title>
            <plex-label titulo="{{prestacionTerm}}"></plex-label>
            <plex-title titulo="Configuración inicial del bloque" size="sm"></plex-title>
            <div class="row">
                <div class="col-12">
                    <div class="container mt-2 mb-2">
                        <div class="row">
                            <div class="col d-flex align-items-start px-2">
                                <span>
                                    <h2 class="text-info">
                                        {{bloqueSelected.accesoDirectoDelDia ? bloqueSelected.accesoDirectoDelDia :
                                        0}}
                                    </h2>
                                    <span class="small">Turnos <br><b>del día</b></span>
                                </span>
                            </div>
                            <div class="col d-flex align-items-start px-2">
                                <span>
                                    <h2 class="text-info">
                                        {{bloqueSelected.accesoDirectoProgramado ?
                                        bloqueSelected.accesoDirectoProgramado : 0}}
                                    </h2>
                                    <span class="small">Turnos <br><b>programados</b></span>
                                    <small *ngIf="bloqueSelected.cupoMobile"><br>
                                        &nbsp;({{bloqueSelected.cupoMobile}} virtuales)
                                    </small>
                                </span>
                            </div>
                            <div class="col d-flex align-items-start px-2">
                                <span>
                                    <h2 class="text-info">
                                        {{bloqueSelected.reservadoProfesional ? bloqueSelected.reservadoProfesional
                                        :
                                        0}}
                                    </h2>
                                    <span class="small">Turnos <br><b>profesional</b></span>
                                </span>
                            </div>
                            <div class="col d-flex align-items-start px-2">
                                <span>
                                    <h2 class="text-info">
                                        {{bloqueSelected.reservadoGestion ? bloqueSelected.reservadoGestion : 0}}
                                    </h2>
                                    <span class="small">Turnos <br><b>con llave</b></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </plex-help>
    </plex-title>
    <div class="py-1 d-flex align-items-center" *ngIf="agenda.nominalizada">
        <span>Descripción: {{bloqueSelected.descripcion?bloqueSelected.descripcion:'-'}}</span>
    </div>
    <div class="py-2 d-flex align-items-center" *ngIf="showSeleccionarTodos">
        <i *ngIf="this.todos" class="mdi mdi-checkbox-marked hover" (click)="seleccionarTodos()"></i>
        <i *ngIf="!this.todos" class="mdi mdi-checkbox-blank-outline hover" (click)="seleccionarTodos()"></i>
        Seleccionar Todo
    </div>
    <!--Listado de turnos-->
    <plex-table *ngIf="bloqueSelected.turnos.length" [columns]="columns" #table2="plTable" class="tabla-turnos">
        <plex-table-columns></plex-table-columns>
        <tr *ngFor="let turno of bloqueSelected.turnos; let i=index"
            [class.selected]="estaSeleccionado(bloqueSelected.turnos[i])" [class.selectable]="selectable">
            <td *plTableCol="'seleccionar'" (click)="seleccionarTurno(turno, true, false)">
                <i *ngIf="estaSeleccionado(bloqueSelected.turnos[i])" class="mdi mdi-checkbox-marked"></i>
                <i *ngIf="!estaSeleccionado(bloqueSelected.turnos[i])" class="mdi mdi-checkbox-blank-outline"></i>
            </td>
            <td *plTableCol="'datosPrincipales'" (click)="seleccionarTurno(bloqueSelected.turnos[i],false, false)">
                <div class="d-flex flex-column align-items-start ml-1">
                    <div class="d-flex flex-rows">
                        <plex-badge *ngIf="turno?.estado !== 'disponible' && turno?.estado !== 'turnoDoble'" size="sm"
                                    type="default">
                            <shared-popover-audit placement="right" [data]="turno" [showUpdate]="true">
                            </shared-popover-audit>
                        </plex-badge>
                        <plex-badge *ngIf="turno.tipoTurno === 'profesional' && turno.estado === 'asignado'"
                                    type="warning">
                            Autocitado</plex-badge>
                        <plex-badge *ngIf="turno.tipoTurno === 'gestion' && turno.estado === 'asignado'" type="warning">
                            Con
                            llave
                        </plex-badge>
                        <plex-badge *ngIf="turno.tipoTurno === 'programado' && turno.estado === 'asignado'"
                                    type="warning">
                            Programado</plex-badge>
                        <plex-badge *ngIf="turno.tipoTurno === 'delDia' && turno.estado === 'asignado'" type="success">
                            Del
                            día
                        </plex-badge>
                        <plex-badge *ngIf="turno?.estado === 'disponible'" type="info">Disponible</plex-badge>
                        <plex-badge *ngIf="turno?.estado === 'turnoDoble'" type="danger">Turno Doble</plex-badge>
                        <plex-badge *ngIf="turno?.paciente?.id && turno?.estado === 'suspendido'" type="danger">
                            Suspendido</plex-badge>
                        <plex-badge *ngIf="!turno?.paciente?.id && turno?.estado === 'suspendido'" type="danger">
                            suspendido(sin paciente)</plex-badge>
                        <plex-badge *ngIf="turno?.nota" size="sm" type="info" tooltip="{{turno.nota}}"
                                    tooltipPosition="right">
                            <plex-icon name="message"></plex-icon>
                        </plex-badge>
                    </div>
                    <plex-label *ngIf="!agenda.dinamica && !turno?.paciente?.id" size="sm"
                                titulo="{{ turno.horaInicio | date: 'HH:mm' }}hs">
                    </plex-label>
                    <plex-label *ngIf="turno?.paciente?.id" size="sm"
                                titulo="{{turno.horaInicio | date: 'HH:mm' }}hs | {{turno.paciente | nombre }}">
                    </plex-label>
                    <small>
                        <span *ngIf="turno?.paciente?.id && turno.paciente.documento !== ''">
                            DNI {{ turno.paciente.documento }}</span>
                        <span *ngIf="turno?.paciente?.id && turno.paciente.documento === ''">
                            Sin documento (temporal)
                        </span>
                        <span *ngIf="turno.estado ==='asignado' && turno.paciente.carpetaEfectores">
                            <span *ngFor="let carpeta of turno.paciente.carpetaEfectores">
                                <span *ngIf="carpeta.organizacion?._id == idOrganizacion && carpeta.nroCarpeta!==null">
                                    | Nro Carpeta {{carpeta.nroCarpeta}} </span>
                            </span>
                        </span>
                    </small>
                </div>
            </td>
            <td *plTableCol="'acciones'">
                <plex-badge *ngIf="turno?.paciente?.id && turno?.estado === 'suspendido' && turno.reasignado?.siguiente"
                            class="mr-1" size="sm" type="info" tooltip="Ya reasignado" tooltipPosition="left">
                    <plex-icon name="account-check"></plex-icon>
                </plex-badge>
                <plex-badge *ngIf="turno?.paciente?.id && turno.asistencia === 'asistio' && turno.estado !== 'suspendido'"
                            class="mr-1" size="sm" type="success" tooltip="Asistió" tooltipPosition="left">
                    <plex-icon name="account-check"></plex-icon>
                </plex-badge>
                <plex-badge *ngIf="turno?.paciente?.id && turno.asistencia === 'noAsistio' && turno.estado !== 'suspendido'"
                            class="mr-1" size="sm" type="danger" tooltip="No asistió" tooltipPosition="left">
                    <plex-icon name="account-remove"></plex-icon>
                </plex-badge>
                <plex-badge *ngIf="turno?.paciente?.id && turno.confirmedAt" class="mr-1" title="Confirmó asistencia">
                    <plex-icon name="emoticon-happy"></plex-icon>
                </plex-badge>
                <plex-badge *ngIf="turno?.paciente?.id && turno?.estado === 'suspendido' && !turno.reasignado?.siguiente"
                            class="mr-1" size="sm" type="danger" tooltip="Aun no reasignado" tooltipPosition="left">
                    <plex-icon name="account-alert"></plex-icon>
                </plex-badge>
                <ng-container *ngIf="agenda | botonesTurnos:turnosSeleccionados as botones">
                    <plex-button *ngIf="botones.turnoDoble && turnosSeleccionados[0]?.id === turno.id"
                                 ariaLabel="Turno doble" size="sm" type="default" icon="numeric-2-box-multiple-outline"
                                 (click)="asignarTurnoDoble('darTurnoDoble')" tooltip="Turno doble"
                                 tooltipPosition="top"></plex-button>
                    <plex-button *ngIf="botones.quitarTurnoDoble && turnosSeleccionados[0]?.id === turno.id"
                                 ariaLabel="Quitar Turno doble" size="sm" type="default" icon="numeric-1-box-outline"
                                 (click)="eventosTurno('quitarTurnoDoble', agenda)" tooltip="Quitar Turno doble"
                                 tooltipPosition="top">
                    </plex-button>
                    <plex-help *ngIf="botones.editarCarpeta && turnosSeleccionados[0]?.id === turno.id" type="info"
                               btnType="info" icon="folder-account" tooltip="Editar Carpeta" tooltipPosition="top">
                        <plex-title size="sm" titulo="Editar N° de carpeta del paciente"></plex-title>
                        <plex-label class="text-white" titulo="Paciente"
                                    subtitulo="{{turnosSeleccionados[0].paciente | nombre }}" icon="paciente">
                        </plex-label>
                        <carpeta-paciente class="text-white" [turnoSeleccionado]="turnosSeleccionados[0] "
                                          (guardarCarpetaEmit)="afterComponenteCarpeta($event)
                                " (cancelarCarpetaEmit)="afterComponenteCarpeta($event) ">
                        </carpeta-paciente>
                    </plex-help>
                    <plex-button *ngIf="botones.sms && turnosSeleccionados[0]?.id === turno.id"
                                 ariaLabel="Enviar un mensaje al paciente" size="sm" type="info" icon="email-outline"
                                 (click)="enviarSMS()" tooltip="Enviar un mensaje al paciente" tooltipPosition="top">
                    </plex-button>
                    <plex-help *ngIf="botones.liberar && turnosSeleccionados[0]?.id === turno.id"
                               class="help-liberar-turnos" type="info" btnType="danger" icon="account-off"
                               tooltip="Liberar turnos" tooltipPosition="left">
                        <liberar-turno [agenda]="agenda" [turnosSeleccionados]="turnosSeleccionados"
                                       [desdeAgenda]="true" (saveLiberarTurno)="saveLiberarTurno($event)"
                                       (reasignarTurnoLiberado)="reasignarTurnoLiberado($event)"
                                       (cancelaLiberarTurno)="cancelaLiberarTurno()">
                        </liberar-turno>
                    </plex-help>
                </ng-container>
            </td>
        </tr>
    </plex-table>
</div>
<!--Sobreturnos-->
<div *ngIf="agenda.sobreturnos && agenda.sobreturnos.length">
    <plex-title titulo="SOBRETURNOS" size="sm"></plex-title>
    <plex-table [columns]="columns" #table2="plTable" class="tabla-sobreturnos">
        <plex-table-columns></plex-table-columns>

        <tr *ngFor="let sobreturno of agenda.sobreturnos; let i=index"
            [class.selected]="estaSeleccionado(agenda.sobreturnos[i])" [class.selectable]="selectable">
            <td *plTableCol="'seleccionar'" (click)="seleccionarTurno(sobreturno, true, true)">
                <i *ngIf="estaSeleccionado(agenda.sobreturnos[i])" class="mdi mdi-checkbox-marked"></i>
                <i *ngIf="!estaSeleccionado(agenda.sobreturnos[i])" class="mdi mdi-checkbox-blank-outline"></i>
            </td>

            <td *plTableCol="'datosPrincipales'" (click)="seleccionarTurno(sobreturno,false, true)">
                <div class="d-flex flex-column align-items-start ml-1">
                    <div class="d-flex flex-rows">
                        <plex-badge *ngIf="sobreturno?.estado !== 'disponible' && sobreturno?.estado !== 'turnoDoble'"
                                    size="sm" type="default">
                            <shared-popover-audit placement="right" [data]="sobreturno" [showUpdate]="true">
                            </shared-popover-audit>
                        </plex-badge>
                        <plex-badge *ngIf="sobreturno && sobreturno.estado === 'disponible'" type="info">
                            Disponible
                        </plex-badge>
                        <plex-badge *ngIf="sobreturno && sobreturno.estado === 'suspendido'" type="danger">
                            Suspendido</plex-badge>
                        <plex-badge *ngIf="sobreturno?.nota" size="sm" type="warning" tooltip="{{sobreturno.nota}}"
                                    tooltipPosition="right">
                            <plex-icon name="message"></plex-icon>
                        </plex-badge>
                    </div>
                    <plex-label *ngIf="sobreturno?.paciente?.id" size="md"
                                titulo="{{sobreturno.horaInicio | date: 'HH:mm' }}hs | {{sobreturno.paciente | nombre }}">
                    </plex-label>
                    <small>
                        <span *ngIf="sobreturno?.paciente?.id && sobreturno.paciente.documento !== ''">
                            DNI {{ sobreturno.paciente.documento }}</span>
                        <span *ngIf="sobreturno?.paciente?.id && sobreturno.paciente.documento === ''">
                            Sin documento
                        </span>
                        <span *ngIf="sobreturno?.paciente?.id && sobreturno.paciente.carpetaEfectores?.length > 0">
                            <span *ngFor="let carpeta of sobreturno.paciente.carpetaEfectores">
                                <span *ngIf="carpeta.organizacion?._id == idOrganizacion && carpeta.nroCarpeta!==null">
                                    | Nro Carpeta {{carpeta.nroCarpeta}} </span>
                            </span>
                        </span>
                    </small>
                </div>
            </td>

            <td *plTableCol="'acciones'">
                <plex-badge *ngIf="sobreturno?.paciente?.id && sobreturno.asistencia === 'asistio' && sobreturno.estado !== 'suspendido'"
                            size="sm" type="success" tooltip="Asistió" tooltipPosition="left">
                    <plex-icon name="account-check"></plex-icon>
                </plex-badge>
                <plex-badge *ngIf="sobreturno?.paciente?.id && sobreturno.asistencia === 'noAsistio' && sobreturno.estado !== 'suspendido'"
                            size="sm" type="danger" tooltip="No asistió" tooltipPosition="left">
                    <plex-icon name="account-remove"></plex-icon>
                </plex-badge>
            </td>
        </tr>
    </plex-table>
</div>