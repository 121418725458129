<div>
    <div *ngIf="!showLiberarTurno && !showMotivoConsulta">
        <!-- El paciente no tiene turnos -->
        <h5 *ngIf="!turnosPaciente || turnosPaciente?.length === 0">
            No hay turnos registrados.
        </h5>

        <!-- El paciente tiene turnos -->
        <fieldset>
            <ul class="list-group">
                <li *ngFor="let turno of turnosPaciente  let indiceTurno = index" class="list-group-item"
                    [appHover]="'border-info blue-border'">
                    <div class="list-group-item-text">
                        <div class="row">
                            <div class="col-md-6">

                                <div>
                                    <plex-badge *ngIf="turno.asistencia === 'asistio' && turno.estado !== 'suspendido'"
                                                type="success">ASISTIÓ</plex-badge>
                                    <plex-badge *ngIf="turno.asistencia === 'noAsistio' && turno.estado !== 'suspendido'"
                                                type="danger">NO ASISTIÓ</plex-badge>
                                    <plex-badge *ngIf="turno.estado === 'suspendido'" type="danger">{{turno.estado}}
                                    </plex-badge>
                                    | {{turno.horaInicio | date:'dd/MM/yyyy HH:mm'}}

                                </div>
                                <div *ngIf="turno.organizacion">
                                    <em class="small">{{ turno.organizacion.nombre }}</em>
                                </div>
                                <div *ngIf="turno.tipoPrestacion">
                                    {{turno.tipoPrestacion.term}}
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div *ngIf="turno && turno.profesionales?.length">
                                    <div class="col-md">
                                        <strong>Equipo de Salud</strong>
                                        <div *ngFor="let profesional of turno.profesionales">

                                            {{profesional | nombre}}
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="turno.profesionales?.length == 0" class="text-danger">Profesional
                                    no asignado</div>

                                <div *ngIf="turno && turno.espacioFisico">
                                    <div class="col-md">
                                        <strong>Espacio Físico</strong>
                                        {{turno.espacioFisico.nombre}}
                                    </div>
                                </div>
                                <div *ngIf="turno && turno.otroEspacioFisico">
                                    <div class="col-md">
                                        <strong>Espacio Físico</strong>
                                        {{turno.otroEspacioFisico.nombre}}
                                    </div>
                                </div>
                                <div *ngIf="!turno.espacioFisico?.nombre && !turno.otroEspacioFisico?.nombre"
                                     class="text-danger">Espacio físico no asignado</div>
                            </div>
                            <div class="col-md-12">
                                <div
                                     *ngIf="financiador?.nombre !== 'SUMAR' && turno?.profesionales?.length && turno?.estado !== 'suspendido' && turno?.paciente.documento">
                                    <seleccionar-financiador [paciente]="turno.paciente"
                                                             (setFinanciador)="setFinanciador($event)"></seleccionar-financiador>
                                </div>
                                <div *ngIf="financiador?.nombre === 'SUMAR'">
                                    <strong>Programa</strong>
                                    {{financiador?.nombre}}
                                </div>
                            </div>
                            <div class="col-md-12"
                                 *ngIf=" turno.horaInicio >= todaysdate && turno.estado !== 'suspendido' && turno.organizacion._id === auth.organizacion.id">
                                <plex-button *ngIf="puedeLiberarTurno && turno.asistencia !== 'asistio' && turno.bloque_id"
                                             class="float-right mr-1" type="danger" label="Liberar Turno" size="sm"
                                             (click)="liberarTurno(turno)">
                                </plex-button>

                                <plex-button *ngIf="puedeRegistrarAsistencia && turno.asistencia !== 'asistio'&& isToday(turno)"
                                             class="float-right mr-1" type="success" label="Dar Asistencia" size="sm"
                                             (click)="eventosTurno(turno, 'darAsistencia')">
                                </plex-button>

                                <plex-button *ngIf="puedeRegistrarAsistencia && turno.asistencia === 'asistio' && isToday(turno) && turno.diagnostico.codificaciones.length == 0"
                                             class="float-right mr-1" type="warning" label="Quitar Asistencia" size="sm"
                                             (click)="eventosTurno(turno,'sacarAsistencia')">
                                </plex-button>

                                <plex-button *ngIf="financiador?.nombre !== 'SUMAR' && turno?.profesionales?.length > 0 && turno?.paciente.documento"
                                             class="float-right mr-1" type="info" label="Recupero" size="sm"
                                             (click)="showArancelamiento(turno)">
                                </plex-button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </fieldset>
    </div>
    <div *ngIf="showMotivoConsulta">
        <div class="row">
            <div class="col" *ngIf="turnoArancelamiento?.paciente?.obraSocial?.financiador">
                <label>Obra Social </label>
                <div>{{financiador?.nombre}}</div>
            </div>
        </div>
        <div class="row" *ngIf="turnoArancelamiento?.paciente?.obraSocial?.numeroAfiliado">
            <div class="col">
                <label>Número de Afiliado </label>
                <div>{{turnoArancelamiento.paciente.obraSocial.numeroAfiliado}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <plex-text label="Motivo de consulta / Diagnóstico" name="motivoConsulta" [multiline]="true"
                           (change)="cambiarMotivo()" [(ngModel)]="turnoArancelamiento.motivoConsulta"></plex-text>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-4">
                <plex-button type="danger" label="Cancelar" (click)="showPanel()"></plex-button>
            </div>
            <div class="col-4">
                <plex-button type="info" label="Descargar" (click)="printArancelamiento(turnoArancelamiento)">
                </plex-button>
            </div>
        </div>
    </div>
    <div *ngIf="showLiberarTurno ">
        <liberar-turno [agenda]="agenda " [turnosSeleccionados]="turnosSeleccionados "
                       (saveLiberarTurno)="afterLiberarTurno() " (cancelaLiberarTurno)="afterLiberarTurno() ">
        </liberar-turno>
    </div>
</div>