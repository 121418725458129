<plex-text [(ngModel)]="filtroPaciente" (change)="filtrarPaciente()" name='filtrarPaciente' [debounce]="500"
           placeholder="Escriba un documento / apellido / nombre">
    <plex-icon name="account-search" left></plex-icon>
</plex-text>

<div *ngIf="pacientes.length">
    <auditoria-listado [pacientes]="pacientes" [registroReportes]="reportes" [autoselect]="false" [errorTab]="true"
                       (selected)="onSelect($event)">
    </auditoria-listado>
</div>

<div *ngIf="!pacientes.length" class="alert alert-default">
    <plex-icon name="emoticon-happy"></plex-icon> No se encontraron pacientes con datos reportados.
</div>

<modal-correccion-paciente #modalCorreccion [paciente]="pacienteSelected" (patientCorrected)="savePatient($event)">
</modal-correccion-paciente>