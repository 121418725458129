<plex-layout main="{{ sidebar }}">
    <plex-layout-main>
        <paciente-busqueda [hostComponent]="'mpi'" [create]="true" [returnScannedPatient]="true" [editing]="true"
                           (searchEnd)="onSearchEnd($event.pacientes,$event.scan)" (selected)="toVisualize($event)"
                           (edit)="toEdit($event)" [offset]="80">
        </paciente-busqueda>
    </plex-layout-main>


    <plex-layout-sidebar [type]="'invert'">
        <!-- historial de búsquedas -->
        <ng-container *ngIf="!showDetallePaciente">
            <plex-title size="md" titulo="Busquedas recientes"></plex-title>

            <paciente-listado [pacientes]="historialSeleccionados" (selected)="toEdit($event)" [showRelaciones]="false">
            </paciente-listado>
        </ng-container>

        <!-- detalles del paciente a visualizar -->
        <ng-container *ngIf="showDetallePaciente">
            <plex-title titulo="Detalle paciente" size="sm">
                <plex-button [disabled]="paciente | pacienteRestringido" type="warning" size="sm" label="Editar Datos" (click)="toEdit(paciente)"></plex-button>
                <plex-button type="danger" icon="close" class="ml-2" size="sm" (click)="closeDetallePaciente()">
                </plex-button>
            </plex-title>

            <paciente-detalle [paciente]="paciente" [showDocumentos]="true" [showRelaciones]="true" reload="true">
            </paciente-detalle>
        </ng-container>

    </plex-layout-sidebar>
</plex-layout>