<plex-int *ngIf="!soloValores" placeholder="Ingrese un valor entre 30 y 100" [(ngModel)]="registro.valor" name="frecuenciaCardiaca" label="Frecuencia cardíaca" suffix="PPM" min="30" max="100" required="true" (change)="emitChange()">
</plex-int>

<p *ngIf="soloValores" class="readonly">
    <span>Frecuencia cardíaca</span> {{registro.valor}} PPM
</p>

<p *ngIf="mensaje.texto">
    <plex-badge type="{{mensaje.class}}">
    {{mensaje.texto}}
    </plex-badge>
</p>