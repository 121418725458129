<plex-title [size]="size? size : ''" main titulo="NUEVA SOLICITUD DE {{tipoSolicitud.toUpperCase()}}">
    <plex-button [size]="size? size : ''" class="float-left mr-1" position="left" type="danger" label="Cancelar"
                 (click)="cancelar()">
    </plex-button>
    <plex-button [size]="size? size : ''" position="right" class="float-right mr-1" type="success" label="Guardar"
                (click)="confirmGuardar($event)" [autodisabled]="true" [validateForm]="form"> </plex-button>
</plex-title>
<form #form="ngForm">
    <!-- SOLICITUD ENTRADA -->
    <ng-container *ngIf="tipoSolicitud == 'entrada'">
        <plex-wrapper>
            <plex-datetime class="mr-0" type="date" [(ngModel)]="modelo.solicitud.fecha" name="fechaSolicitud"
                           label="Fecha de solicitud" [required]="true">
            </plex-datetime>
            <div class="mr-4 mb-3" justify="start">
                <span hint="Recuerde registrar en este campo la fecha en que el profesional solicita la prestación y no la fecha en que se registra en el sistema"
                      hintType="info">
                </span>
            </div>
            <plex-select [(ngModel)]="modelo.solicitud.tipoPrestacion" name="tipoPrestacionDestino"
                         tmPrestaciones="solicitudes:tipoPrestacion:?" label="Tipo de Prestación Solicitada" escribir
                         placeholder="Escriba al menos 3 letras" [required]="true"
                         (change)="onSelectPrestacionOrigen()">
            </plex-select>
            <plex-select *ngIf="!autocitado" [(ngModel)]="modelo.solicitud.organizacionOrigen" name="organizacionOrigen"
                         [data]="dataOrganizacionesOrigen" label="Organización origen"
                         placeholder="Seleccione la organización" labelField="nombre"
                         (change)="onSelectOrganizacionOrigen(); checkProfesional()" [required]="true">
            </plex-select>
        </plex-wrapper>
        <plex-grid type="full" cols="4">
            <plex-bool [(ngModel)]="autocitado" label="Autocitado" grow="1" name="autocitado">
            </plex-bool>
        </plex-grid>
        <plex-grid type="full" cols="2">
            <plex-wrapper>
                <plex-select *ngIf="!autocitado" [(ngModel)]="prestacionOrigen" [data]="dataTipoPrestacionesOrigen"
                             name="tipoPrestacion" (change)="onSelect()" label="Tipos de Prestación Origen"
                             placeholder="Tipos de Prestación Origen" [required]="true" grow="full">
                </plex-select>
                <plex-select [(ngModel)]="modelo.solicitud.profesionalOrigen" name="profesionalOrigen"
                             (getData)="loadProfesionales($event)" label="Profesional solicitante"
                             placeholder="Escriba el apellido del Profesional" labelField="apellido + ' ' + nombre"
                             (change)="checkProfesional()" grow="full">
                </plex-select>
                <plex-select *ngIf="!autocitado" [(ngModel)]="modelo.solicitud.profesional" label="Profesional destino"
                             name="profesional" (getData)="loadProfesionales($event)" (change)="checkProfesional()"
                             placeholder="Escriba el apellido de un Profesional" labelField="apellido + ' ' + nombre"
                             grow="full">
                </plex-select>
            </plex-wrapper>
            <plex-wrapper>
                <plex-text [(ngModel)]="motivo" label="Notas / Diagnóstico / Motivo" name="motivo" [required]="true"
                           multiline="true"></plex-text>
            </plex-wrapper>
        </plex-grid>
    </ng-container>
    <!-- /SOLICITUD ENTRADA -->
    <!-- SOLICITUD SALIDA -->
    <ng-container *ngIf="tipoSolicitud == 'salida'">
        <plex-wrapper>
            <plex-datetime class="mr-0" type="date" [(ngModel)]="modelo.solicitud.fecha" name="fechaSolicitud"
                           label="Fecha de solicitud" [required]="true">
            </plex-datetime>
            <div class="mr-4  mb-3" justify="start">
                <span hint="Recuerde registrar en este campo la fecha en que el profesional solicita la prestación y no la fecha en que se registra en el sistema"
                      hintType="info">
                </span>
            </div>
            <plex-select [(ngModel)]="modelo.solicitud.tipoPrestacionOrigen" name="tipoPrestacionOrigen"
                         (change)="onSelect()" tmPrestaciones="solicitudes:tipoPrestacion:?"
                         label="Tipos de Prestación Origen" placeholder="Escriba al menos 3 letras" [required]="true">
            </plex-select>
            <plex-select *ngIf="!autocitado" [(ngModel)]="modelo.solicitud.organizacion" name="organizacionDestino"
                         label="Organización destino" placeholder="Seleccione la organización" labelField="nombre"
                         [data]="dataOrganizacionesDestino" [required]="true" (change)="onSelectOrganizacionDestino()">
            </plex-select>
        </plex-wrapper>
        <plex-grid type="full" cols="2">
            <plex-wrapper>
                <plex-select [(ngModel)]="modelo.solicitud.profesionalOrigen" name="profesionalOrigen"
                             (getData)="loadProfesionales($event)" label="Profesional solicitante"
                             placeholder="Escriba el apellido del Profesional" labelField="apellido + ' ' + nombre"
                             [required]="true" grow="full">
                </plex-select>
                <plex-select [(ngModel)]="prestacionDestino" name="tipoPrestacionDestino" [data]="dataReglasDestino"
                             label="Tipo de Prestación Solicitada" placeholder="Tipos de Prestación solicitada"
                             [required]="true" (change)="onSelectPrestacionDestino()" grow="full">
                </plex-select>
                <plex-select *ngIf="!autocitado" [(ngModel)]="modelo.solicitud.profesional" label="Profesional destino"
                             name="profesional" (getData)="loadProfesionales($event)"
                             placeholder="Escriba el apellido de un Profesional" labelField="apellido + ' ' + nombre"
                             grow="full">
                </plex-select>
            </plex-wrapper>
            <plex-wrapper>
                <plex-text [(ngModel)]="motivo" label="Notas / Diagnóstico / Motivo" name="motivo" [required]="true"
                           multiline="true"></plex-text>
            </plex-wrapper>
        </plex-grid>
    </ng-container>
    <!-- /SOLICITUD SALIDA -->
    <!-- Adjuntar Archivos -->
    <plex-title titulo="Cargar adjuntos">
        <upload-file label="Adjuntar archivo" (onUpload)="onUpload($event)" [extensiones]="extensions" [modulo]="'top'">
        </upload-file>
        <br><br>
        <span *ngIf="errorExt">
            <plex-badge type="danger" size="sm" hint="Estos son los archivos que podes subir: {{ extensions }}"
                        detach="top" hintType="danger">Archivo inválido.</plex-badge>
        </span>
    </plex-title>
    <shared-galeria-archivos [files]="documentosUrl" [loading]="waiting" (remove)="imageRemoved($event)">
    </shared-galeria-archivos>
</form>
