<plex-grid size="md" type="auto" *ngIf="files">
    <plex-card *ngFor="let archivo of files; let i = index">
        <ng-container *ngIf="archivo.isImage">
            <div justify="center">
                <plex-icon size="xxl" name="jpg-outiline" class="icon-imagen"></plex-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="archivo.isDocument">
            <div justify="center">
                <plex-icon size="xxl" name="pdf-outline" type="info"></plex-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="archivo.isVideo">
            <div justify="center">
                <plex-icon class="icon-video" size="xxl" name="mp4-outline"></plex-icon>
            </div>
        </ng-container>
        <plex-button type="success" size="sm" icon="download" (click)="openUrl(archivo)">
        </plex-button>
        <plex-button *ngIf="archivo.isImage" type="info" size="sm" icon="eye" (click)="open(i)">
        </plex-button>
        <plex-button *ngIf="!readonly && modulo !== 'rup'" type="danger" size="sm" icon="delete"
                     (click)="onRemove(archivo)"></plex-button>
    </plex-card>
    <plex-card size="md" *ngIf="loading">
        <plex-loader type="ball-pulse"></plex-loader>
    </plex-card>
</plex-grid>

<plex-grid size="md" type="auto" *ngIf="!files && file">
    <plex-card>
        <ng-container *ngIf="verificarImagen(file.ext)">
            <div justify="center">
                <plex-icon size="xxl" name="jpg-outiline" class="icon-imagen" (click)="openFile()"></plex-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="verificarDocumento(file.ext)">
            <div justify="center">
                <plex-icon size="xxl" name="pdf-outline" type="info" class="icon-doc"
                           (click)="openUrl(file)"></plex-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="verificarVideo(file.ext)">
            <div justify="center">
                <plex-icon class="icon-video" size="xxl" name="mp4-outline" (click)="openFile()"></plex-icon>
            </div>
        </ng-container>
    </plex-card>

</plex-grid>