<div class="rup-card mini {{semantic}}">
    <div class="rup-header">
        <div class="rup-border rup-border-{{semantic}}">
            <div class="row p-0 m-0 border-secondary border-left-0">
                <div class="col-10 p-0 m-0">
                    <div class="row m-0 p-0">
                        <div class="icon-rup drag-handle p-0 pt-1">
                            <i class="adi adi-{{icono}}"></i>
                        </div>
                        <div class="col p-0 pl-2 float-left">
                            {{ titulo }}
                            <div class="row p-0 m-0">
                                <div class="col-10 p-0 m-0">
                                    <div class="sugerido" *ngIf="fecha">
                                        <small>
                                            Última evolución:
                                            {{ fechaa | fecha }}</small>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-2 p-0 m-0">
                    <div class="p-0 pt-1 pr-1 float-right">
                        <ng-container>
                            <plex-badge size="sm" type="success">
                                {{ estado }}
                            </plex-badge>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>