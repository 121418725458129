<plex-modal #modal (closed)="notificarAccion(false)">
    <plex-icon name="alert-circle" type="danger"></plex-icon>
    <plex-modal-title type="danger">Historia Universal Digital de Salud</plex-modal-title>
    <plex-modal-subtitle type="">Por favor, indique el motivo de acceso:</plex-modal-subtitle>
    <main>
        <div class="w-100">
            <plex-radio [(ngModel)]="motivoSelected" [data]="motivosAccesoHuds">
            </plex-radio>
            <div class="mt-2">
                <plex-label><strong>Detalle de motivo (opcional)</strong></plex-label>
                <plex-text placeholder="Describa brevemente el motivo de acceso" multiline="true" name="multi"
                           [(ngModel)]="descripcionAcceso">
                </plex-text>
            </div>

            <div class="mt-2">
                <p class="text-justify mt-2 mb-1">
                    <small>
                        Declaro que tengo pleno conocimiento y acepto que todos los datos a los que pueda acceder en el
                        ejercicio de mis funciones, los trataré con absoluta confidencialidad. En este sentido, me
                        comprometo a no divulgar, publicar, utilizar, reproducir, difundir o transmitir de ninguna
                        manera la información, sin importar sus características, ni proporcionarla a terceros bajo
                        ninguna circunstancia.
                        <br>
                        <b>Es importante destacar que el manejo de la información está limitada exclusivamente al
                            cumplimiento de mis responsabilidades en este centro asistencial.
                        </b>
                    </small>
                </p>
                <div class="text-center">
                    <img *ngIf='urlFirma' [src]="urlFirma" height="100px" class="mb-2" />
                    <br>
                    Profesional:
                    <plex-modal-subtitle center type="">
                        <strong>{{ ' '+auth.usuario.nombreCompleto }}</strong>
                    </plex-modal-subtitle>
                    <br>
                    DNI:
                    <plex-modal-subtitle center type="">
                        <strong>{{' '+auth.usuario.documento}}</strong>
                    </plex-modal-subtitle>
                </div>
            </div>
        </div>

    </main>

    <plex-button modal right type="success" (click)="notificarAccion(true)" [disabled]="motivoSelect()">
        ACEPTAR
    </plex-button>
    <plex-button modal left type="danger" (click)="notificarAccion(false)">
        CANCELAR
    </plex-button>
</plex-modal>