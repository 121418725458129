<plex-list [height]="height" (scrolled)="onScroll()" [striped]="false">

    <ng-container *ngFor="let paciente of listado;let i=index">
        <plex-item [class.pacienteFallecido]="paciente.fechaFallecimiento" [selected]="selectedId === paciente.id"
                   (click)="seleccionar(paciente)" [colors]="colorItem(i)">
            <img [ngClass]="{'img-fallecido' : paciente.fechaFallecimiento}" [mpiFotoPaciente]="paciente" />

            <plex-label *ngIf="!showDatosTutor(paciente)" titulo="{{ paciente | nombre }}"
                        subtitulo="{{ paciente | documento }}">
            </plex-label>

            <plex-label *ngIf="showDatosTutor(paciente)" titulo="{{ paciente | nombre }}" subtitulo=" {{paciente.relaciones[0].relacion?.nombre.toUpperCase() || 'RELACIÓN S/D'}}: 
                             {{ paciente.relaciones[0] | documento }} - {{ (paciente.relaciones[0] | nombre)}}">
            </plex-label>

            <plex-label titulo="{{ paciente | edad }}" subtitulo="{{ paciente.fechaNacimiento | fecha }}">
            </plex-label>

            <plex-badge *ngIf="showDatosTutor(paciente)" type="warning">
                Sin DNI
            </plex-badge>

            <plex-badge type="info">{{ paciente.genero }}
            </plex-badge>

            <plex-badge type="" class=""
                        [ngClass]="{'text-success' : paciente.estado === 'validado' , 'text-warning' : paciente.estado === 'temporal'} ">
                {{ paciente.estado || 'S/D' }}
            </plex-badge>

            <plex-badge *ngIf="paciente.fechaFallecimiento" type="danger">Fallecido:
                {{ paciente.fechaFallecimiento | fecha}}
            </plex-badge>

            <plex-button *ngIf="showBtnRelaciones(paciente)" type="info" size="sm" icon="usuarios"
                         title="{{openBtnRelaciones(paciente)?'No ver relaciones':'Ver relaciones'}}"
                         (click)="verRelaciones(paciente)">
            </plex-button>

            <plex-button *ngIf="editing" type="warning" size="sm" icon="pencil" title="Editar paciente"
                         [disabled]="paciente | pacienteRestringido" (click)="editar(paciente)">
            </plex-button>
        </plex-item>

        <div *ngIf="pacienteSeleccionado?.id === paciente.id" class="ml-3">
            <paciente-relaciones [relaciones]="listadoRelaciones" (selected)="seleccionar($event)">
            </paciente-relaciones>
        </div>

    </ng-container>

</plex-list>