<plex-title titulo="Notas" size="sm">
    <plex-button *ngIf="showForm" label="Cancelar" size="sm mr-1" type="danger" (click)="cancelarNota()">
    </plex-button>
    <plex-button *ngIf="showForm" label="Guardar" type="success" size="sm" (click)="guardarNota()"
                 [disabled]="!formularioNota?.valid">
    </plex-button>
    <plex-button *ngIf="!showForm && !_nota" label="Agregar nota" type="success" size="sm" class='mr-1'
                 (click)="agregarNota()"></plex-button>
</plex-title>
<form #formularioNota="ngForm" class="mr-1">
    <plex-table *ngIf="_nota && !showForm">
        <tr>
            <td>
                <plex-label icon="documento" size="md" titulo="{{_nota}}"></plex-label>
            </td>
            <td>
                <plex-button size="sm" type="danger" icon="cesto" class="float-right" tooltip="Eliminar"
                             (click)="eliminar()">
                </plex-button>
                <plex-button size="sm" type="warning" icon="pencil" class="float-right" tooltip="Editar"
                             (click)="editarNota()">
                </plex-button>
            </td>
        </tr>
    </plex-table>
    <plex-grid *ngIf="!_nota && !showForm">
        <section>
            <div class="d-flex justify-content-start align-items-center">
                <plex-label icon="informacion" type="warning" size="md" direction="row" titulo="No hay ninguna nota aún"
                            subtitulo="Para agregar una nota presioná el botón AGREGAR NOTA"></plex-label>
            </div>
        </section>
    </plex-grid>
    <plex-select *ngIf="_notasPredefinidas?.length && showForm" name="nota" [data]="_notasPredefinidas"
                 label="{{ nota ? 'Seleccione una nueva opción' : 'Seleccione una opción' }}"
                 (change)="notaEditada = ''" [(ngModel)]="notaPredefinida" grow="full" [required]="true">
    </plex-select>
    <plex-text label="Ingrese nota"
               *ngIf="showForm && (_notasPredefinidas?.length === 0 || notaPredefinida?.id === 'otra')"
               [(ngModel)]="notaEditada" name="notaText" grow="full" [required]="true" multiline="true"
               (change)="cleanSpaces()">
    </plex-text>
</form>
