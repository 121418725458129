<div *ngIf="!soloValores && registro.valor">
    <plex-select [(ngModel)]="registro.valor.solicitudPrestacion.prestacionSolicitada" name="nombrePrestacion"
                 required="true" label="Prestación a Solicitar" [data]="tiposPrestacion" labelField="term"
                 (change)="cambiarNombre()">
    </plex-select>
    <plex-text label="Motivo" multiline="true" [autoFocus]="true" required="true"
               [(ngModel)]="registro.valor.solicitudPrestacion.motivo"></plex-text>
</div>

<p *ngIf="soloValores" class="readonly">
    <span>Motivo</span>
    <ng-container>
        {{ registro.valor.solicitudPrestacion.motivo || '(no registrado)' }}
    </ng-container>
</p>